import { Component, OnInit } from '@angular/core';
import { Observable, timer } from 'rxjs';
import { take, map } from 'rxjs/operators';

@Component({
  selector: 'internet-interrupted',
  templateUrl: './internet-interrupted.component.html',
  styleUrls: ['./internet-interrupted.component.scss']
})
export class InternetInterruptedComponent implements OnInit {

  public countDown: Observable<number>;
  private count;
  constructor() { }

  ngOnInit() {
    this.reconnectCoundown();
  }

  reconnectCoundown() {
    this.count = 60;
    this.countDown = timer(0, 1000).pipe(
      take(this.count),
      map(() => --this.count)
    );
  }

}
