import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'advertisement',
  templateUrl: './advertisement.component.html',
  styleUrls: ['./advertisement.component.scss']
})
export class AdvertisementComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

  expandSection(sectionId) {

    if (sectionId == 1) {
      document.getElementById("shade1").style.display = "none";
      document.getElementById("section-container-1").style.height = "auto";

    } else if (sectionId == 2) {
      document.getElementById("shade2").style.display = "none";
      document.getElementById("section-container-2").style.height = "auto";
    }

  }

}
