<div class="app_container">
    <div class="main_header_section">

        <div class="row">
            <div class="col-md-3 main_header_container">

                <app-cover-header></app-cover-header>

            </div>
            <div class="col-md-9 animation_clouds" id="laptop_with_animation">

                <app-cover-animation></app-cover-animation>

            </div>

        </div>

    </div>
</div>

<div class="app_container">

    <div class="story_video_section">

        <app-story-video></app-story-video>

    </div>

</div>

<div class="app_container">

    <div>
        <br>
        <br>
        <br>
        <br>
        <br>
        <app-three-simple-steps></app-three-simple-steps>

    </div>

</div>

<div class="app_container" style="margin-top: -260px;">

    <div>
        <br>
        <br>
        <br>
        <br>
        <br>
        <app-key-features></app-key-features>

    </div>

</div>

<div class="coming_soon_container">
    <div class="coming-soon-title-section">

        <div class="flex-container">
            <div>
                <img class="title_left_part float-right" src="../../../../../assets/images/home/coming_soon-vec1.svg">
            </div>
            <div>Coming Soon...</div>
            <div>
                <img class="title_right_part float-left" src="../../../../../assets/images/home/coming_soon-vec2.svg">
            </div>
        </div>

    </div>
    <div class="coming-soon-container-half"></div>
    <div class="app_container">
        <br>
        <app-coming-soon></app-coming-soon>
    </div>


</div>


<div class="trusted_container">
    <br>
    <br>
    <br>
    <br>
    <br>
    <app-trusted-section></app-trusted-section>





    <div class="award-container">

        <app-section-title [sectionTitle]="'Awards'"></app-section-title>
        <br><br>
        <div class="row">

            <div class="col-sm-12 col-md-4">
                <img src="../../../../../assets/images/home/slasscom_award.svg">
            </div>
            <div class="col-sm-12 col-md-4">
                <img src="../../../../../assets/images/home/ux-award-1.svg">
            </div>
            <div class="col-sm-12 col-md-4">
                <img src="../../../.././../assets/images/home/ux-award-2.svg">
            </div>

        </div>
    </div>





    <br><br><br>
    <div class="text-center">
        <button *ngIf="goToApp == 2" class="primary_button" onclick="location.href='/signup';">Get Started</button>
        <button *ngIf="goToApp == 1" class="primary_button" onclick="location.href='/app/datalocker';">Get Started</button>
    </div>

</div>




<div class="contact_us_container">

    <br>
    <br>
    <br>
    <div class="app_container" id="contact_us_container">

        <app-section-title [sectionTitle]="'Contact Us'"></app-section-title>
        <div class="contact-us-container">
            <app-contact-us></app-contact-us>

        </div>
    </div>

</div>