<div class="main-content" [ngClass]="{'show-main-content' : contentLoadedFromServer}">
    <app-header
        *ngIf="currentRoute != '/ai-analytics-for-financial-services/asset-backed-securities' && currentRoute != '/early-bird-registration/insurance-claim-assistant-bot' && currentRoute != '/zepto-for-healthcare'">
    </app-header>

    <div class="app_layout">
        <div *ngIf="loadingRouteConfig">

            <div class="loader">
                <h3>Loading...</h3>
            </div>

        </div>
        <div class="outlet fadeIn" *ngIf="!loadingRouteConfig">
            <router-outlet></router-outlet>
        </div>
    </div>
    <app-footer
        *ngIf="currentRoute != '/ai-analytics-for-financial-services/asset-backed-securities' && currentRoute != '/early-bird-registration/insurance-claim-assistant-bot' && currentRoute != '/zepto-for-healthcare'">
    </app-footer>
</div>

<div class="not-connection-container" *ngIf=showNetworkStatus>
    <internet-interrupted></internet-interrupted>
</div>