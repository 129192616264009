<div class="app_container">


  <!-- Start: Price Plans
            ====================================== -->
  <div class="pricing_section">

    <h1 class="pricing_title d-flex justify-content-center justify-content-md-start">
      Choose the Plan that best suits your need
    </h1>

    <div class="pricing_plan_container">
      <div class="d-none d-lg-block plan-type-container">
        <div class="d-flex justify-content-center">
          <ul class="nav pricing_nav">
            <li class="nav-item">
              <a (click)="changeActivePlanColor(1)" class="nav-link active" id="plan_1_pill" data-toggle="pill" href="#plan_1"
                role="tab" aria-controls="pills-home" aria-expanded="true">
                <img *ngIf="selectedPackage == 1" src="../../../../../assets/images/pricing/plan_topic_vector1.svg"
                  height="20" alt="plan">
                <span id="basic_plan" class="plan-name" style="color:#777777">Basic</span>
                <img *ngIf="selectedPackage == 1" src="../../../../../assets/images/pricing/plan_topic_vector2.svg"
                  height="20" alt="plan">
              </a>
            </li>

            <li class="nav-item">
              <a (click)="changeActivePlanColor(2)" class="nav-link" id="plan_2_pill" data-toggle="pill" href="#plan_2"
                role="tab" aria-controls="pills-profile" aria-expanded="true">
                <img *ngIf="selectedPackage == 2" src="../../../../../assets/images/pricing/plan_topic_vector1.svg"
                  height="20" alt="plan">
                <span id="team_plan" class="plan-name" style="color:#777777">Team</span>
                <img *ngIf="selectedPackage == 2" src="../../../../../assets/images/pricing/plan_topic_vector2.svg"
                  height="20" alt="plan">
              </a>
            </li>

            <li class="nav-item">
              <a (click)="changeActivePlanColor(3)" class="nav-link" id="plan_3_pill" data-toggle="pill" href="#plan_3"
                role="tab" aria-controls="pills-plan" aria-expanded="true">
                <img *ngIf="selectedPackage == 3" src="../../../../../assets/images/pricing/plan_topic_vector1.svg"
                  height="20" alt="plan">
                <span id="corporate_plan" class="plan-name" style="color:#777777">Corporate</span>
                <img *ngIf="selectedPackage == 3" src="../../../../../assets/images/pricing/plan_topic_vector2.svg"
                  height="20" alt="plan">
              </a>
            </li>
          </ul>
        </div>
      </div>




      <div class="d-none d-lg-block">
        <div class="tab-content" id="pills-tabContent">
          <!-- begining of first pill -->
          <div class="tab-pane fade show active" id="plan_1" role="tabpanel" aria-labelledby="plan_1_pill">

            <div class="card plan_card" style="box-shadow: 0 10px 30px rgba(113, 117, 226, 0.212) !important;">


              <div class="card-body">


                <div class="row">
                  <div class="col-12 col-md-6 order-2 col-lg-4 order-md-1 order-lg-1">

                    <div class="plan_details_container d-flex justify-content-center justify-content-md-start justify-content-sm-center">

                      <table class=" d-table justify-content-center justify-content-md-start">
                        <tr>
                          <td>
                            <img src="../../../../../assets/images/pricing/Artboard_1.svg">
                          </td>
                          <td>1 User License</td>
                        </tr>
                        <tr>
                          <td>
                            <img src="../../../../../assets/images/pricing/Artboard_2.svg">
                          </td>
                          <td>2 GB Storage</td>
                        </tr>
                        <tr>
                          <td>
                            <img src="../../../../../assets/images/pricing/Artboard_3.svg">
                          </td>
                          <td>5 Dashboards</td>
                        </tr>
                        <tr>
                          <td>
                            <img src="../../../../../assets/images/pricing/Artboard_4.svg">
                          </td>
                          <td>Email Support</td>
                        </tr>
                        <tr>
                          <td>
                            <img src="../../../../../assets/images/pricing/Artboard_5.svg">
                          </td>
                          <td>Free Upgrades</td>
                        </tr>

                      </table>

                    </div>

                  </div>

                  <div class="plan_main_img col-12 col-md-6 order-1 col-lg-4 order-md-2 order-lg-2">
                    <img class="plan_1_main_image" src="../../../../../assets/images/pricing/bacis_pricing_plan.svg">
                  </div>

                  <div class="col-12 col-md-12 order-3 col-lg-4 order-md-3 order-lg-3">

                    <div class="plan_price">
                      <br>
                      <div class="plan-price d-flex justify-content-center justify-content-md-end">
                        <span class="strike">$79</span> / $69</div>
                      <br>
                      <div class="plan-users d-flex justify-content-center justify-content-md-end">per user / month</div>
                      <br>
                      <div class="d-flex justify-content-center justify-content-md-end">
                        <!--<button *ngIf="goToApp == 2" class="secondary_button1" onclick="location.href='/signup';">Get
                          Started</button>
                        <button *ngIf="goToApp == 1" class="secondary_button1" onclick="location.href='/app/datalocker';">Get
                          Started</button>-->
                          <button class="secondary_button1" data-toggle="modal" data-target="#contact-us-form" (click)="openContactUs()">Contact
                            Us</button>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>

          </div>
          <!-- end of first pill -->

          <!-- begining of second pill -->

          <div class="tab-pane fade" id="plan_2" role="tabpanel" aria-labelledby="plan_2_pill">

            <div class="card plan_card" style="box-shadow: 0 10px 30px rgba(113, 117, 226, 0.212) !important;">
              <div class="card-body">
                <div class="row">
                  <div class="col-12 col-md-6 order-2 col-lg-4 order-md-1 order-lg-1">
                    <div class="plan_details_container d-flex justify-content-center justify-content-md-start justify-content-sm-center">
                      <table class=" d-table justify-content-center justify-content-md-start">
                        <tr>
                          <td>
                            <img src="../../../../../assets/images/pricing/Artboard_1.svg">
                          </td>
                          <td>5 User Licenses</td>
                        </tr>
                        <tr>
                          <td>
                            <img src="../../../../../assets/images/pricing/Artboard_2.svg">
                          </td>
                          <td>10 GB Storage</td>
                        </tr>
                        <tr>
                          <td>
                            <img src="../../../../../assets/images/pricing/Artboard_3.svg">
                          </td>
                          <td>25 Dashboards</td>
                        </tr>
                        <tr>
                          <td>
                            <img src="../../../../../assets/images/pricing/Artboard_4.svg">
                          </td>
                          <td>Email Support</td>
                        </tr>
                        <tr>
                          <td>
                            <img src="../../../../../assets/images/pricing/Artboard_5.svg">
                          </td>
                          <td>Free Upgrades</td>
                        </tr>
                      </table>
                    </div>
                  </div>

                  <div class="plan_main_img col-12 col-md-6 order-1 col-lg-4 order-md-2 order-lg-2">
                    <img class="plan_1_main_image" src="../../../../../assets/images/pricing/team_pricing_plan.svg">
                  </div>


                  <div class="col-12 col-md-12 order-3 col-lg-4 order-md-3 order-lg-3">

                    <div class="plan_price">
                      <br>
                      <div class="plan-price d-flex justify-content-center justify-content-md-end">
                        $245</div>
                      <br>
                      <div class="plan-users d-flex justify-content-center justify-content-md-end">per team / month</div>
                      <br>
                      <div class="d-flex justify-content-center justify-content-md-end">

                        <button class="secondary_button1" data-toggle="modal" data-target="#contact-us-form" (click)="openContactUs()">Contact
                          Us</button>

                      </div>
                    </div>

                  </div>
                </div>
              </div>

              <!-- team option cards begining-->



              <!-- begining of team option disable on lg devices -->






              <div class="row">
                <div class="col-lg-6">
                  <div class="card package_card">
                    <div class="card-body">
                      <h3 class="card-title">
                        <div class="d-flex justify-content-center justify-content-sm-start">
                          Dashboard Views
                        </div>
                      </h3>

                      <div class="row">

                        <div class="col-12 col-sm-5">
                          <div class="d-flex justify-content-center justify-content-md-start">
                            <img class="package_card_img" src="../../../../../assets/images/pricing/add_dashboard.svg">
                          </div>
                        </div>

                        <div class="col-12 col-sm-7">
                          <div class="d-flex justify-content-center justify-content-md-end">
                            <div class="package_card_price">
                              <div class="team_option_price">$10</div>
                              <div class="team_option_per_user">per user / month</div>
                              <br>

                              <button class="secondary_button1" data-toggle="modal" data-target="#contact-us-form"
                                (click)="openContactUs()">Contact
                                Us
                              </button>

                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>


                <div class="col-lg-6">
                  <div class="card package_card">
                    <div class="card-body">
                      <h3 class="card-title">
                        <div class="d-flex justify-content-center justify-content-sm-start">
                          Extra Storage
                        </div>
                      </h3>

                      <div class="row">

                        <div class="col-12 col-sm-5">
                          <div class="d-flex justify-content-center justify-content-md-start">
                            <img class="package_card_img" src="../../../../../assets/images/pricing/add_storage.svg">
                          </div>
                        </div>

                        <div class="col-12 col-sm-7">
                          <div class="d-flex justify-content-center justify-content-md-end">
                            <div class="package_card_price">
                              <div class="team_option_price">&nbsp;</div>
                              <div class="team_option_per_user">&nbsp;</div>
                              <br>

                              <button class="secondary_button1" data-toggle="modal" data-target="#contact-us-form"
                                (click)="openContactUs()">Contact
                                Us
                              </button>

                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>





              <!-- end of team option disable on lg devices -->

              <!-- end of team option card -->
            </div>



          </div>
          <!-- end of second pill -->

          <!-- begining of third pill -->

          <div class="tab-pane fade" id="plan_3" role="tabpanel" aria-labelledby="plan_3_pill">

            <div class="card plan_card" style="box-shadow: 0 10px 30px rgba(113, 117, 226, 0.212) !important;">
              <div class="card-body">

                <div class="row">
                  <div class="col-12 col-md-6 order-2 col-lg-4 order-md-1 order-lg-1">

                    <div class="plan_details_container d-flex justify-content-center justify-content-md-start justify-content-sm-center">

                      <table class=" d-flex justify-content-center justify-content-md-start">
                        <tr>
                          <td>Got a big team and want to customize your package?</td>
                        </tr>

                      </table>
                    </div>
                  </div>


                  <div class="plan_main_img col-12 col-md-6 order-1 col-lg-4 order-md-2 order-lg-2">
                    <img class="plan_1_main_image" src="../../../../../assets/images/pricing/team_pricing_plan_1.svg">
                  </div>


                  <div class="col-12 col-md-12 order-3 col-lg-4 order-md-3 order-lg-3">
                    <div class="plan_price">
                      <br>
                      <div class="plan_price_container d-flex justify-content-center justify-content-md-end">
                        &nbsp;
                      </div>
                      <br>
                      <div class="plan_user_container d-flex justify-content-center justify-content-md-end">&nbsp;</div>
                      <br>
                      <div class="d-flex justify-content-center justify-content-md-end">
                        <button class="secondary_button1" data-toggle="modal" data-target="#contact-us-form" (click)="openContactUs()">Contact
                          Us</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>










      <div class="d-block d-lg-none">


        <div id="main_price_card_carousel" class="carousel slide" data-ride="carousel" data-interval="false"
          data-slide-to="5">


          <div class="carousel-inner">
            <div class="carousel-item active">

              <div class="card plan_card" style="box-shadow: 0 10px 30px rgba(113, 117, 226, 0.212) !important;">
                <div class="card-body">



                  <div class="d-flex justify-content-center">
                    <span id="basic_plan" style="color: #302f2f;" class="plan-name">Basic</span>
                  </div>

                  <div class="row">
                    <div class="col-12 col-md-6 order-2 col-lg-4 order-md-1 order-lg-1">

                      <div class="plan_details_container d-flex justify-content-center justify-content-md-start justify-content-sm-center">

                        <table class=" d-table justify-content-center justify-content-md-start">
                          <tr>
                            <td>
                              <img src="../../../../../assets/images/pricing/Artboard_1.svg">
                            </td>
                            <td>1 User License</td>
                          </tr>
                          <tr>
                            <td>
                              <img src="../../../../../assets/images/pricing/Artboard_2.svg">
                            </td>
                            <td>2 GB Storage</td>
                          </tr>
                          <tr>
                            <td>
                              <img src="../../../../../assets/images/pricing/Artboard_3.svg">
                            </td>
                            <td>5 Dashboards</td>
                          </tr>
                          <tr>
                            <td>
                              <img src="../../../../../assets/images/pricing/Artboard_4.svg">
                            </td>
                            <td>Email Support</td>
                          </tr>
                          <tr>
                            <td>
                              <img src="../../../../../assets/images/pricing/Artboard_5.svg">
                            </td>
                            <td>Free Upgrades</td>
                          </tr>

                        </table>

                      </div>

                    </div>

                    <div class="plan_main_img col-12 col-md-6 order-1 col-lg-4 order-md-2 order-lg-2">
                      <img class="plan_1_main_image" src="../../../../../assets/images/pricing/bacis_pricing_plan.svg">
                    </div>

                    <div class="col-12 col-md-12 order-3 col-lg-4 order-md-3 order-lg-3">

                      <div class="plan_price">
                        <br>
                        <div class="plan-price d-flex justify-content-center justify-content-md-end">
                          <span class="strike">$79</span> / $69</div>
                        <br>
                        <div class="plan-users d-flex justify-content-center justify-content-md-end">per user / month
                        </div>
                        <br>
                        <div class="d-flex justify-content-center justify-content-md-end">
                          <!--<button *ngIf="goToApp == 2" class="secondary_button1" onclick="location.href='/signup';">Get
                            Started</button>
                          <button *ngIf="goToApp == 1" class="secondary_button1" onclick="location.href='/app/datalocker';">Get
                            Started</button>-->
                          <button class="secondary_button1" data-toggle="modal" data-target="#contact-us-form" (click)="openContactUs()">Contact
                            Us</button>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>
              </div>
              <br>
              <br>
            </div>
            <div class="carousel-item">



              <div class="card plan_card" style="box-shadow: 0 10px 30px rgba(113, 117, 226, 0.212) !important;">
                <div class="card-body">

                  <div class="d-flex justify-content-center">
                    <span id="basic_plan" style="color: #302f2f;" class="plan-name">Team</span>
                  </div>

                  <div class="row">
                    <div class="col-12 col-md-6 order-2 col-lg-4 order-md-1 order-lg-1">

                      <div class="plan_details_container d-flex justify-content-center justify-content-md-start justify-content-sm-center">

                        <table class=" d-table justify-content-center justify-content-md-start">
                          <tr>
                            <td>
                              <img src="../../../../../assets/images/pricing/Artboard_1.svg">
                            </td>
                            <td>5 User Licenses</td>
                          </tr>
                          <tr>
                            <td>
                              <img src="../../../../../assets/images/pricing/Artboard_2.svg">
                            </td>
                            <td>10 GB Storage</td>
                          </tr>
                          <tr>
                            <td>
                              <img src="../../../../../assets/images/pricing/Artboard_3.svg">
                            </td>
                            <td>25 Dashboards</td>
                          </tr>
                          <tr>
                            <td>
                              <img src="../../../../../assets/images/pricing/Artboard_4.svg">
                            </td>
                            <td>Email Support</td>
                          </tr>
                          <tr>
                            <td>
                              <img src="../../../../../assets/images/pricing/Artboard_5.svg">
                            </td>
                            <td>Free Upgrades</td>
                          </tr>
                        </table>

                      </div>

                    </div>

                    <div class="plan_main_img col-12 col-md-6 order-1 col-lg-4 order-md-2 order-lg-2">
                      <img class="plan_1_main_image" src="../../../../../assets/images/pricing/team_pricing_plan_1.svg">
                    </div>

                    <div class="col-12 col-md-12 order-3 col-lg-4 order-md-3 order-lg-3">

                      <div class="plan_price">
                        <br>
                        <div class="plan-price d-flex justify-content-center justify-content-md-end">
                          $245</div>
                        <br>
                        <div class="plan-users d-flex justify-content-center justify-content-md-end">per team
                          /
                          month
                        </div>
                        <br>
                        <div class="d-flex justify-content-center justify-content-md-end">
                          <button class="secondary_button1" data-toggle="modal" data-target="#contact-us-form" (click)="openContactUs()">Contact
                            Us</button>
                        </div>
                      </div>

                    </div>
                  </div>


                  <hr>


                  <div class="row">
                    <div class="col-lg-6">
                      <div class="card package_card">
                        <div class="card-body">
                          <h3 class="package_card_title">
                            <div class="d-flex justify-content-center justify-content-sm-start" style="font-weight: bold; font-size: 22px;">
                              Dashboard Views
                            </div>
                          </h3>

                          <div class="row">

                            <div class="col-12 col-sm-5">
                              <div class="d-flex justify-content-center justify-content-md-start">
                                <img class="package_card_img" src="../../../../../assets/images/pricing/add_dashboard.svg"
                                  style="width: 165px;">
                              </div>
                            </div>

                            <div class="col-12 col-sm-7">
                              <div class="d-flex justify-content-center justify-content-md-end">
                                <div class="package_card_price">
                                  <div class="team_option_price d-flex justify-content-center justify-content-md-start"
                                    style="font-size: 32px;font-weight: 600;">$10</div>
                                  <div class="team_option_per_user d-flex justify-content-center justify-content-md-start"
                                    style="font-size: 18px;">per
                                    user / month</div>
                                  <br>
                                  <button class="secondary_button1" data-toggle="modal" data-target="#contact-us-form"
                                    (click)="openContactUs()">Contact
                                    Us
                                  </button>

                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>


                    <div class="col-lg-6">
                      <div class="card package_card">
                        <div class="card-body">
                          <h3 class="package_card_title">
                            <div class="d-flex justify-content-center justify-content-sm-start" style="font-weight: bold; font-size: 22px;">
                              Extra Storage
                            </div>
                          </h3>

                          <div class="row">

                            <div class="col-12 col-sm-5">
                              <div class="d-flex justify-content-center justify-content-md-start">
                                <img class="package_card_img" src="../../../../../assets/images/pricing/add_storage.svg"
                                  style="width: 165px;">
                              </div>
                            </div>

                            <div class="col-12 col-sm-7">
                              <div class="d-flex justify-content-center justify-content-md-end">
                                <div class="package_card_price">

                                  <button class="secondary_button1" data-toggle="modal" data-target="#contact-us-form"
                                    (click)="openContactUs()">Contact
                                    Us
                                  </button>

                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>


                </div>
              </div>

              <br>
              <br>
            </div>

            <div class="carousel-item">




              <div class="card plan_card" style="box-shadow: 0 10px 30px rgba(113, 117, 226, 0.212) !important;">
                <div class="card-body">

                  <div class="d-flex justify-content-center">
                    <span id="basic_plan" style="color: #302f2f;" class="plan-name">Corporate</span>
                  </div>


                  <div class="row">
                    <div class="col-12 col-md-6 order-2 col-lg-4 order-md-1 order-lg-1">

                      <div class="plan_details_container d-flex justify-content-center justify-content-md-start justify-content-sm-center">

                        <table class=" d-flex justify-content-center justify-content-md-start">
                          <tr>
                            <td style="text-align: center">Got a big team and want to customize your package?</td>
                          </tr>

                        </table>

                      </div>

                    </div>

                    <div class="plan_main_img col-12 col-md-6 order-1 col-lg-4 order-md-2 order-lg-2">
                      <img class="plan_1_main_image" src="../../../../../assets/images/pricing/team_pricing_plan_1.svg">
                    </div>

                    <div class="col-12 col-md-12 order-3 col-lg-4 order-md-3 order-lg-3">

                      <div class="plan_price">
                        <br>
                        <div class="plan-price d-flex justify-content-center justify-content-md-end">
                          &nbsp;
                          <br>
                          <div class="plan-users d-flex justify-content-center justify-content-md-end">&nbsp;</div>
                          <br>
                          <div class="d-flex justify-content-center justify-content-md-end">
                            <button class="secondary_button1" data-toggle="modal" data-target="#contact-us-form"
                              (click)="openContactUs()">Contact
                              Us
                            </button>
                          </div>
                        </div>

                      </div>
                    </div>
                  </div>
                </div>


                <br>
                <br>
              </div>
            </div>

            <!-- Left and right controls -->
            <a class="carousel-control-prev" href="#main_price_card_carousel" data-slide="prev">
              <span class="carousel-control-prev-icon"></span>
            </a>
            <a class="carousel-control-next" href="#main_price_card_carousel" data-slide="next">
              <span class="carousel-control-next-icon"></span>
            </a>
          </div>

        </div>


      </div>
    </div>

    <!-- End: Price Plan ====================================== -->
  </div>



  <!-- faq section ========================================== -->

  <br><br><br>
  <app-section-title [sectionTitle]="'FAQ'"></app-section-title>



  <app-faq></app-faq>

</div>






<!-- Modal -->
<div class="modal fade" id="contact-us-form" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title"></h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <app-contact-us [resetStatus]="resetContactUs"></app-contact-us>
      </div>
      <div class="modal-footer">

      </div>
    </div>
  </div>
</div>