<div class="faq_container">

  <div id="accordion">
    <div class="card">
      <div class="faq_card_header" id="headingOne" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
        <h5 class="mb-0">
          <button class="btn btn-link">
            <span class="float-left">What kind of data can I analyze?</span>
            <i class="fa float-right" aria-hidden="true"></i>
          </button>

        </h5>
      </div>

      <div id="collapseOne" class="collapse show" aria-labelledby="headingOne" data-parent="#accordion">
        <div class="card-body">
          <p>You can analyze your data that is available in Excel or CSV, structured in a tabular manner where the first
            row contains the column headers</p>
        </div>
      </div>
    </div>

    <div class="card">
      <div class="faq_card_header" id="headingTwo" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
        <h5 class="mb-0">
          <button class="btn btn-link">
            <span class="float-left">Will I get charged before the trial?</span>
            <i class="fa float-right" aria-hidden="true"></i>
          </button>
        </h5>
      </div>
      <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo" data-parent="#accordion">
        <div class="card-body">
          <p>Absolutely not, you will not be charged a single dime until the end of the trial period</p>
        </div>
      </div>
    </div>

    <div class="card">
      <div class="faq_card_header" id="headingThree" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false"
        aria-controls="collapseThree">
        <h5 class="mb-0">
          <button class="btn btn-link">
            <span class="float-left">Can I unsubscribe before my trial ends?</span>
            <i class="fa float-right" aria-hidden="true"></i>
          </button>
        </h5>
      </div>
      <div id="collapseThree" class="collapse" aria-labelledby="headingThree" data-parent="#accordion">
        <div class="card-body">
          <p>Of course you can. All you need to do is just unsubscribe via the ‘My Accounts Section’ or drop us an email
            to support@zepto.io</p>
        </div>
      </div>
    </div>

    <div class="card">
      <div class="faq_card_header" id="headingFour" data-toggle="collapse" data-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
        <h5 class="mb-0">
          <button class="btn btn-link">
            <span class="float-left">How secure my data is?</span>
            <i class="fa float-right" aria-hidden="true"></i>
          </button>
        </h5>
      </div>
      <div id="collapseFour" class="collapse" aria-labelledby="headingFour" data-parent="#accordion">
        <div class="card-body">
          <p>First and foremost we don’t keep any data with us. Your entire data will be stored on Amazon Web Services (AWS)
            platform. Talking about AWS security, they comply with ISO 27018, a code of practice that focuses on protection
            of personal data in the cloud. So your data is in good hands!
          </p>
        </div>
      </div>
    </div>

    <div class="card">
      <div class="faq_card_header" id="headingFive" data-toggle="collapse" data-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
        <h5 class="mb-0">
          <button class="btn btn-link">
            <span class="float-left">Can I contact an agent before subscribing?</span>
            <i class="fa float-right" aria-hidden="true"></i>
          </button>
        </h5>
      </div>
      <div id="collapseFive" class="collapse" aria-labelledby="headingFive" data-parent="#accordion">
        <div class="card-body">
          <p>Absolutely, all you need to do is just write an email to support@zepto.io requesting for support. One of our
            agents would get back to you ASAP
          </p>
        </div>
      </div>
    </div>
  </div>

</div>