import { Component, OnInit, Input, SimpleChanges } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { WebService } from '../../../core/services/web-service/web.service';
import { ApiEndpont } from '../../../core/api-endpoints/api-endpoint';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss'],
  providers: [WebService]

})
export class ContactUsComponent implements OnInit {

  public loading: boolean = false;
  public showContactUsForm: boolean = true;
  contactUsForm: UntypedFormGroup;
  private endPoint = ApiEndpont;
  @Input() resetStatus;

  constructor(private fb: UntypedFormBuilder, private webservice: WebService) {

    this.contactUsForm = fb.group({
      'name': ['', [Validators.required]],
      'company': ['', [Validators.required]],
      'companySize': ['', [Validators.required]],
      'subject': ['', [Validators.required]],
      'email': ['', [Validators.required, Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$")]],
      'message': ['', [Validators.required]]
    });

  }

  ngOnInit() {
    this.showContactUsForm = true;
  }


  ngOnChanges(changes: SimpleChanges) {
    this.contactUsForm.reset();
    this.contactUsForm.controls['companySize'].setValue("");
    this.showContactUsForm = true;
  }

  contactUS(contactUsObj) {

    this.loading = true;

    let contactUsPayload = {
      company: contactUsObj.company,
      contactNum: "---",
      designation: "Not Available",
      email: contactUsObj.email,
      message: "Subject: " + contactUsObj.subject + "<br>Employee Size: " + contactUsObj.companySize + "<br>Message: " + contactUsObj.message,
      name: contactUsObj.name,
      subject: contactUsObj.subject
    }

    this.webservice.processPost(this.endPoint.contactUs.contact, contactUsPayload).subscribe(response => {

      this.loading = false;
      this.showContactUsForm = false;


      if (response["message"] == "SUCCESS") {

      } else {

      }
    }, error => {
      console.log("ERRORRRR, error")
      this.loading = false;
    })
  }

}