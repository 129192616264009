<!-- <div class="header-1">
  <h1 class="header_title">
    World's Simplest BI & Analytics Tool
  </h1>
  <h1 class="header_desc">
    An AI-powered data analytical tool for everyone
  </h1>

  <div class="try-for-free-btn">
    <button *ngIf="goToApp == 2" class="primary_button" [routerLink]="['/signup']">Try for free</button>
    <button *ngIf="goToApp == 1" class="primary_button" onclick="location.href='/app/datalocker';">Try for free</button>
  </div>
</div>

<div class="header-2">
  <h1 class="header_title">
    The Elite Data Scientist for Healthcare
  </h1>
  <h1 class="header_desc">
    Improving quality of outcomes, patient satisfaction and optimization of resources through ZEPTO's AI Powered Data
    Analytics
  </h1>

  <div class="try-for-free-btn">
    <button class="primary_button" (click)="scroll()">Contact Us</button>
  </div>
</div> -->


<div id="homepage-text-slider" class="homepage-text-slider">

  <input type="radio" class="radio" name="slider" id="slide1" />
  <input type="radio" class="radio" name="slider" id="play1" checked="" />

  <div class="text-container">
    <div class="text-container-inner">
      <div class="text-slide">

        <h1 class="header_title">
          World's Simplest BI & Analytics Tool
        </h1>
        <h1 class="header_desc">
          An AI-powered data analytical tool for everyone
        </h1>

        <div class="try-for-free-btn">
          <button *ngIf="goToApp == 2" class="primary_button" [routerLink]="['/signup']">Try for free</button>
          <button *ngIf="goToApp == 1" class="primary_button" onclick="location.href='/app/datalocker';">Try for
            free</button>
        </div>

      </div>
      <div class="text-slide">

        <h1 class="header_title">
          Data Science in Healthcare
        </h1>
        <h1 class="header_desc">
          Improving outcomes, patient satisfaction and resource optimization using AI Powered Data Analytics
        </h1>

        <div class="try-for-free-btn">
          <a class="primary_button" [routerLink]="['/zepto-for-healthcare']" target="_blank">Check it out</a>
        </div>

      </div>
    </div>
  </div>


</div>