<div class="section-title">
  <!-- <app-section-title [sectionTitle]="'Analytic Heroes'"></app-section-title> -->

  <div class="title_cloud_container">

    <div class="flex-container">
      <div>
        <img class="title_left_part float-right" src="../../../../../assets/images/shared/h2_bg-left_part.svg">
      </div>
      <div>Analytic Heroes</div>
      <div>
        <img class="title_right_part float-left" src="../../../../../assets/images/shared/h2_bg-right_part.svg">
      </div>
    </div>
  </div>

</div>

<div class="top-desc-lg">
  <div>
    A team of diverse and data-driven individuals committed to creating a product people love and
    would empower them to achieve greater heights using DATA
  </div>
</div>

<div class="team-group">
  <img src="../../../../../assets/images/team/team-group.jpg">
</div>

<div class="top-desc-sm">
  <div>
    A team of diverse and data-driven individuals committed to creating a product people love and
    would empower them to achieve greater heights using DATA
  </div>
</div>


<div class="team-container">

  <div class="team-title" id="team-title">
    Meet Our Team
  </div>


  <div class="large-screen">
    <!-- apurva's section -->
    <div class="team-member-container">
      <div class="row">
        <div class="col-3">
          <div class="image-cropper" [ngClass]="{'show-more-image-cropper': showMoreId == 1}">
            <div class="rounded apurva-rounded" [ngClass]="{'show-more-rounded': showMoreId == 1}"></div>
          </div>
        </div>
        <div class="col-9">
          <div class="details">
            <div class="name">
              Apurva Udeshi
            </div>
            <div class="position">
              Founder
            </div>
            <div class="info">
              <p>
                Apurva Udeshi is the Founder of Zepto, which he launched in 2016. He is the visionary behind
                the Zepto platform & strategy, incorporating the best of AI and ML with an intuitive user
                experience suited to the way people work today. <span *ngIf="showMoreId == 1" class="more" [ngClass]="{'show-more-info': showMoreId == 1}">Apurva
                  leads the company in its mission to
                  transform the way people and businesses approach data.</span>
              </p>

              <p *ngIf="showMoreId == 1" class="more" [ngClass]="{'show-more-info': showMoreId == 1}">
                Apurva is also the Managing Director of Argent Capital - a sector agnostic structured fund that
                invests across Private Equity, Listed Equity, Fixed Income Trading, Real Estate, and Startups.
                Apurva in his personal capacity is an active angel investor. He is the founding Board Member of
                the Lankan Angel Network (which is tied up with India’s largest Angel Network - the Indian Angel
                Network).
              </p>

              <p *ngIf="showMoreId == 1" class="more" [ngClass]="{'show-more-info': showMoreId == 1}">
                Apurva attended Imperial College, London where he graduated with First Class (Hons) in
                Computing and was placed in the Top 10% of his Class. Prior to his current roles, he was a
                Convertible Bond Trader at Nomura International Plc, London and headed up the Fixed Income
                Trading Desk for Kotak Mahindra Bank in London
              </p>
            </div>

          </div>

          <div class="row">
            <div class="col-8">
              <button *ngIf="showMoreId != 1" class="primary_button" (click)="showMore(1)">Info + </button>
            </div>
            <div class="col-4 social-container">
              <button class="social-btn">
                <a href="https://www.linkedin.com/in/apurva-udeshi-7a1b2b15/">
                  <img src="../../../../../assets/images/team/social/linkedin.svg">
                </a>
              </button>
              <button class="social-btn">
                <a href="https://www.facebook.com/zeptobi/">
                  <img src="../../../../../assets/images/team/social/facebook.svg">
                </a>
              </button>
              <button class="social-btn">
                <a href="https://twitter.com/ApurvaUdeshi">
                  <img src="../../../../../assets/images/team/social/twitter.svg">
                </a>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- amal's section -->
    <div class="team-member-container">
      <div class="row">
        <div class="col-3">
          <div class="image-cropper" [ngClass]="{'show-more-image-cropper': showMoreId == 2}">
            <div class="rounded amal-rounded" [ngClass]="{'show-more-rounded': showMoreId == 2}"></div>
          </div>
        </div>
        <div class="col-9">
          <div class="details">
            <div class="name">
              Amal Vinoth
            </div>
            <div class="position">
              Head of Business Development
            </div>
            <div class="info">
              <p>
                Amal is the Head of Business Development and Platform Sales at Zepto. Amal oversees our
                customer-focused business functions at Zepto, including sales, marketing, customer
                experience, partnerships, business strategy and operations.
              </p>

              <p *ngIf="showMoreId == 2" class="more" [ngClass]="{'show-more-info': showMoreId == 2}">
                Amal brings in over 10 years or
                industry experience. Prior to joining Zepto, Amal was a Senior Executive in the Corporate
                Finance Division of CIC Holdings (a diversified conglomerate) and a Financial Auditor at
                PricewaterhouseCoopers, Sri Lanka. He is also a Founding Partner at AccuFlex Accounting
              </p>

              <p *ngIf="showMoreId == 2" class="more" [ngClass]="{'show-more-info': showMoreId == 2}">
                Amal holds a Diploma in Accounting & Business, CASL
              </p>
            </div>

          </div>

          <div class="row">
            <div class="col-8">
              <button *ngIf="showMoreId != 2" class="primary_button" (click)="showMore(2)">Info + </button>
            </div>
            <div class="col-4 social-container">
              <button class="social-btn">
                <a href="https://www.linkedin.com/in/amal-vinoth-a95341b1/">
                  <img src="../../../../../assets/images/team/social/linkedin.svg">
                </a>
              </button>
              <button class="social-btn">
                <a href="https://www.facebook.com/amal.vinoth.5">
                  <img src="../../../../../assets/images/team/social/facebook.svg">
                </a>
              </button>
              <button class="social-btn">
                <a href="https://twitter.com/zeptolytics">
                  <img src="../../../../../assets/images/team/social/twitter.svg">
                </a>
              </button>
            </div>
          </div>

        </div>
      </div>
    </div>



    <!-- suresh's section -->
    <div class="team-member-container">
      <div class="row">
        <div class="col-3">
          <div class="image-cropper" [ngClass]="{'show-more-image-cropper': showMoreId == 3}">
            <div class="rounded suresh-rounded" [ngClass]="{'show-more-rounded': showMoreId == 3}"></div>
          </div>
        </div>
        <div class="col-9">
          <div class="details">
            <div class="name">
              Suresh Sandirasegaram
            </div>
            <div class="position">
              Chief Technology Officer
            </div>
            <div class="info">
              <p>
                Suresh Sandirasegaram is the Chief Technology Officer at Zepto. Suresh leads the architecture, design,
                and engineering of the product in driving Zepto’s long-term roadmap and vision for data analytics into
                a
                viable product.
              </p>

              <p *ngIf="showMoreId == 3" class="more" [ngClass]="{'show-more-info': showMoreId == 3}">
                Before joining Zepto, Suresh was a Lead Software Engineer at SyscoLabs. He has over 15 years of
                experience in Software Engineering and holds a BSc(Computing) and a PG-Dip in Engineering (Electrical
                and
                Electronics Engineering)
              </p>

            </div>

          </div>

          <div class="row">
            <div class="col-8">
              <button *ngIf="showMoreId != 3" class="primary_button" (click)="showMore(3)">Info + </button>
            </div>
            <div class="col-4 social-container">
              <button class="social-btn">
                <a href="https://www.linkedin.com/in/suresh-sandirasegaram-3ba640113/">
                  <img src="../../../../../assets/images/team/social/linkedin.svg">
                </a>
              </button>
              <button class="social-btn">
                <a href="https://www.facebook.com/zeptobi/">
                  <img src="../../../../../assets/images/team/social/facebook.svg">
                </a>
              </button>
              <button class="social-btn">
                <a href="https://twitter.com/zeptolytics">
                  <img src="../../../../../assets/images/team/social/twitter.svg">
                </a>
              </button>
            </div>
          </div>

        </div>
      </div>

    </div>
  </div>




  <div class="small-screen">


    <div class="team-member-tile" (click)="selectMemberSm(1)" *ngIf="isSelectMember != true">
      <img src="../../../../../assets/images/team/mobile/apurva.svg">
      <div class="name">
        Apurva Udeshi
      </div>
      <div class="position">
        Founder
      </div>
    </div>

    <div class="team-member-tile" (click)="selectMemberSm(2)" *ngIf="isSelectMember != true">
      <img src="../../../../../assets/images/team/mobile/amal.svg">
      <div class="name">
        Amal Vinoth
      </div>
      <div class="position">
        Head of Business Development
      </div>
    </div>

    <div class="team-member-tile" (click)="selectMemberSm(3)" *ngIf="isSelectMember != true">
      <img src="../../../../../assets/images/team/mobile/suresh.svg">
      <div class="name">
        Suresh Sandirasegaram
      </div>
      <div class="position">
        Chief Technology Officer
      </div>
    </div>





    <div id="details-info">
      <div class="row">
        <div class="col-1 left-arrow" *ngIf="selectedMember != 0">
          <img (click)="previous()" src="../../../../../assets/images/team/mobile/left-arrow.svg">
        </div>
        <div class="col-10">
          <div id="apurva-section" class="team-member-tile-details" *ngIf="selectedMember == 1">
            <img src="../../../../../assets/images/team/mobile/apurva.svg">

            <div class="name">
              Apurva Udeshi
            </div>
            <div class="position">
              Founder
            </div>

            <div class="info">
              <p>
                Apurva Udeshi is the Founder of Zepto, which he launched in 2016. He is the visionary behind
                the Zepto platform & strategy, incorporating the best of AI and ML with an intuitive user
                experience suited to the way people work today. <span *ngIf="showMoreId == 1" class="more" [ngClass]="{'show-more-info': showMoreId == 1}">Apurva
                  leads the company in its mission to
                  transform the way people and businesses approach data.</span>
              </p>

              <p>
                Apurva is also the Managing Director of Argent Capital - a sector agnostic structured fund that
                invests across Private Equity, Listed Equity, Fixed Income Trading, Real Estate, and Startups.
                Apurva in his personal capacity is an active angel investor. He is the founding Board Member of
                the Lankan Angel Network (which is tied up with India’s largest Angel Network - the Indian Angel
                Network).
              </p>

              <p>
                Apurva attended Imperial College, London where he graduated with First Class (Hons) in
                Computing and was placed in the Top 10% of his Class. Prior to his current roles, he was a
                Convertible Bond Trader at Nomura International Plc, London and headed up the Fixed Income
                Trading Desk for Kotak Mahindra Bank in London
              </p>
            </div>


            <div class="sm-social-container">
              <button class="social-btn">
                <a href="https://www.linkedin.com/in/apurva-udeshi-7a1b2b15/">
                  <img src="../../../../../assets/images/team/social/linkedin.svg">
                </a>
              </button>
              <button class="social-btn">
                <a href="https://www.facebook.com/zeptobi">
                  <img src="../../../../../assets/images/team/social/facebook.svg">
                </a>
              </button>
              <button class="social-btn">
                <a href="https://twitter.com/ApurvaUdeshi">
                  <img src="../../../../../assets/images/team/social/twitter.svg">
                </a>
              </button>
            </div>

          </div>



          <div id="amal-section" class="team-member-tile-details" *ngIf="selectedMember == 2">
            <img src="../../../../../assets/images/team/mobile/amal.svg">

            <div class="name">
              Amal Vinoth
            </div>
            <div class="position">
              Head of Business Development
            </div>

            <div class="info">
              <p>
                Amal is the Head of Business Development and Platform Sales at Zepto. Amal oversees our
                customer-focused business functions at Zepto, including sales, marketing, customer
                experience, partnerships, business strategy and operations.
              </p>

              <p>
                Amal brings in over 10 years or
                industry experience. Prior to joining Zepto, Amal was a Senior Executive in the Corporate
                Finance Division of CIC Holdings (a diversified conglomerate) and a Financial Auditor at
                PricewaterhouseCoopers, Sri Lanka. He is also a Founding Partner at AccuFlex Accounting
              </p>

              <p>
                Amal holds a Diploma in Accounting & Business, CASL
              </p>
            </div>


            <div class="sm-social-container">
              <button class="social-btn">
                <a href="https://www.linkedin.com/in/amal-vinoth-a95341b1/">
                  <img src="../../../../../assets/images/team/social/linkedin.svg">
                </a>
              </button>
              <button class="social-btn">
                <a href="https://www.facebook.com/amal.vinoth.5">
                  <img src="../../../../../assets/images/team/social/facebook.svg">
                </a>
              </button>
              <button class="social-btn">
                <a href="https://twitter.com/zeptolytics">
                  <img src="../../../../../assets/images/team/social/twitter.svg">
                </a>
              </button>
            </div>

          </div>




          <div id="suresh-section" class="team-member-tile-details" *ngIf="selectedMember == 3">
            <img src="../../../../../assets/images/team/mobile/suresh.svg">

            <div class="name">
              Suresh Sandirasegaram
            </div>
            <div class="position">
              Chief Technology Officer
            </div>

            <div class="info">
              <p>
                Suresh Sandirasegaram is the Chief Technology Officer at Zepto. Suresh leads the architecture, design,
                and engineering of the product in driving Zepto’s long-term roadmap and vision for data analytics into
                a
                viable product.
              </p>

              <p>
                Before joining Zepto, Suresh was a Lead Software Engineer at SyscoLabs. He has over 15 years of
                experience in Software Engineering and holds a BSc(Computing) and a PG-Dip in Engineering (Electrical
                and
                Electronics Engineering)
              </p>
            </div>



            <div class="sm-social-container">
              <button class="social-btn">
                <a href="https://www.linkedin.com/in/suresh-sandirasegaram-3ba640113/">
                  <img src="../../../../../assets/images/team/social/linkedin.svg">
                </a>
              </button>
              <button class="social-btn">
                <a href="https://www.facebook.com/zeptobi/">
                  <img src="../../../../../assets/images/team/social/facebook.svg">
                </a>
              </button>
              <button class="social-btn">
                <a href="https://twitter.com/zeptolytics">
                  <img src="../../../../../assets/images/team/social/twitter.svg">
                </a>
              </button>
            </div>

          </div>

        </div>
        <div class="col-1 right-arrow" *ngIf="selectedMember != 0">
          <img (click)="next()" src="../../../../../assets/images/team/mobile/right-arrow.svg">
        </div>
      </div>

    </div>









    <div class="team-bottom" *ngIf="isSelectMember == true">
      <div class="row">
        <div class="col-4">

          <div class="team-bottom-tile-sm" (click)="selectMemberSm(1)" [ngClass]="{'team-bottom-lg': selectedMember == 1, 'team-bottom-lg-top':selectedMember != 1}">
            <img src="../../../../../assets/images/team/mobile/apurva.svg">
            <div class="name">
              Apurva Udeshi
            </div>
            <div class="position">
              Founder
            </div>
          </div>

        </div>
        <div class="col-4">

          <div class="team-bottom-tile-sm" (click)="selectMemberSm(2)" [ngClass]="{'team-bottom-lg': selectedMember == 2, 'team-bottom-lg-top':selectedMember != 2}">
            <img src="../../../../../assets/images/team/mobile/amal.svg">
            <div class="name">
              Amal Vinoth
            </div>
            <div class="position">
              Head of Business Development
            </div>
          </div>

        </div>
        <div class="col-4">

          <div class="team-bottom-tile-sm" (click)="selectMemberSm(3)" [ngClass]="{'team-bottom-lg': selectedMember == 3, 'team-bottom-lg-top':selectedMember != 3}">
            <img src="../../../../../assets/images/team/mobile/suresh.svg">
            <div class="name">
              Suresh Sandirasegaram
            </div>
            <div class="position">
              Chief Technology Officer
            </div>
          </div>

        </div>
      </div>
    </div>

  </div>