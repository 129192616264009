<div class="pre-release-container">
  <div class="container">
    <div class="row">
      <div class="col-12 col-md-5">
        <div class="header-logo">
          <img src="../../../../assets/images/header/logo.svg">
        </div>
        <div class="hero-text">
          First <span>100</span><br>
          users get free<br>
          usage for a month
        </div>
        <div class="second-display-container">
          <div class="row">
            <div class="col-4">
              <div class="box">
                <img src="../../../../assets/images/landing-pages/box-1.svg">
              </div>
              <div class="box-title">AI CHATBOT</div>
            </div>
            <div class="col-4">
              <div class="box">
                <img src="../../../../assets/images/landing-pages/box-2.svg">
              </div>
              <div class="box-title"> ANTI – FRAUD ALGORITHMS</div>
            </div>
            <div class="col-4">
              <div class="box">
                <img src="../../../../assets/images/landing-pages/box-3.svg">
              </div>
              <div class="box-title">TRIAGE CLAIMS EFFECTIVELY</div>
            </div>
          </div>
        </div>
        <div class="description">
          <p>We can build insurance software solution add on’s using ZEPTO, answer any questions you have, help you
            reduce claim cycle time and create better possibilities of tomorrow.</p>

          <p>Talk to us and find out how we can help in your journey!</p>
        </div>
      </div>
      <div class="col-12 col-md-7">
        <div class="pre-release-form">
          <div class="form-container">
            <div class="form-title">
              PRE - RELEASE
              <div>SIGN UP</div>
            </div>
            <div class="desc">
              Thank you for showing interest in ZEPTO’s predictive analytics feature. Our
              software solutions will help claim cycle time to be reduced significantly, add to profitability of the
              insurance company and increase customer satisfaction and retention rates.
            </div>

            <form [formGroup]="registerForm" (ngSubmit)="onSubmit()">
              <div class="form-group">
                <label for="exampleInputEmail1">Name</label>
                <input type="text" formControlName="name" class="form-control" id="exampleInputEmail1"
                  aria-describedby="emailHelp" placeholder="Your name">
                <small *ngIf="submitted && f.name.errors" class="form-text text-muted form-error-msg">
                  <span *ngIf="f.name.errors.required">Please enter name.</span>
                </small>
              </div>
              <div class="form-group">
                <label for="exampleInputEmail1">Email</label>
                <input type="email" formControlName="email" class="form-control" id="exampleInputEmail1"
                  aria-describedby="emailHelp" placeholder="Your email">
                <small *ngIf="submitted && f.email.errors" class="form-text text-muted form-error-msg">
                  <span *ngIf="f.email.errors.required">Please enter an email.</span>
                  <span *ngIf="f.email.errors.pattern != undefined">Please enter a correct email.</span>
                </small>
              </div>
              <div class="form-group">
                <label for="exampleInputEmail1">Phone Number</label>
                <div class="input-group mb-3">
                  <div class="input-group-prepend">
                    <button class="btn btn-outline-secondary dropdown-toggle" type="button" data-toggle="dropdown"
                      aria-haspopup="true" aria-expanded="false">+{{selectedCode}}</button>
                    <div class="dropdown-menu">
                      <div *ngFor="let code of countryCode" class="dropdown-item"
                        (click)="setCountryCode(code.COUNTRY_CODE)">{{code.COUNTRY}}</div>
                    </div>
                  </div>
                  <input type="number" formControlName="phone" class="form-control"
                    aria-label="Text input with dropdown button" placeholder="Your phone number">
                </div>
              </div>

              <div class="form-group">
                <label for="exampleFormControlTextarea1">Leave a Message</label>
                <textarea class="form-control" formControlName="message" id="exampleFormControlTextarea1" rows="8"
                  placeholder="Your message"></textarea>
              </div>
              <div class="register-btn-container">
                <button type="submit" class="btn register-btn">Register Now</button>
              </div>
              <br>
            </form>

          </div>
          <div class="thank-you-container" *ngIf="formSubmitted">
            <div class="content">
              <img src="../../../../assets/images/landing-pages/thank-you.svg">
              <div class="thank-you-text">
                THANK YOU
              </div>
              <div class="thank-you-desc">
                We are excited to have you on board! Our team will get in touch with you shortly.
              </div>
              <button type="button" class="btn explore-btn" [routerLink]="['/']">Explore Zepto</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>