import { BrowserModule, BrowserTransferStateModule } from '@angular/platform-browser';
import { NgModule, ErrorHandler, Injectable } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
// import { CookieService } from 'ngx-cookie-service';
// import { CookieService } from 'ngx-cookie';
import { CookieModule, CookieService } from 'ngx-cookie';
import { ToastrModule } from 'ng6-toastr-notifications';
// import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
import * as Sentry from '@sentry/browser';

import { CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { HeaderComponent } from './core/header/header.component';
import { FooterComponent } from './core/footer/footer.component';
import { HomeComponent } from './modules/home/pages/home/home.component';
import { CoverHeaderComponent } from './modules/home/components/cover-header/cover-header.component';
import { CoverAnimationComponent } from './modules/home/components/cover-animation/cover-animation.component';
import { StoryVideoComponent } from './modules/home/components/story-video/story-video.component';
import { ThreeSimpleStepsComponent } from './modules/home/components/three-simple-steps/three-simple-steps.component';
import { KeyFeaturesComponent } from './modules/home/components/key-features/key-features.component';
import { ComingSoonComponent } from './modules/home/components/coming-soon/coming-soon.component';
import { TrustedSectionComponent } from './modules/home/components/trusted-section/trusted-section.component';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { NgxGalleryModule } from '@kolkov/ngx-gallery';
import { CountToModule } from 'angular-count-to';
import { CountsComponent } from './modules/about-us/components/counts/counts.component';
import { AboutUsComponent } from './modules/about-us/pages/about-us/about-us.component';
import { BlogComponent } from './modules/blog/pages/blog/blog.component';
import { FAQComponent } from './modules/pricing/components/faq/faq.component';
import { PricingComponent } from './modules/pricing/pages/pricing/pricing.component';
import { PrivacyPolicyComponent } from './modules/privacy-policy/pages/privacy-policy/privacy-policy.component';
import { TermsComponent } from './modules/terms/terms/terms.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SectionTitleComponent } from './shared/components/section-title/section-title.component';
import { ContactUsComponent } from './shared/components/contact-us/contact-us.component';
import { LoaderComponent } from './shared/components/loader/loader.component';
import { TeamComponent } from './modules/team/pages/team/team.component';

import { SignUpComponent } from './modules/signup/pages/sign-up/sign-up.component';
import { SuccessComponent } from './modules/signup/components/success/success.component';
import { SignInComponent } from './modules/signin/pages/sign-in/sign-in.component';
import { ResetPasswordComponent } from './modules/signin/components/reset-password/reset-password.component';
import { NotFoundComponent } from './core/not-found/not-found.component';
import { InternetInterruptedComponent } from './core/internet-interrupted/internet-interrupted.component';
import { AdvertisementComponent } from './modules/landing-pages/advertisement/advertisement.component';
import { RecaptchaModule, RecaptchaFormsModule, RECAPTCHA_SETTINGS, RecaptchaSettings } from 'ng-recaptcha';
import { PreReleaseComponent } from './modules/landing-pages/pre-release/pre-release.component';
import { HealthcareComponent } from './modules/landing-pages/healthcare/healthcare.component';


  Sentry.init({
    dsn: 'https://faad91062e5f47dd8692dbe702dbe86b@pulse.zepto.io/3',
    integrations: integrations => {
      // integrations will be all default integrations
      return integrations.filter(integration => integration.name !== 'Breadcrumbs');
    }
  });

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,


    HomeComponent,
    CoverHeaderComponent,
    CoverAnimationComponent,
    StoryVideoComponent,
    ThreeSimpleStepsComponent,
    KeyFeaturesComponent,
    ComingSoonComponent,
    TrustedSectionComponent,

    AboutUsComponent,
    CountsComponent,

    BlogComponent,

    PricingComponent,
    FAQComponent,

    PrivacyPolicyComponent,

    TermsComponent,
    SignUpComponent,
    SignInComponent,

    LoaderComponent,
    ContactUsComponent,
    SectionTitleComponent,
    SuccessComponent,
    ResetPasswordComponent,
    TeamComponent,
    SignInComponent,
    NotFoundComponent,
    InternetInterruptedComponent,
    AdvertisementComponent,
    PreReleaseComponent,
    HealthcareComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    BrowserTransferStateModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    NgxGalleryModule,
    SlickCarouselModule,
    CountToModule,
    FormsModule,
    ReactiveFormsModule,
    ReactiveFormsModule,
    RecaptchaModule,
    RecaptchaFormsModule,
    ToastrModule.forRoot(),
    CookieModule.forRoot(),
    // NgIdleKeepaliveModule.forRoot()
  ],
  providers: [{
    provide: RECAPTCHA_SETTINGS,
    useValue: {
      siteKey: '6LeZkqoUAAAAACZD0Ek4PY9qp-QWptJTuZjBhRX-',
    } as RecaptchaSettings,
  }],
  bootstrap: [AppComponent]
})
export class AppModule { }