<div class="not-found-container">

  <div class="not-found-image">
    <img src="../../../assets/images/404/404.svg">
  </div>

  <div class="oops">
    Oops!
  </div>

  <div class="oops-description">
    This page couldn’t be found. Please check your URL and try again.
  </div>

  <div class="goto-home">
    <a [routerLink]="['/']">
      Go to home page
    </a>
  </div>

</div>