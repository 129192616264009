<!-- ==================== Start: nav bar ====================-->

<nav class="navbar navbar-expand-lg navbar-light nav-section">
  <!-- header logo -->
  <a class="navbar-brand" href="/">
    <img src="../../../assets/images/header/logo.svg" height="40" alt="Logo">
  </a>

  <!-- header toggle -->
  <button *ngIf="showHideNav" class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
    aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>

  <div class="collapse navbar-collapse" id="navbarSupportedContent" *ngIf="showHideNav">

    <ul class="navbar-nav mr-auto">

    </ul>

    <div class="form-inline">
      <ul class="navbar-nav mr-auto d-flex align-content-md-center">
        <a class="nav-item nav-link" [routerLink]="['/aboutus']">Our Story</a>
        <a class="nav-item nav-link" [routerLink]="['/team']">Team</a>
        <!--<a class="nav-item nav-link" [routerLink]="['/blog']">Blog</a>-->
        <a class="nav-item nav-link" [routerLink]="['/pricing']">Pricing</a>
        <a *ngIf="!signInStatus" class="nav-item nav-link" [routerLink]="['/login']">Sign In</a>
        <a *ngIf="!signInStatus" class="nav-item nav-link" [routerLink]="['/signup']">
          <button class="primary_button" (click)="resetSignUp()">Sign Up</button>
        </a>

        <a *ngIf="signInStatus" class="nav-item nav-link goto-app">
          <button class="primary_button" (click)="gotoApp()">Go to app</button>
        </a>

        <div *ngIf="signInStatus" class="user-profile">

          <div class="btn-group">
            <div data-toggle="dropdown" aria-expanded="false">
              <img src="../../../assets/images/header/profile.svg">
            </div>
            <div class="dropdown-menu dropdown-menu-right">

              <div class="profle-dropdown-image">
                <img class="dropdown-profile-img" src="../../../assets/images/header/profile.svg">

              </div>
              <button class="dropdown-item logout" type="button" (click)="logOut()">Logout</button>
            </div>
          </div>

        </div>
      </ul>
    </div>

  </div>

</nav>
<!-- ==================== End: nav bar  ==================== -->