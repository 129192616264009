import { Component, OnInit } from '@angular/core';
import { WebService } from '../../../../core/services/web-service/web.service';
import { CookieService } from 'ngx-cookie';
import { ApiEndpont } from '../../../../core/api-endpoints/api-endpoint';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-cover-header',
  templateUrl: './cover-header.component.html',
  styleUrls: ['./cover-header.component.scss'],
  providers: [WebService, CookieService]
})
export class CoverHeaderComponent implements OnInit {

  // 0 for no option, 1 gor goto app, 2 for get started
  public goToApp: number = 0;
  private endPoint = ApiEndpont;

  constructor(
    private webservice: WebService,
    private cookieService: CookieService
  ) { }

  ngOnInit() {
    this.validateToken();
  }

  validateToken() {
    const tokenPayload = {
      token: this.cookieService.get("AUTH_TOKEN")
    }
    return this.webservice.processPost(this.endPoint.auth.validateToken, tokenPayload)
      .pipe(map(
        (response) => {
          if (response['status'] == "valid") {
            // enable go to app
            this.goToApp = 1;
          } else {
            // enable get started
            this.goToApp = 2;
          }
        }
      ))
      .subscribe(
        (data) => { },
        (error) => {
          // enable get started
          this.goToApp = 2;
        }
      );
  }

}
