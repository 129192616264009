<div class="feature_grid">

  <app-section-title [sectionTitle]="'Key Features'"></app-section-title>


  <div class="row">

    <div class="col-6 col-sm-6 col-md-6">
      <div class="card">
        <div class="card-body features_card_body">
          <div class="row">
            <div class="col-12 col-md-12 col-lg-4">
              <img src="../../../../../assets/images/home/ai.svg">
              <h2 class="feature_card-title">AI Powered</h2>
            </div>

            <div class="col-sm-8 col-md-12 col-lg-8 feature_desc">
              With one click and our integrated AI platform throws the most relevant insights for the user
            </div>
          </div>
        </div>
      </div>
    </div>


    <div class="col-6 col-sm-6 col-md-6">
      <div class="card">
        <div class="card-body features_card_body">
          <div class="row">
            <div class="col-12 col-md-12 col-lg-4">
              <img src="../../../../../assets/images/home/search-1.svg">
              <h2 class="feature_card-title">Search</h2>
            </div>

            <div class="col-sm-8 col-md-12 col-lg-8 feature_desc">
              Using our NLP Platform, users can simply type the question they are looking for!
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-6 col-sm-6 col-md-6">
      <div class="card">
        <div class="card-body features_card_body">
          <div class="row">
            <div class="col-12 col-md-12 col-lg-4">
              <img src="../../../../../assets/images/home/prediction.svg">
              <h2 class="feature_card-title">Prediction</h2>
            </div>

            <div class="col-sm-8 col-md-12 col-lg-8 feature_desc">
              Know where your business is headed by using AI-powered advanced forecasting & predictive models
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-6 col-sm-6 col-md-6">
      <div class="card">
        <div class="card-body features_card_body">
          <div class="row">
            <div class="col-12 col-md-12 col-lg-4">
              <img src="../../../../../assets/images/home/intuition.svg">
              <h5 class="feature_card-title">Dashboard</h5>
            </div>

            <div class="col-sm-8 col-md-12 col-lg-8 feature_desc">
              Set your KPIs and create your own dashboard in just 3 clicks... all by yourself!
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-6 col-sm-6 col-md-6">
      <div class="card">
        <div class="card-body features_card_body">
          <div class="row">
            <div class="col-12 col-md-12 col-lg-4">
              <img src="../../../../../assets/images/home/collaberation.svg">
              <h2 class="feature_card-title">Collaborative</h2>
            </div>

            <div class="col-sm-8 col-md-12 col-lg-8 feature_desc">
              All files, charts and dashboards created can be collaboratively worked on with one source file.
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-6 col-sm-6 col-md-6">
      <div class="card">
        <div class="card-body features_card_body">
          <div class="row">
            <div class="col-12 col-md-12 col-lg-4">
              <img src="../../../../../assets/images/home/vr.svg">
              <h2 class="feature_card-title">Virtual Reality</h2>
            </div>

            <div class="col-sm-8 col-md-12 col-lg-8 feature_desc">
              Experience your data in Virtual Reality with highly detailed charts generated by a 3D VR engine
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</div>