<div class="trusted_section">

  <app-section-title [sectionTitle]="'Trusted by big to small brands around the world!'"></app-section-title>


  <div class="trusted_content">

    <div class="trusted_container">

      <div>
        <p class="trust_desc" style="margin-left: 25%; margin-right: 25%;">
          “Most repetitive analytical exercises carried out within excel can now be very easily integrated into the
          Zepto platform.
          <br />
          <br /> This allows the team to utilize their time more efficiently and focus on productive work”
        </p>

        <p class="trust_company" style="font-size: 20px;">- CEO, Capital Alliance Investments Limited -</p>

      </div>
    </div>

  </div>

  <!-- <ngx-gallery [options]="galleryOptions" [images]="galleryImages"></ngx-gallery> -->





  <ngx-slick-carousel class="carousel" #slickModal="slick-carousel" [config]="slideConfig">
    <div ngxSlickItem *ngFor="let slide of slides" class="slide">
      <img src="{{ slide.img }}" alt="" width="100%">
    </div>
  </ngx-slick-carousel>



</div>