<div class="landing-page-wrapper">
  <div class="top-blue-line"></div>
  <div class="row">
    <div class="col-5">
      <div class="header-logo">
        <img [routerLink]="['/']" src="../../../../assets/images/header/logo.svg">
      </div>
      <div class="hero-text">
        <div class="bold-title">
          <span>ZEPTO</span> for <br>Healthcare Services
        </div>
        <div class="hero-description">
          <div class="hero-btns">
            <button class="check-it-now" (click)="scroll()">CHECK IT OUT</button>
            <img class="down-arrow bounce" src="../../../../assets/images/landing-pages/health-down-arrow.svg">
          </div>
        </div>
      </div>
    </div>
    <div class="col-7">
      <div class="hero-img">
        <img src="../../../../assets/images/landing-pages/health-hero-image.png">
      </div>
    </div>
  </div>
  <br>
  <br>
  <br id="scroll-to">
  <br>
  <div class="section-wrapper">
    <div class="section-container" id="section-container-1">
      <div class="section-paragraph">
        <div class="row">
          <div class="col-6">
            <div class="paragraph1-img">
              <img src="../../../../assets/images/landing-pages/health-maintenance-image.png">
            </div>
          </div>
          <div class="col-6">
            <div class="section-title-small">Use Case 01</div>
            <div class="section-main-title">Predictive Maintenance</div>
            <div class="pargraph1-text">
              <p>
                The level of predictability of any crisis can drastically help to
                contain the situation. In the case of medical devices,
                healthcare organizations may incur a lot directly as well as
                indirectly due to unplanned machine break-downs. Learn how
                <span [routerLink]="['/']">ZEPTO</span> can help to solve this problem
              </p>
              <div class="know-more" id="know-more1">
                <br><br> <br><br>
                <button class="btn-know-more" (click)="expandSection(1)">Learn more</button>
              </div>
            </div>
          </div>
          <div class="col-12">
            <br>
            <br>
            <br>
            <video id="healthcare_video" preload controls>
              <source src="../../../../../assets/videos/zepto_for_healthcare.mp4" type='video/mp4'>
            </video>

            <div class="risk">
              Risk is not the malfunction of a medical device but not knowing when it will occur...
            </div>
          </div>
          <div class="col-6">
            <div class="p-ex-txt-con-1">
              <div class="title-1">
                Problem
              </div>
              <div class="desc-1">
                The level of predictability of any crisis can drastically help to contain the situation. In the case of
                medical devices, healthcare organizations incur a lot directly as well as indirectly due to unplanned
                machine break-downs
              </div>
              <div class="tbl-1">
                <div class="row">
                  <div class="col-4">
                    <div class="precentage">
                      48%
                    </div>
                  </div>
                  <div class="col-8">
                    <div class="precentage-desc">
                      Of the total budget is spent on the maintenance of medical devices
                    </div>
                  </div>
                  <div class="col-4">
                    <div class="precentage">
                      25%
                    </div>
                  </div>
                  <div class="col-8">
                    <div class="precentage-desc">
                      Of the Capex on devices is spent on backup devices
                    </div>
                  </div>
                  <div class="col-4">
                    <div class="precentage">
                      43%
                    </div>
                  </div>
                  <div class="col-8">
                    <div class="precentage-desc">
                      Of repairs/interventions could have been avoided if there is a robust preventive maintenance
                      schedule in place
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-6">
            <div class="p-ex-img-1">
              <img src="../../../../assets/images/landing-pages/health-problem-image.png">
            </div>
          </div>
          <div class="col-6">
            <div class="p-ex-img-2">
              <img src="../../../../assets/images/landing-pages/health-solution-image.png">
            </div>
          </div>
          <div class="col-6">
            <div class="p-ex-txt-con-2">
              <div class="title-2">
                Solution using ZEPTO
              </div>
              <div class="desc-2">
                With ZEPTO, the maintenance department can create a robust maintenance schedule which takes the past
                usage and machine break-down data from the device logs into consideration and uses advanced machine
                learning algorithms. This way, the possibility of a device break-down can be sensed a week before and
                dynamically schedule a preventive maintenance program
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <br>
  <br>
  <br>
  <div class="section-wrapper">
    <div class="section-container" id="section-container-2">
      <div class="section-paragraph">
        <div class="row">
          <div class="col-6">
            <div class="section-title-small txt-left">Use Case 02</div>
            <div class="section-main-title txt-left">Predicting Patient Inflow</div>
            <div class="pargraph1-text txt-left">
              <p>
                Staffing accounts for one of the main components in a hospital’s cost structure. Too little staff can
                significantly impact quality of care and patient experience; too much staff can jeopardize a hospital's
                financial viability. Learn more as to how <span [routerLink]="['/']">ZEPTO</span>’s predictive analytics
                feature can help solve this
                problem
              </p>
              <div class="know-more fl-left" id="know-more2">
                <br><br> <br><br>
                <button class="btn-know-more" (click)="expandSection(2)">Learn more</button>
              </div>
            </div>
          </div>
          <div class="col-6">
            <div class="paragraph1-img">
              <img src="../../../../assets/images/landing-pages/health-patient-inflow-image.png">
            </div>
          </div>
          <div class="col-12">
            <br>
            <br>
            <br>
            <div class="risk">
              Avoid the risk of patients getting re-admitted within 30 days...
            </div>
          </div>
          <div class="col-6">
            <div class="p-ex-txt-con-1">
              <div class="title-1">
                Problem
              </div>
              <div class="desc-1">
                Hospital readmission has become a critical metric of quality and cost of health-care. This has a
                negative impact on both financial and reputational aspects to the company
              </div>
              <div class="tbl-1">
                <div class="row">
                  <div class="col-4">
                    <div class="precentage">
                      20%
                    </div>
                  </div>
                  <div class="col-8">
                    <div class="precentage-desc">
                      of patients are being readmitted within 30 days of discharge in US hospitals on maximum
                    </div>
                  </div>
                  <div class="col-4">
                    <div class="precentage">
                      <span class="dol">$</span>17<span class="bil">Bn</span>
                    </div>
                  </div>
                  <div class="col-8">
                    <div class="precentage-desc">
                      Of financial implication for the hospitals annually
                    </div>
                  </div>
                  <div class="col-4">
                    <div class="precentage">
                      2/3
                    </div>
                  </div>
                  <div class="col-8">
                    <div class="precentage-desc">
                      Of the re-admission can be avoided by using predictive analytics
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-6">
            <div class="p-ex-img-1">
              <img src="../../../../assets/images/landing-pages/health-problem-image.png">
            </div>
          </div>
          <div class="col-6">
            <div class="p-ex-img-2">
              <img src="../../../../assets/images/landing-pages/health-solution-image.png">
            </div>
          </div>
          <div class="col-6">
            <div class="p-ex-txt-con-2 top-m">
              <div class="title-2">
                Solution using ZEPTO
              </div>
              <div class="desc-2">
                Studies say, apart from the historical data of patient’s medical records, treatments and vital
                information before the discharge, patient’s bio information, socio-economic data, behavioral data,
                weather and air pollution conditions are also highly influential for a patient’s readmission.
              </div>
              <div class="desc-2">
                ZEPTO’s predictive analytics feature can issue warnings to Hospitals with possible scientific reasoning
                when a patient’s risk factors indicate a high likelihood for readmission within a 30-day window. ZEPTO’s
                dynamic data merging feature will allow blending of multiple data sources into a single dataset for
                better prediction.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <br>
  <br>
  <br>
  <div class="section-wrapper">
    <div class="section-container" id="section-container-3">
      <div class="section-paragraph">
        <div class="row">
          <div class="col-6">
            <div class="paragraph1-img">
              <img src="../../../../assets/images/landing-pages/health-hospital.png">
            </div>
          </div>
          <div class="col-6">
            <div class="section-title-small">Use Case 03</div>
            <div class="section-main-title">Predicting Likelihood of Readmission of patients
            </div>
            <div class="pargraph1-text">
              <p>
                Hospital readmission has become a critical metric of quality and cost of healthcare. This has a negative
                financial impact as well as a dent to the reputation of the hospital. Learn more as to how <span
                  [routerLink]="['/']">ZEPTO</span> can
                help solve this problem
              </p>
              <div class="know-more" id="know-more3">
                <br><br> <br><br>
                <button class="btn-know-more" (click)="expandSection(3)">Learn more</button>
              </div>
            </div>
          </div>
          <div class="col-12">
            <br>
            <br>
            <br>
            <div class="risk">
              Optimized staff allocation with robust patient inflow predictions...
            </div>
          </div>
          <div class="col-6">
            <div class="p-ex-txt-con-1">
              <div class="title-1">
                Problem
              </div>
              <div class="desc-1">
                Staffing accounts for is a main component in the hospital's cost structure. Too little staff can
                significantly impact quality of care and patient experience; too much staff can jeopardize a hospital's
                financial viability
              </div>
              <div class="tbl-1">
                <div class="row">
                  <div class="col-4">
                    <div class="precentage">
                      50%
                    </div>
                  </div>
                  <div class="col-8">
                    <div class="precentage-desc">
                      Accounts for the staff cost out of total operating cost of a hospital on average
                    </div>
                  </div>
                  <div class="col-4">
                    <div class="precentage">
                      45%
                    </div>
                  </div>
                  <div class="col-8">
                    <div class="precentage-desc">
                      Of the admin workforce dedicate their time on staff and resource allocation
                    </div>
                  </div>
                  <div class="col-4">
                    <div class="precentage">
                      21%
                    </div>
                  </div>
                  <div class="col-8">
                    <div class="precentage-desc">
                      Of total time of medical professionals go un-billable
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-6">
            <div class="p-ex-img-1">
              <img src="../../../../assets/images/landing-pages/health-problem-image.png">
            </div>
          </div>
          <div class="col-6">
            <div class="p-ex-img-2">
              <img src="../../../../assets/images/landing-pages/health-solution-image.png">
            </div>
          </div>
          <div class="col-6">
            <div class="p-ex-txt-con-2 top-m">
              <div class="title-2">
                Solution using ZEPTO
              </div>
              <div class="desc-2">
                Using ZEPTO’s predictive analytics feature in combination with external sources of data like weather
                forecasts, air quality index, seasonal changes, traffic prone areas and events occurring within a
                specified range to the hospital we can determine patient inflow at a granular level on a daily basis.
              </div>
              <div class="desc-2">
                ZEPTO’s Predictive Analytics model is based on both global and local factors, such as seasonality,
                holidays, location-based health issues and one-time occurrences, such as large conventions or major
                sporting events. All this data, when combined with ZEPTO’s sophisticated algorithms, allows the
                scheduling solution to identify the expected patient demand for a specific hospital, within very
                specific time windows
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <br>
  <br>
  <br>
  <hr>
  <br>
  <br>
  <br>
  <div class="section-wrapper no-shadow">
    <div class="row">
      <div class="col-6">
        <div class="logo">
          <img [routerLink]="['/']" src="../../../../assets/images/header/logo.svg">
          <div class="desc">
            Your personal Data Scientist
          </div>
        </div>
      </div>
      <div class="col-6">
        <div class="contact-info">
          <img src="../../../../assets/images/landing-pages/health-contact-person.png">
          <div class="know-more">
            Want to know more?
          </div>
          <div class="know-more call">
            Call us for a Demo
          </div>
          <div class="tp">
            +1 (617) 642-9426
          </div>
        </div>
      </div>
    </div>
    <div class="footer">
      <img [routerLink]="['/']" src="../../../../assets/images/landing-pages/logo-white.svg">
    </div>
  </div>
</div>