export const ApiEndpont = {


    // apiEndpoint: 'http://www.v3.zepto.io/', // This is test end-point
    apiEndpoint: 'http://localhost:3000/', // This is production end-point


    signIn: {
        validateEmail: 'api/v3/users/email/validate',
        validatePassword: 'api/v3/users/password/validate',
        validateToken: 'api/v3/users/token',
        sendPwdResetLink: 'api/v3/users/password/reset',
        verifyResetToken: ''
    },
    password: {
        validatePasswordCode: 'api/v3/users/pwtoken',
        userPasswordUpdate: 'api/v3/users/password/update',
        resendPasswordResetLink: 'api/v3/users/password/reset'
    },
    signOut: {
        logOut: 'api/logout/user',
    },
    signUp: {
        registerUser: 'api/v3/users',
        verifyEmail: 'api/v3/users/verify',
        resendVerificationCode: 'api/v3/users/verify-code/resend',
        validateAuthToken: 'auth/api/validate/user/signUpToken'
    },
    userInfo: {
        packegeDetails: 'auth/api/user/info',
        getUserInfo: 'api/get/user/info',
    },
    paypal: {
        getPaypalRedirectUrl: 'api/v3/payment/paypal/plan/create',
        validateCode: 'auth/api/validate/user/signUpToken',
        billSuccessValidate: 'api/v3/payment/paypal/plan/execute'
    },
    zeptoPay: {
        validatePayCode: 'auth/api/execute/zepto/pay/code'
    },
    contactUs: {
        contact: 'auth/api/send/corporation_plan_contact_us_message'
    },
    blog: {
        url: 'https://www.zepto.io/blog/'
    },
    auth: {
        validateToken: 'api/v3/users/token'
    }
}