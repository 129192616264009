import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { MetaTagService } from '../../../../core/services/meta-tag-service/meta-tag.service';
import { WebService } from '../../../../core/services/web-service/web.service';
import { CookieService } from 'ngx-cookie';
import { ApiEndpont } from '../../../../core/api-endpoints/api-endpoint';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  providers: [MetaTagService, WebService, CookieService]
})
export class HomeComponent implements OnInit {

  // 0 for no option, 1 gor goto app, 2 for get started
  public goToApp: number = 0;
  private endPoint = ApiEndpont;


  constructor(
    private titleService: Title,
    private meta: MetaTagService,
    private webservice: WebService,
    private cookieService: CookieService
  ) { }

  ngOnInit() {
    this.validateToken();

    this.titleService.setTitle('Zepto - AI powered Data Analytics Tool for Everyone');
    // ============== add meta tags ================
    this.meta.addTags(
      {
        title: "Zepto - AI powered Data Analytics Tool for Everyone",
        description: "World's Simplest AI Powered Cloud based Data Analytics Tool. Get actionable insights from your data in the form of Charts and Stories with a simple tool powered by Artificial Intelligence.",
        image: 'https://www.zepto.io/assets/images/shared/og-v3.png',
      }
    );
  }



  validateToken() {
    const tokenPayload = {
      token: this.cookieService.get("AUTH_TOKEN")
    }
    return this.webservice.processPost(this.endPoint.auth.validateToken, tokenPayload)
      .pipe(map(
        (response) => {
          if (response['status'] == "valid") {
            // enable go to app
            this.goToApp = 1;
          } else {
            // enable get started
            this.goToApp = 2;
          }
        }
      ))
      .subscribe(
        (data) => { },
        (error) => {
          // enable get started
          this.goToApp = 2;
        }
      );
  }

}
