import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Validators, UntypedFormBuilder, UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import * as jQuery from 'jquery';
import { ActivatedRoute } from '@angular/router';
import { WebService } from '../../../../core/services/web-service/web.service';
import { ApiEndpont } from '../../../../core/api-endpoints/api-endpoint';
import { CookieService } from 'ngx-cookie';
import { JwtHelperService } from '@auth0/angular-jwt';
import { map } from 'rxjs/operators';

declare var jQuery: any;
declare var $: any;
const helper = new JwtHelperService();

@Component({
  selector: 'reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss'],
  providers: [WebService, CookieService]
})
export class ResetPasswordComponent implements OnInit {
  public showPassword: boolean;
  resetForm: UntypedFormGroup;
  public resedLinkModalText = {
    title: "Resend a link",
    description: "We will resend a password reset link to <span>  </span>",
    buttonTitle: "Resend a reset link",
    status: 1
  }
  public successfullySentLink: boolean;
  private endPoint = ApiEndpont;
  public showResetPwdForm: number = 0;
  private token: string;
  public showPasswordResetSuccess: boolean;
  public resetEmail: string;
  public modalBtnLoading: boolean;
  public loading: boolean;
  public showPasswordLengthCorrectTick: boolean;
  @ViewChild('resendLinkModal', { static: true }) resendLinkModal: ElementRef;


  /**
 * email error showing statuses
 * 0 is default
 * 1 is for empty email address
 * 2 is for incorrect email format
 * 3 is for not registered email
 */
  public resetPwdStatus: number = 0;


  constructor(
    private fb: UntypedFormBuilder,
    private activeRoute: ActivatedRoute,
    private webservice: WebService,
    private cookieService: CookieService,
  ) { }

  ngOnInit() {

    this.resetForm = this.fb.group({
      resetInput: new UntypedFormControl('', Validators.compose([Validators.required]))
    });

    this.activeRoute.queryParams.subscribe(params => {
      this.validatePwdResetToken(params.token);
      this.token = params.token;
    });
  }


  validatePwdResetToken(token) {
    const resetPwdVerifyPayload = {
      pwtoken: token
    }

    return this.webservice.processPost(this.endPoint.password.validatePasswordCode, resetPwdVerifyPayload)
      .pipe(map(
        (response) => {
          if (response['status'] === "success") {
            this.showResetPwdForm = 1;
          } else {
            this.showResetPwdForm = 2;
          }
        }
      ))
      .subscribe(
        (data) => { },
        (error) => {
          if (error.error.error === "NotFoundError") {
            this.showResetPwdForm = 2;
          } else if (error.error.error === "ValidationError") {
            this.showResetPwdForm = 2;
          } else if (error.error.error === "PwtokenValidationError") {
            this.showResetPwdForm = 2;
          } else if (error.error.error === "PwtokenExpiredError") {
            this.resetEmail = error.error.email;
            this.showResetPwdForm = 3;
          }
        }
      );

  }

  passwordMask() {
    this.showPassword = !this.showPassword;

    var x = document.getElementById("password-field");
    if (x['type'] === "password") {
      x['type'] = "text";
    } else {
      x['type'] = "password";
    }
  }



  resetPassword(resetPassword) {
    this.showPasswordResetSuccess = false;

    const pwdUpdatePayload = {
      pwtoken: this.token,
      password: resetPassword.controls.resetInput.value
    }

    if (resetPassword.status === "VALID") {
      this.resetPwdStatus = 0;
      this.loading = true;


      return this.webservice.processPost(this.endPoint.password.userPasswordUpdate, pwdUpdatePayload)
        .pipe(map(
          (response) => {

            if (response['status'] === "success") {
              this.cookieService.put("AUTH_TOKEN", response['token']);

              this.resetEmail = helper.decodeToken(response['token']).email;
              this.showPasswordResetSuccess = true;
              this.loading = false;
            }
          }
        ))
        .subscribe(
          (data) => { },
          (error) => {
            this.loading = false;
            if (error.error.error === "NotFoundError") {

            }
          }
        );



    } else {
      if (resetPassword.controls.resetInput.errors.required != undefined) {
        this.resetPwdStatus = 1;
      } else if (resetPassword.controls.resetInput.errors.minlength != null) {
        this.resetPwdStatus = 2;
      }
    }
  }

  clearPasswordField() {
    this.resetPwdStatus = 1;
    this.resetForm.get("resetInput").setValue("");
  }

  openResetLinkSendModal() {
    jQuery(this.resendLinkModal.nativeElement).modal({ backdrop: 'static', keyboard: false }, 'show');

    this.successfullySentLink = false;
    this.resedLinkModalText = {
      title: "Resend a link",
      description: "We will resend a password reset link to <span> " + this.resetEmail + " </span>",
      buttonTitle: "Resend a reset link",
      status: 1
    }
  }

  sendResetLink() {


    const resendLinkPayload = {
      email: this.resetEmail.toLowerCase(),
    }

    this.loading = true;


    return this.webservice.processPost(this.endPoint.password.resendPasswordResetLink, resendLinkPayload)
      .pipe(map(
        (response) => {

          if (response['status'] === "success") {
            this.loading = false;

            this.successfullySentLink = true;
            setTimeout(() => {
              this.resedLinkModalText = {
                title: "Successfully Sent!",
                description: "We have resent a password reset link to " + this.resetEmail + " Please check your email.",
                buttonTitle: "Ok",
                status: 1
              }
            }, 200);

          }
        }
      ))
      .subscribe(
        (data) => { },
        (error) => {
          this.loading = false;
          if (error.error.error === "ValidationError") {

          }
          else if (error.error.error === "NotFoundError") {

          }
        }
      );



  }


  goToApp() {
    window.location.href = "/app/datalocker"
  }
  goToHome() {
    window.location.href = "/"
  }

  checkPasswordLenght() {
    if (this.resetForm.controls.resetInput.value.length >= 6) {
      this.showPasswordLengthCorrectTick = true;
    } else {
      this.showPasswordLengthCorrectTick = false;
    }
  }


}
