import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { Validators, UntypedFormBuilder, UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { WebService } from '../../../../core/services/web-service/web.service';
import { CookieService } from 'ngx-cookie';
import { ApiEndpont } from '../../../../core/api-endpoints/api-endpoint';
import { map } from 'rxjs/operators';

import { JwtHelperService } from '@auth0/angular-jwt';
import * as jQuery from 'jquery';
import { ToastrManager, Toastr } from 'ng6-toastr-notifications';


declare var jQuery: any;
declare var $: any;
const helper = new JwtHelperService();
declare let ga:Function;

@Component({
  selector: 'sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.scss'],
  providers: [WebService, CookieService]
})
export class SignUpComponent implements OnInit {
  public registrationSuccess: boolean;
  public verifySuccess: boolean;
  public showPassword: boolean;
  public showPasswordLengthCorrectTick: boolean;
  signUpForm: UntypedFormGroup;
  emailVerifyForm: UntypedFormGroup;
  private endPoint = ApiEndpont;
  public loading: boolean;
  public registerFormError: number;
  public userEmail: string;
  public varificationCodeValidation: number;

  @ViewChild('successModal', { static: true }) successModal: ElementRef;


  constructor(
    private fb: UntypedFormBuilder,
    private webservice: WebService,
    private cookieService: CookieService,
    public toastr: ToastrManager
  ) { }

  ngOnInit() {
    // this.registrationSuccess = true;
    // this.verifySuccess = true;


    if (this.cookieService.get("AUTH_TOKEN") != undefined) {
      this.userEmail = helper.decodeToken(this.cookieService.get("AUTH_TOKEN")).email

      if (helper.decodeToken(this.cookieService.get("AUTH_TOKEN")).signUpStatus === "NEW_USER") {
        this.registrationSuccess = true;
      }
    }

    this.signUpForm = this.fb.group({
      name: new UntypedFormControl('', Validators.compose([Validators.required])),
      email: new UntypedFormControl('', Validators.compose([Validators.required])),
      password: new UntypedFormControl('', Validators.compose([Validators.required])),
      recaptcha: new UntypedFormControl('', Validators.compose([Validators.required]))
    });


    this.emailVerifyForm = this.fb.group({
      digit1: new UntypedFormControl('', Validators.compose([Validators.required])),
      digit2: new UntypedFormControl('', Validators.compose([Validators.required])),
      digit3: new UntypedFormControl('', Validators.compose([Validators.required])),
      digit4: new UntypedFormControl('', Validators.compose([Validators.required]))
    });

  }

  doRegistration(signUpForm) {
    if (signUpForm.status == "VALID") {

      var checkNameWhiteSpace = signUpForm.value.name.trim();
      this.signUpForm.controls['name'].setValue(checkNameWhiteSpace);
      if (checkNameWhiteSpace.length == 0) {
        this.registerFormError = 1;
        return;
      }

      this.loading = true;
      this.registerFormError = 0;

      const signUpPayload = {
        firstName: signUpForm.value.name,
        email: signUpForm.value.email.toLowerCase(),
        password: signUpForm.value.password
      }

      return this.webservice.processPost(this.endPoint.signUp.registerUser, signUpPayload)
        .pipe(map(
          (response) => {
            if (response['status'] === "success") {
              this.cookieService.put("AUTH_TOKEN", response['token'])
              this.registrationSuccess = true;

              ga('send', 'event', 'NewUser_Add_Evt', 'New User', 'New User Added');
              
              document.body.style.overflowY = "hidden";
              setTimeout(() => {
                document.body.style.overflowY = "scroll";
              }, 500);
              this.userEmail = helper.decodeToken(response['token']).email
              jQuery(this.successModal.nativeElement).modal({ backdrop: 'static', keyboard: false }, 'show');
            }
            this.loading = false;
          }
        ))
        .subscribe(
          (data) => { },
          (error) => {
            this.loading = false;
            if (error.error.error === "UserAlreadyExist") {
              this.registerFormError = 6;
            }
          }
        );
    } else {
      if (signUpForm.controls.name.errors != null) {
        if (signUpForm.controls.name.errors.required == true) {
          this.registerFormError = 1;
        }
      }
      else if (signUpForm.controls.email.errors != null) {
        if (signUpForm.controls.email.errors.required == true) {
          this.registerFormError = 2;
        } else if (signUpForm.controls.email.errors.pattern.requiredPattern != undefined) {
          this.registerFormError = 3;
        }
      }
      else if (signUpForm.controls.password.errors != null) {
        if (signUpForm.controls.password.errors.required == true) {
          this.registerFormError = 4;
        } else if (signUpForm.controls.password.errors.minlength != undefined) {
          this.registerFormError = 5;
        }
      }
      else if (signUpForm.controls.recaptcha.errors != null) {
        if (signUpForm.controls.recaptcha.errors.required == true) {
          this.registerFormError = 7;
        }
      }
    }
  }

  doVerification(verificationForm) {

    if (verificationForm.status === "VALID") {

      if (this.cookieService.get("AUTH_TOKEN") != undefined) {
        this.varificationCodeValidation = 0;


        this.loading = true;
        let verificationCode = this.emailVerifyForm.get('digit1').value + this.emailVerifyForm.get('digit2').value + this.emailVerifyForm.get('digit3').value + this.emailVerifyForm.get('digit4').value;

        const verificationPayload = {
          email: helper.decodeToken(this.cookieService.get("AUTH_TOKEN")).email.toLowerCase(),
          verifyCode: verificationCode
        }

        return this.webservice.processPost(this.endPoint.signUp.verifyEmail, verificationPayload)
          .pipe(map(
            (response) => {
              if (response['status'] === "valid") {
                this.verifySuccess = true;
                this.cookieService.put("AUTH_TOKEN", response['token']);

                ga('send', 'event', 'Email_Verify_Evt', 'Verified email', 'NewUser email Verified')
              }
              this.loading = false;
            }
          ))
          .subscribe(
            (data) => { },
            (error) => {
              this.loading = false;
              if (error.error.error === "VerifyCodeValidationError") {
                this.varificationCodeValidation = 2;
              } else if (error.error.error === "VerifyCodeExpired") {
                this.varificationCodeValidation = 3;
              } else if (error.error.error === "UserAlreadyVerify") {
                this.varificationCodeValidation = 4;
              }
            }
          );

      } else {
        this.showError("Something went wrong!")
      }

    } else {
      this.varificationCodeValidation = 1;
    }

  }

  passwordMask() {
    this.showPassword = !this.showPassword;

    var x = document.getElementById("password-field");
    if (x['type'] === "password") {
      x['type'] = "text";
    } else {
      x['type'] = "password";
    }
  }

  checkPasswordLenght() {
    if (this.signUpForm.controls.password.value.length >= 6) {
      this.showPasswordLengthCorrectTick = true;
      this.registerFormError = 0;
    } else {
      this.showPasswordLengthCorrectTick = false;
    }
  }

  digitBoxSetting(id, event) {
    if (id == 1) {
      document.getElementById("codeBox2").focus();
    }
    else if (id == 2) {
      document.getElementById("codeBox3").focus();
    }
    else if (id == 3) {
      document.getElementById("codeBox4").focus();
    }


  }

  digitBoxPaste(event: ClipboardEvent) {
    let clipboardData = event.clipboardData || window['clipboardData'];
    let beforePasteText = clipboardData.getData('text');
    let pastedText = beforePasteText.replace(/[^0-9\.]+/g, " ")


    this.emailVerifyForm.setValue({
      digit1: pastedText[0],
      digit2: pastedText[1],
      digit3: pastedText[2],
      digit4: pastedText[3],
    });
  }





  onKeyUpEvent(index, event) {
    const eventCode = event.which || event.keyCode;
    if (index !== 4) {
      if (eventCode !== 8) {
        if (index === 1) {
          this.emailVerifyForm.controls['digit1'].setValue(event.target.value);
          setTimeout(() => {
            document.getElementById('codeBox2').focus();
          }, 10);
        }
        else if (index === 2) {
          this.emailVerifyForm.controls['digit2'].setValue(event.target.value);
          setTimeout(() => {
            document.getElementById('codeBox3').focus();
          }, 10);
        }
        else if (index === 3) {
          this.emailVerifyForm.controls['digit3'].setValue(event.target.value);
          setTimeout(() => {
            document.getElementById('codeBox4').focus();
          }, 10);
        }
      }
    }






    if (eventCode === 8) {
      if (index === 4) {
        this.emailVerifyForm.controls['digit4'].setValue("");
        setTimeout(() => {
          document.getElementById('codeBox3').focus();
        }, 10);
      } else if (index === 3) {
        this.emailVerifyForm.controls['digit3'].setValue("");
        setTimeout(() => {
          document.getElementById('codeBox2').focus();
        }, 10);
      } else if (index === 2) {
        this.emailVerifyForm.controls['digit2'].setValue("");
        setTimeout(() => {
          document.getElementById('codeBox1').focus();
        }, 10);
      } else if (index === 1) {
        this.emailVerifyForm.controls['digit3'].setValue("");
      }

    }
  }


  getCodeBoxElement(index) {
    return document.getElementById('codeBox' + index);
  }




  resendVerificationCode() {
    if (this.cookieService.get("AUTH_TOKEN") != undefined) {

      const resendPaylod = {
        email: helper.decodeToken(this.cookieService.get("AUTH_TOKEN")).email.toLowerCase()
      }


      return this.webservice.processPost(this.endPoint.signUp.resendVerificationCode, resendPaylod)
        .pipe(map(
          (response) => {

            if (response['status'] === "success") {
              this.userEmail = response['email'];
              jQuery(this.successModal.nativeElement).modal({ backdrop: 'static', keyboard: false }, 'show');
            }


          }
        ))
        .subscribe(
          (data) => { },
          (error) => {

          }
        );

    } else {
      this.showError("Something went wrong!");
    }

  }


  clearVerificationForm() {
    this.emailVerifyForm.reset();
    this.varificationCodeValidation = 0;
  }

  clearSignupFormFields(id) {
    this.registerFormError = 0;
    if (id == 1) {
      this.signUpForm.get("email").setValue("");
    } else if (id == 2) {
      this.signUpForm.get("password").setValue("");
    }
  }


  showError(message) {
    this.toastr.errorToastr(message, 'Error!', { position: 'bottom-full-width', animate: 'slideFromBottom' });
  }
}
