import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators, UntypedFormControl } from '@angular/forms';
import * as Sentry from '@sentry/browser';

@Component({
  selector: 'app-pre-release',
  templateUrl: './pre-release.component.html',
  styleUrls: ['./pre-release.component.scss']
})
export class PreReleaseComponent implements OnInit {

  public countryCode = new Array<any>();
  public selectedCode: string = "1";
  registerForm: UntypedFormGroup;
  submitted = false;
  formSubmitted = false;

  constructor(
    private formBuilder: UntypedFormBuilder
  ) { }

  ngOnInit() {
    this.registerForm = this.formBuilder.group({
      name: ['', Validators.required],
      email: new UntypedFormControl('', Validators.compose([
        Validators.required,
        Validators.pattern('[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$')
      ])),
      phone: [''],
      message: ['']
    });

    this.countryCode = [
      {
        "COUNTRY": "Afghanistan",
        "COUNTRY_CODE": "93"
      },
      {
        "COUNTRY": "Albania",
        "COUNTRY_CODE": "355"
      },
      {
        "COUNTRY": "Algeria",
        "COUNTRY_CODE": "213"
      },
      {
        "COUNTRY": "American Samoa",
        "COUNTRY_CODE": "1-684"
      },
      {
        "COUNTRY": "Andorra",
        "COUNTRY_CODE": "376"
      },
      {
        "COUNTRY": "Angola",
        "COUNTRY_CODE": "244"
      },
      {
        "COUNTRY": "Anguilla",
        "COUNTRY_CODE": "1-264"
      },
      {
        "COUNTRY": "Antarctica",
        "COUNTRY_CODE": "672"
      },
      {
        "COUNTRY": "Antigua and Barbuda",
        "COUNTRY_CODE": "1-268"
      },
      {
        "COUNTRY": "Argentina",
        "COUNTRY_CODE": "54"
      },
      {
        "COUNTRY": "Armenia",
        "COUNTRY_CODE": "374"
      },
      {
        "COUNTRY": "Aruba",
        "COUNTRY_CODE": "297"
      },
      {
        "COUNTRY": "Australia",
        "COUNTRY_CODE": "61"
      },
      {
        "COUNTRY": "Austria",
        "COUNTRY_CODE": "43"
      },
      {
        "COUNTRY": "Azerbaijan",
        "COUNTRY_CODE": "994"
      },
      {
        "COUNTRY": "Bahamas",
        "COUNTRY_CODE": "1-242"
      },
      {
        "COUNTRY": "Bahrain",
        "COUNTRY_CODE": "973"
      },
      {
        "COUNTRY": "Bangladesh",
        "COUNTRY_CODE": "880"
      },
      {
        "COUNTRY": "Barbados",
        "COUNTRY_CODE": "1-246"
      },
      {
        "COUNTRY": "Belarus",
        "COUNTRY_CODE": "375"
      },
      {
        "COUNTRY": "Belgium",
        "COUNTRY_CODE": "32"
      },
      {
        "COUNTRY": "Belize",
        "COUNTRY_CODE": "501"
      },
      {
        "COUNTRY": "Benin",
        "COUNTRY_CODE": "229"
      },
      {
        "COUNTRY": "Bermuda",
        "COUNTRY_CODE": "1-441"
      },
      {
        "COUNTRY": "Bhutan",
        "COUNTRY_CODE": "975"
      },
      {
        "COUNTRY": "Bolivia",
        "COUNTRY_CODE": "591"
      },
      {
        "COUNTRY": "Bosnia and Herzegovina",
        "COUNTRY_CODE": "387"
      },
      {
        "COUNTRY": "Botswana",
        "COUNTRY_CODE": "267"
      },
      {
        "COUNTRY": "Brazil",
        "COUNTRY_CODE": "55"
      },
      {
        "COUNTRY": "British Indian Ocean Territory",
        "COUNTRY_CODE": "246"
      },
      {
        "COUNTRY": "British Virgin Islands",
        "COUNTRY_CODE": "1-284"
      },
      {
        "COUNTRY": "Brunei",
        "COUNTRY_CODE": "673"
      },
      {
        "COUNTRY": "Bulgaria",
        "COUNTRY_CODE": "359"
      },
      {
        "COUNTRY": "Burkina Faso",
        "COUNTRY_CODE": "226"
      },
      {
        "COUNTRY": "Burundi",
        "COUNTRY_CODE": "257"
      },
      {
        "COUNTRY": "Cambodia",
        "COUNTRY_CODE": "855"
      },
      {
        "COUNTRY": "Cameroon",
        "COUNTRY_CODE": "237"
      },
      {
        "COUNTRY": "Canada",
        "COUNTRY_CODE": "1"
      },
      {
        "COUNTRY": "Cape Verde",
        "COUNTRY_CODE": "238"
      },
      {
        "COUNTRY": "Cayman Islands",
        "COUNTRY_CODE": "1-345"
      },
      {
        "COUNTRY": "Central African Republic",
        "COUNTRY_CODE": "236"
      },
      {
        "COUNTRY": "Chad",
        "COUNTRY_CODE": "235"
      },
      {
        "COUNTRY": "Chile",
        "COUNTRY_CODE": "56"
      },
      {
        "COUNTRY": "China",
        "COUNTRY_CODE": "86"
      },
      {
        "COUNTRY": "Christmas Island",
        "COUNTRY_CODE": "61"
      },
      {
        "COUNTRY": "Cocos Islands",
        "COUNTRY_CODE": "61"
      },
      {
        "COUNTRY": "Colombia",
        "COUNTRY_CODE": "57"
      },
      {
        "COUNTRY": "Comoros",
        "COUNTRY_CODE": "269"
      },
      {
        "COUNTRY": "Cook Islands",
        "COUNTRY_CODE": "682"
      },
      {
        "COUNTRY": "Costa Rica",
        "COUNTRY_CODE": "506"
      },
      {
        "COUNTRY": "Croatia",
        "COUNTRY_CODE": "385"
      },
      {
        "COUNTRY": "Cuba",
        "COUNTRY_CODE": "53"
      },
      {
        "COUNTRY": "Curacao",
        "COUNTRY_CODE": "599"
      },
      {
        "COUNTRY": "Cyprus",
        "COUNTRY_CODE": "357"
      },
      {
        "COUNTRY": "Czech Republic",
        "COUNTRY_CODE": "420"
      },
      {
        "COUNTRY": "Democratic Republic of the Congo",
        "COUNTRY_CODE": "243"
      },
      {
        "COUNTRY": "Denmark",
        "COUNTRY_CODE": "45"
      },
      {
        "COUNTRY": "Djibouti",
        "COUNTRY_CODE": "253"
      },
      {
        "COUNTRY": "Dominica",
        "COUNTRY_CODE": "1-767"
      },
      {
        "COUNTRY": "Dominican Republic",
        "COUNTRY_CODE": "1-809, 1-829, 1-849"
      },
      {
        "COUNTRY": "East Timor",
        "COUNTRY_CODE": "670"
      },
      {
        "COUNTRY": "Ecuador",
        "COUNTRY_CODE": "593"
      },
      {
        "COUNTRY": "Egypt",
        "COUNTRY_CODE": "20"
      },
      {
        "COUNTRY": "El Salvador",
        "COUNTRY_CODE": "503"
      },
      {
        "COUNTRY": "Equatorial Guinea",
        "COUNTRY_CODE": "240"
      },
      {
        "COUNTRY": "Eritrea",
        "COUNTRY_CODE": "291"
      },
      {
        "COUNTRY": "Estonia",
        "COUNTRY_CODE": "372"
      },
      {
        "COUNTRY": "Ethiopia",
        "COUNTRY_CODE": "251"
      },
      {
        "COUNTRY": "Falkland Islands",
        "COUNTRY_CODE": "500"
      },
      {
        "COUNTRY": "Faroe Islands",
        "COUNTRY_CODE": "298"
      },
      {
        "COUNTRY": "Fiji",
        "COUNTRY_CODE": "679"
      },
      {
        "COUNTRY": "Finland",
        "COUNTRY_CODE": "358"
      },
      {
        "COUNTRY": "France",
        "COUNTRY_CODE": "33"
      },
      {
        "COUNTRY": "French Polynesia",
        "COUNTRY_CODE": "689"
      },
      {
        "COUNTRY": "Gabon",
        "COUNTRY_CODE": "241"
      },
      {
        "COUNTRY": "Gambia",
        "COUNTRY_CODE": "220"
      },
      {
        "COUNTRY": "Georgia",
        "COUNTRY_CODE": "995"
      },
      {
        "COUNTRY": "Germany",
        "COUNTRY_CODE": "49"
      },
      {
        "COUNTRY": "Ghana",
        "COUNTRY_CODE": "233"
      },
      {
        "COUNTRY": "Gibraltar",
        "COUNTRY_CODE": "350"
      },
      {
        "COUNTRY": "Greece",
        "COUNTRY_CODE": "30"
      },
      {
        "COUNTRY": "Greenland",
        "COUNTRY_CODE": "299"
      },
      {
        "COUNTRY": "Grenada",
        "COUNTRY_CODE": "1-473"
      },
      {
        "COUNTRY": "Guam",
        "COUNTRY_CODE": "1-671"
      },
      {
        "COUNTRY": "Guatemala",
        "COUNTRY_CODE": "502"
      },
      {
        "COUNTRY": "Guernsey",
        "COUNTRY_CODE": "44-1481"
      },
      {
        "COUNTRY": "Guinea",
        "COUNTRY_CODE": "224"
      },
      {
        "COUNTRY": "Guinea-Bissau",
        "COUNTRY_CODE": "245"
      },
      {
        "COUNTRY": "Guyana",
        "COUNTRY_CODE": "592"
      },
      {
        "COUNTRY": "Haiti",
        "COUNTRY_CODE": "509"
      },
      {
        "COUNTRY": "Honduras",
        "COUNTRY_CODE": "504"
      },
      {
        "COUNTRY": "Hong Kong",
        "COUNTRY_CODE": "852"
      },
      {
        "COUNTRY": "Hungary",
        "COUNTRY_CODE": "36"
      },
      {
        "COUNTRY": "Iceland",
        "COUNTRY_CODE": "354"
      },
      {
        "COUNTRY": "India",
        "COUNTRY_CODE": "91"
      },
      {
        "COUNTRY": "Indonesia",
        "COUNTRY_CODE": "62"
      },
      {
        "COUNTRY": "Iran",
        "COUNTRY_CODE": "98"
      },
      {
        "COUNTRY": "Iraq",
        "COUNTRY_CODE": "964"
      },
      {
        "COUNTRY": "Ireland",
        "COUNTRY_CODE": "353"
      },
      {
        "COUNTRY": "Isle of Man",
        "COUNTRY_CODE": "44-1624"
      },
      {
        "COUNTRY": "Israel",
        "COUNTRY_CODE": "972"
      },
      {
        "COUNTRY": "Italy",
        "COUNTRY_CODE": "39"
      },
      {
        "COUNTRY": "Ivory Coast",
        "COUNTRY_CODE": "225"
      },
      {
        "COUNTRY": "Jamaica",
        "COUNTRY_CODE": "1-876"
      },
      {
        "COUNTRY": "Japan",
        "COUNTRY_CODE": "81"
      },
      {
        "COUNTRY": "Jersey",
        "COUNTRY_CODE": "44-1534"
      },
      {
        "COUNTRY": "Jordan",
        "COUNTRY_CODE": "962"
      },
      {
        "COUNTRY": "Kazakhstan",
        "COUNTRY_CODE": "7"
      },
      {
        "COUNTRY": "Kenya",
        "COUNTRY_CODE": "254"
      },
      {
        "COUNTRY": "Kiribati",
        "COUNTRY_CODE": "686"
      },
      {
        "COUNTRY": "Kosovo",
        "COUNTRY_CODE": "383"
      },
      {
        "COUNTRY": "Kuwait",
        "COUNTRY_CODE": "965"
      },
      {
        "COUNTRY": "Kyrgyzstan",
        "COUNTRY_CODE": "996"
      },
      {
        "COUNTRY": "Laos",
        "COUNTRY_CODE": "856"
      },
      {
        "COUNTRY": "Latvia",
        "COUNTRY_CODE": "371"
      },
      {
        "COUNTRY": "Lebanon",
        "COUNTRY_CODE": "961"
      },
      {
        "COUNTRY": "Lesotho",
        "COUNTRY_CODE": "266"
      },
      {
        "COUNTRY": "Liberia",
        "COUNTRY_CODE": "231"
      },
      {
        "COUNTRY": "Libya",
        "COUNTRY_CODE": "218"
      },
      {
        "COUNTRY": "Liechtenstein",
        "COUNTRY_CODE": "423"
      },
      {
        "COUNTRY": "Lithuania",
        "COUNTRY_CODE": "370"
      },
      {
        "COUNTRY": "Luxembourg",
        "COUNTRY_CODE": "352"
      },
      {
        "COUNTRY": "Macau",
        "COUNTRY_CODE": "853"
      },
      {
        "COUNTRY": "Macedonia",
        "COUNTRY_CODE": "389"
      },
      {
        "COUNTRY": "Madagascar",
        "COUNTRY_CODE": "261"
      },
      {
        "COUNTRY": "Malawi",
        "COUNTRY_CODE": "265"
      },
      {
        "COUNTRY": "Malaysia",
        "COUNTRY_CODE": "60"
      },
      {
        "COUNTRY": "Maldives",
        "COUNTRY_CODE": "960"
      },
      {
        "COUNTRY": "Mali",
        "COUNTRY_CODE": "223"
      },
      {
        "COUNTRY": "Malta",
        "COUNTRY_CODE": "356"
      },
      {
        "COUNTRY": "Marshall Islands",
        "COUNTRY_CODE": "692"
      },
      {
        "COUNTRY": "Mauritania",
        "COUNTRY_CODE": "222"
      },
      {
        "COUNTRY": "Mauritius",
        "COUNTRY_CODE": "230"
      },
      {
        "COUNTRY": "Mayotte",
        "COUNTRY_CODE": "262"
      },
      {
        "COUNTRY": "Mexico",
        "COUNTRY_CODE": "52"
      },
      {
        "COUNTRY": "Micronesia",
        "COUNTRY_CODE": "691"
      },
      {
        "COUNTRY": "Moldova",
        "COUNTRY_CODE": "373"
      },
      {
        "COUNTRY": "Monaco",
        "COUNTRY_CODE": "377"
      },
      {
        "COUNTRY": "Mongolia",
        "COUNTRY_CODE": "976"
      },
      {
        "COUNTRY": "Montenegro",
        "COUNTRY_CODE": "382"
      },
      {
        "COUNTRY": "Montserrat",
        "COUNTRY_CODE": "1-664"
      },
      {
        "COUNTRY": "Morocco",
        "COUNTRY_CODE": "212"
      },
      {
        "COUNTRY": "Mozambique",
        "COUNTRY_CODE": "258"
      },
      {
        "COUNTRY": "Myanmar",
        "COUNTRY_CODE": "95"
      },
      {
        "COUNTRY": "Namibia",
        "COUNTRY_CODE": "264"
      },
      {
        "COUNTRY": "Nauru",
        "COUNTRY_CODE": "674"
      },
      {
        "COUNTRY": "Nepal",
        "COUNTRY_CODE": "977"
      },
      {
        "COUNTRY": "Netherlands",
        "COUNTRY_CODE": "31"
      },
      {
        "COUNTRY": "Netherlands Antilles",
        "COUNTRY_CODE": "599"
      },
      {
        "COUNTRY": "New Caledonia",
        "COUNTRY_CODE": "687"
      },
      {
        "COUNTRY": "New Zealand",
        "COUNTRY_CODE": "64"
      },
      {
        "COUNTRY": "Nicaragua",
        "COUNTRY_CODE": "505"
      },
      {
        "COUNTRY": "Niger",
        "COUNTRY_CODE": "227"
      },
      {
        "COUNTRY": "Nigeria",
        "COUNTRY_CODE": "234"
      },
      {
        "COUNTRY": "Niue",
        "COUNTRY_CODE": "683"
      },
      {
        "COUNTRY": "North Korea",
        "COUNTRY_CODE": "850"
      },
      {
        "COUNTRY": "Northern Mariana Islands",
        "COUNTRY_CODE": "1-670"
      },
      {
        "COUNTRY": "Norway",
        "COUNTRY_CODE": "47"
      },
      {
        "COUNTRY": "Oman",
        "COUNTRY_CODE": "968"
      },
      {
        "COUNTRY": "Pakistan",
        "COUNTRY_CODE": "92"
      },
      {
        "COUNTRY": "Palau",
        "COUNTRY_CODE": "680"
      },
      {
        "COUNTRY": "Palestine",
        "COUNTRY_CODE": "970"
      },
      {
        "COUNTRY": "Panama",
        "COUNTRY_CODE": "507"
      },
      {
        "COUNTRY": "Papua New Guinea",
        "COUNTRY_CODE": "675"
      },
      {
        "COUNTRY": "Paraguay",
        "COUNTRY_CODE": "595"
      },
      {
        "COUNTRY": "Peru",
        "COUNTRY_CODE": "51"
      },
      {
        "COUNTRY": "Philippines",
        "COUNTRY_CODE": "63"
      },
      {
        "COUNTRY": "Pitcairn",
        "COUNTRY_CODE": "64"
      },
      {
        "COUNTRY": "Poland",
        "COUNTRY_CODE": "48"
      },
      {
        "COUNTRY": "Portugal",
        "COUNTRY_CODE": "351"
      },
      {
        "COUNTRY": "Puerto Rico",
        "COUNTRY_CODE": "1-787, 1-939"
      },
      {
        "COUNTRY": "Qatar",
        "COUNTRY_CODE": "974"
      },
      {
        "COUNTRY": "Republic of the Congo",
        "COUNTRY_CODE": "242"
      },
      {
        "COUNTRY": "Reunion",
        "COUNTRY_CODE": "262"
      },
      {
        "COUNTRY": "Romania",
        "COUNTRY_CODE": "40"
      },
      {
        "COUNTRY": "Russia",
        "COUNTRY_CODE": "7"
      },
      {
        "COUNTRY": "Rwanda",
        "COUNTRY_CODE": "250"
      },
      {
        "COUNTRY": "Saint Barthelemy",
        "COUNTRY_CODE": "590"
      },
      {
        "COUNTRY": "Saint Helena",
        "COUNTRY_CODE": "290"
      },
      {
        "COUNTRY": "Saint Kitts and Nevis",
        "COUNTRY_CODE": "1-869"
      },
      {
        "COUNTRY": "Saint Lucia",
        "COUNTRY_CODE": "1-758"
      },
      {
        "COUNTRY": "Saint Martin",
        "COUNTRY_CODE": "590"
      },
      {
        "COUNTRY": "Saint Pierre and Miquelon",
        "COUNTRY_CODE": "508"
      },
      {
        "COUNTRY": "Saint Vincent and the Grenadines",
        "COUNTRY_CODE": "1-784"
      },
      {
        "COUNTRY": "Samoa",
        "COUNTRY_CODE": "685"
      },
      {
        "COUNTRY": "San Marino",
        "COUNTRY_CODE": "378"
      },
      {
        "COUNTRY": "Sao Tome and Principe",
        "COUNTRY_CODE": "239"
      },
      {
        "COUNTRY": "Saudi Arabia",
        "COUNTRY_CODE": "966"
      },
      {
        "COUNTRY": "Senegal",
        "COUNTRY_CODE": "221"
      },
      {
        "COUNTRY": "Serbia",
        "COUNTRY_CODE": "381"
      },
      {
        "COUNTRY": "Seychelles",
        "COUNTRY_CODE": "248"
      },
      {
        "COUNTRY": "Sierra Leone",
        "COUNTRY_CODE": "232"
      },
      {
        "COUNTRY": "Singapore",
        "COUNTRY_CODE": "65"
      },
      {
        "COUNTRY": "Sint Maarten",
        "COUNTRY_CODE": "1-721"
      },
      {
        "COUNTRY": "Slovakia",
        "COUNTRY_CODE": "421"
      },
      {
        "COUNTRY": "Slovenia",
        "COUNTRY_CODE": "386"
      },
      {
        "COUNTRY": "Solomon Islands",
        "COUNTRY_CODE": "677"
      },
      {
        "COUNTRY": "Somalia",
        "COUNTRY_CODE": "252"
      },
      {
        "COUNTRY": "South Africa",
        "COUNTRY_CODE": "27"
      },
      {
        "COUNTRY": "South Korea",
        "COUNTRY_CODE": "82"
      },
      {
        "COUNTRY": "South Sudan",
        "COUNTRY_CODE": "211"
      },
      {
        "COUNTRY": "Spain",
        "COUNTRY_CODE": "34"
      },
      {
        "COUNTRY": "Sri Lanka",
        "COUNTRY_CODE": "94"
      },
      {
        "COUNTRY": "Sudan",
        "COUNTRY_CODE": "249"
      },
      {
        "COUNTRY": "Suriname",
        "COUNTRY_CODE": "597"
      },
      {
        "COUNTRY": "Svalbard and Jan Mayen",
        "COUNTRY_CODE": "47"
      },
      {
        "COUNTRY": "Swaziland",
        "COUNTRY_CODE": "268"
      },
      {
        "COUNTRY": "Sweden",
        "COUNTRY_CODE": "46"
      },
      {
        "COUNTRY": "Switzerland",
        "COUNTRY_CODE": "41"
      },
      {
        "COUNTRY": "Syria",
        "COUNTRY_CODE": "963"
      },
      {
        "COUNTRY": "Taiwan",
        "COUNTRY_CODE": "886"
      },
      {
        "COUNTRY": "Tajikistan",
        "COUNTRY_CODE": "992"
      },
      {
        "COUNTRY": "Tanzania",
        "COUNTRY_CODE": "255"
      },
      {
        "COUNTRY": "Thailand",
        "COUNTRY_CODE": "66"
      },
      {
        "COUNTRY": "Togo",
        "COUNTRY_CODE": "228"
      },
      {
        "COUNTRY": "Tokelau",
        "COUNTRY_CODE": "690"
      },
      {
        "COUNTRY": "Tonga",
        "COUNTRY_CODE": "676"
      },
      {
        "COUNTRY": "Trinidad and Tobago",
        "COUNTRY_CODE": "1-868"
      },
      {
        "COUNTRY": "Tunisia",
        "COUNTRY_CODE": "216"
      },
      {
        "COUNTRY": "Turkey",
        "COUNTRY_CODE": "90"
      },
      {
        "COUNTRY": "Turkmenistan",
        "COUNTRY_CODE": "993"
      },
      {
        "COUNTRY": "Turks and Caicos Islands",
        "COUNTRY_CODE": "1-649"
      },
      {
        "COUNTRY": "Tuvalu",
        "COUNTRY_CODE": "688"
      },
      {
        "COUNTRY": "U.S. Virgin Islands",
        "COUNTRY_CODE": "1-340"
      },
      {
        "COUNTRY": "Uganda",
        "COUNTRY_CODE": "256"
      },
      {
        "COUNTRY": "Ukraine",
        "COUNTRY_CODE": "380"
      },
      {
        "COUNTRY": "United Arab Emirates",
        "COUNTRY_CODE": "971"
      },
      {
        "COUNTRY": "United Kingdom",
        "COUNTRY_CODE": "44"
      },
      {
        "COUNTRY": "United States",
        "COUNTRY_CODE": "1"
      },
      {
        "COUNTRY": "Uruguay",
        "COUNTRY_CODE": "598"
      },
      {
        "COUNTRY": "Uzbekistan",
        "COUNTRY_CODE": "998"
      },
      {
        "COUNTRY": "Vanuatu",
        "COUNTRY_CODE": "678"
      },
      {
        "COUNTRY": "Vatican",
        "COUNTRY_CODE": "379"
      },
      {
        "COUNTRY": "Venezuela",
        "COUNTRY_CODE": "58"
      },
      {
        "COUNTRY": "Vietnam",
        "COUNTRY_CODE": "84"
      },
      {
        "COUNTRY": "Wallis and Futuna",
        "COUNTRY_CODE": "681"
      },
      {
        "COUNTRY": "Western Sahara",
        "COUNTRY_CODE": "212"
      },
      {
        "COUNTRY": "Yemen",
        "COUNTRY_CODE": "967"
      },
      {
        "COUNTRY": "Zambia",
        "COUNTRY_CODE": "260"
      },
      {
        "COUNTRY": "Zimbabwe",
        "COUNTRY_CODE": "263"
      }
    ];
  }

  setCountryCode(code) {
    this.selectedCode = code;
  }

  // convenience getter for easy access to form fields
  public get f() { return this.registerForm.controls; }

  onSubmit() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.registerForm.invalid) {
      return;
    }

    Sentry.captureEvent({
      message: this.registerForm.value.email,
      level: Sentry.Severity.Info,
      extra: {
        name: this.registerForm.value.name,
        email: this.registerForm.value.email,
        mobile: this.registerForm.value.phone != "" ? this.selectedCode + this.registerForm.value.phone : "-",
        message: this.registerForm.value.message,
        product: "insurance-claim-assistant-bot"
      }
    });


    this.formSubmitted = true;
  }
}
