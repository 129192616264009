import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Validators, UntypedFormBuilder, UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import * as jQuery from 'jquery';
import { WebService } from '../../../../core/services/web-service/web.service';
import { ApiEndpont } from '../../../../core/api-endpoints/api-endpoint';
import { CookieService } from 'ngx-cookie';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Router } from '@angular/router';
import { map } from 'rxjs/operators';


declare var jQuery: any;
declare var $: any;
const helper = new JwtHelperService();
declare let ga:Function;


@Component({
  selector: 'sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.scss'],
  providers: [WebService, CookieService]
})
export class SignInComponent implements OnInit {
  public emailSuccess: boolean;
  public showPassword: boolean;
  public reverseFlip: boolean;
  public loading: boolean;
  public modalBtnLoading: boolean;

  emailForm: UntypedFormGroup;
  passwordForm: UntypedFormGroup;


  /**
   * email error showing statuses
   * 0 is default
   * 1 is for empty email address
   * 2 is for incorrect email format
   * 3 is for not registered email
   */
  public emailErrorStatus: number = 0;
  public passwordErrorStatus: number = 0;
  public forgotPasswordModalText: any = {}
  public successfullySentForgotPwdLink: boolean;
  private endPoint = ApiEndpont;


  @ViewChild('fogotPasswordModal', { static: true }) fogotPasswordModal: ElementRef;

  constructor(
    private fb: UntypedFormBuilder,
    private webservice: WebService,
    private cookieService: CookieService,
    private router: Router
  ) { }

  ngOnInit() {

    this.emailForm = this.fb.group({
      emailInput: new UntypedFormControl('', Validators.compose([
        Validators.required,
        Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$')
      ]))
    });

    this.passwordForm = this.fb.group({
      passwordInput: new UntypedFormControl('', Validators.compose([
        Validators.required]))
    });
  }


  doEmailVerify(emailForm) {
    if (emailForm.status === "VALID") {
      this.loading = true;
      this.emailErrorStatus = 0;

      var x = document.getElementById("password-field")['type'] = "password";


      const emailPayload = {
        email: emailForm.value.emailInput.toLowerCase()
      }

      // call api to validate email
      return this.webservice.processPost(this.endPoint.signIn.validateEmail, emailPayload)
        .pipe(map(
          (response) => {
            if (response['status'] === "valid") {

              this.emailSuccess = true;
              this.reverseFlip = false;
              this.emailErrorStatus = 0;

              document.body.style.overflowY = "hidden";
              setTimeout(() => {
                document.body.style.overflowY = "scroll";
                this.loading = false;
              }, 500);

            }
          }
        ))
        .subscribe(
          (data) => { },
          (error) => {
            this.loading = false;
            if (error.error.error === "NotFoundError") {
              this.emailErrorStatus = 3;
            }
          }
        );





    } else {
      if (emailForm.controls.emailInput.errors.required != undefined) {
        this.emailErrorStatus = 1;
      } else if (emailForm.controls.emailInput.errors.pattern != null) {
        this.emailErrorStatus = 2;
      }
    }
  }




  doPaswordVerify(passwordForm) {
    if (passwordForm.status === "VALID") {
      this.passwordErrorStatus = 0;
      this.loading = true;

      const passwordPayload = {
        // email: emailForm.value.emailInput
        email: this.emailForm.value.emailInput.toLowerCase(),
        password: passwordForm.value.passwordInput,
        rememberme: true
      }


      return this.webservice.processPost(this.endPoint.signIn.validatePassword, passwordPayload)
        .pipe(map(
          async (response) => {
            if (response['status'] === "valid") {

              this.cookieService.put("AUTH_TOKEN", response['token']);
              this.passwordErrorStatus = 0;

                let userData = await this.webservice.processGet(this.endPoint.userInfo.getUserInfo).toPromise();
                this.cookieService.put("refNo", userData['userRefNo']);

                ga('send', 'event', 'User_login_Evt', 'User Login', 'User is logged in');

                setTimeout(() => {
                  this.loading = false;

                  if (helper.decodeToken(response['token']).signUpStatus === "NEW_USER") {
                    this.router.navigateByUrl('/signup');
                  
                  } else if (helper.decodeToken(response['token']).signUpStatus === "TRIAL_USER") {
                    window.location.href = "/app/datalocker";
                  } else if (helper.decodeToken(response['token']).signUpStatus === "TRIAL_EXPIRED") {
                    window.location.href = "/app/account/profile";
                  } else if (helper.decodeToken(response['token']).signUpStatus === "PAID_USER") {
                    window.location.href = "/app/datalocker";
                  } else if (helper.decodeToken(response['token']).signUpStatus === "BILLING_FAILED") {
                    window.location.href = "/app/account/profile";
                  } else if (helper.decodeToken(response['token']).signUpStatus === "VIEW_ONLY_DASH_BOARD") {
                    window.location.href = "/app/dashboard-view-only";
                  }

                }, 500);


            } else if (response['error'] === "PasswordValidationError") {
              this.passwordErrorStatus = 3;
              this.loading = false;
            }
          }
        ))
        .subscribe(
          (data) => { },
          (error) => {
            this.loading = false;
            if (error.error.error === "NotFoundError") {
              this.passwordErrorStatus = 3;
            } else if (error.error.error === "PasswordValidationError") {
              this.passwordErrorStatus = 3;
            }
          }
        );



    } else {
      if (passwordForm.controls.passwordInput.errors.required != undefined) {
        this.passwordErrorStatus = 1;
      }
    }
  }

  clearEmailField() {
    this.emailErrorStatus = 1;
    this.emailForm.get("emailInput").setValue("");
  }

  passwordMask() {
    this.showPassword = !this.showPassword;
    var x = document.getElementById("password-field");
    if (x['type'] === "password") {
      x['type'] = "text";
    } else {
      x['type'] = "password";
    }
  }

  doReverseFlip() {
    this.passwordErrorStatus = 0;
    this.reverseFlip = true;
    this.emailSuccess = false;
    this.passwordMask();
    this.showPassword = false;
    this.emailForm.reset();
    this.passwordForm.reset();
  }

  openForgotPasswordModal() {
    this.successfullySentForgotPwdLink = false;
    jQuery(this.fogotPasswordModal.nativeElement).modal({ backdrop: 'static', keyboard: false }, 'show');
    this.forgotPasswordModalText = {
      title: "Forgot Your Password?",
      description: "We will send a password reset link to " + this.emailForm.value.emailInput,
      buttonTitle: "Send reset link",
      status: 1
    }
  }
  sendResetLink() {
    this.modalBtnLoading = true;
    const resetLinkPayload = {
      email: this.emailForm.value.emailInput.toLowerCase()
    }

    return this.webservice.processPost(this.endPoint.signIn.sendPwdResetLink, resetLinkPayload)
      .pipe(map(
        (response) => {
          if (response['status'] === "success") {

            this.successfullySentForgotPwdLink = true;

            setTimeout(() => {
              this.modalBtnLoading = false;
            });
          }
        }
      ))
      .subscribe(
        (data) => { },
        (error) => {
          this.modalBtnLoading = false;
        }
      );

  }

}
