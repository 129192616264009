<div class="app_container" *ngIf="!verifySuccess">
  <div class="signup-container">
    <div class="row">
      <div class="col-md-6 d-none d-md-block">
        <div>
          <img src="../../../../../assets/images/sign_up/finger-print.svg">
        </div>
      </div>

      <div class="col-md-6 sign-up-form" [ngClass]="{'do-flip': registrationSuccess}">
        <div class="flip-box">
          <div class="flip-box-inner">

            <!-- begining of registration section -->
            <div class="flip-box-front">

              <form (ngSubmit)="doRegistration(signUpForm)" [formGroup]="signUpForm">

                <div class="signup-form-top-container">
                  <div class="signup-logo">
                    <img src="../../../../../assets/images/sign_up/zepto-signup-logo.svg">
                  </div>

                  <div class="input-container-top">

                    <input type="text" formControlName="name" class="input_rounded center_text_input" placeholder="Name"
                      maxlength="50" autofocus>
                    <input type="text" formControlName="email" class="input_rounded center_text_input" id="email-field"
                      placeholder="Email" maxlength="50" pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$"
                      [ngClass]="{'input-error': registerFormError == 3 || registerFormError == 6}">

                    <span toggle="#email-field" class="field-icon" (click)="clearSignupFormFields(1)"
                      *ngIf="registerFormError == 3 || registerFormError == 6">
                      <img class="clear-error" src="../../../../../assets/images/shared/remove-input-error.svg">
                    </span>

                    <input type="password" formControlName="password" class="input_rounded center_text_input"
                      [ngClass]="{'input-error': registerFormError == 5}" id="password-field"
                      placeholder="Password ( Must have at least 6 characters )" minlength="6" maxlength="50"
                      (ngModelChange)="checkPasswordLenght()">

                    <span *ngIf="!registrationSuccess">
                      <span toggle="#password-field" class="field-icon" *ngIf="showPasswordLengthCorrectTick">
                        <img class="correct-sign" src="../../../../../assets/images/sign_in/correct.svg">
                      </span>
                      <span toggle="#password-field" class="field-icon" (click)="clearSignupFormFields(2)"
                        *ngIf="registerFormError == 5">
                        <img class="clear-error clear-error-pwd"
                          src="../../../../../assets/images/shared/remove-input-error.svg">
                      </span>

                      <span toggle="#password-field" class="field-icon" (click)="passwordMask()">
                        <img class="eye-open" *ngIf="!showPassword"
                          src="../../../../../assets/images/sign_up/icons/eye-open.svg">
                        <img class="eye-close" *ngIf="showPassword"
                          src="../../../../../assets/images/sign_up/icons/eya-close.svg">
                      </span>
                    </span>

                    <div class="recaptcha-container">
                      <re-captcha formControlName="recaptcha"></re-captcha>
                    </div>


                    <div class="error-container">
                      <div *ngIf="registerFormError == 1">
                        <span>
                          <img src="../../../../../assets/images/shared/error-info.svg">
                        </span>
                        <span>Please enter a name to proceed.</span>
                      </div>

                      <div *ngIf="registerFormError == 2">
                        <span>
                          <img src="../../../../../assets/images/shared/error-info.svg">
                        </span>
                        <span>Please enter an email to proceed.</span>
                      </div>

                      <div *ngIf="registerFormError == 3">
                        <span>
                          <img src="../../../../../assets/images/shared/error-info.svg">
                        </span>
                        <span>Please enter a valid email.</span>
                      </div>

                      <div *ngIf="registerFormError == 6">
                        <span>
                          <img src="../../../../../assets/images/shared/error-info.svg">
                        </span>
                        <span>Email ID has already been registered.</span>
                      </div>

                      <div *ngIf="registerFormError == 4">
                        <span>
                          <img src="../../../../../assets/images/shared/error-info.svg">
                        </span>
                        <span>Please enter a password to proceed.</span>
                      </div>


                      <div *ngIf="registerFormError == 5">
                        <span>
                          <img src="../../../../../assets/images/shared/error-info.svg">
                        </span>
                        <span>Password should be minimmum 6 characters.</span>
                      </div>

                      <div *ngIf="registerFormError == 7">
                        <span>
                          <img src="../../../../../assets/images/shared/error-info.svg">
                        </span>
                        <span>Please check you are not a robot.</span>
                      </div>


                    </div>


                  </div>
                </div>
                <div class="signup-form-bottom-container">
                  <div class="input-container-bottom">
                    <div class="register-button">
                      <button type="submit" class="secondary_button1 fullWidth">
                        <span *ngIf="!loading">Register</span>
                        <span class="loading" *ngIf="loading"><img
                            src="../../../../../assets/images/shared/zepto-loader-button.gif"></span>
                      </button>
                    </div>
                    <div class="bottom-text">
                      <div>
                        <span class="section-one">Already have an account ?
                        </span>
                        <span class="section-two" [routerLink]="['/login']">
                          Sign in here
                        </span>
                      </div>

                      <div>
                        <span class="section-one">
                          By clicking ‘Register’, I agree to the
                        </span>
                        <a class="section-two" [routerLink]="['/terms']" target="_blank">
                          Terms of Service
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
            <!-- end of registration section -->

            <!-- verify section -->
            <div class="flip-box-back">
              <form (ngSubmit)="doVerification(emailVerifyForm)" [formGroup]="emailVerifyForm">

                <div class="signup-form-top-container">
                  <div class="verify-title">
                    <span>
                      Verify Account
                    </span>
                  </div>


                  <div class="verification-code-sent-msg">
                    <div>
                      Please enter the verification code we sent to {{userEmail}}
                    </div>
                  </div>

                  <div class="input-container-top">
                    <!-- <input type="text" class="input_rounded center_text_input" placeholder="Name"> -->

                    <input formControlName="digit1" [ngClass]="{'digit-box-error': varificationCodeValidation == 1}"
                      class="digit-box" id="codeBox1" type="text" maxlength="1" placeholder="#"
                      (paste)="digitBoxPaste($event)" (keydown)="onKeyUpEvent(1, $event)"
                      oninput="this.value=this.value.replace(/[^0-9]/g,'');" autofocus />
                    <input formControlName="digit2" [ngClass]="{'digit-box-error': varificationCodeValidation == 1}"
                      class="digit-box" id="codeBox2" type="text" maxlength="1" placeholder="#"
                      (keydown)="onKeyUpEvent(2,$event)" (paste)="digitBoxPaste($event)"
                      oninput="this.value=this.value.replace(/[^0-9]/g,'');" />
                    <input formControlName="digit3" [ngClass]="{'digit-box-error': varificationCodeValidation == 1}"
                      class="digit-box" id="codeBox3" type="text" maxlength="1" placeholder="#"
                      (keydown)="onKeyUpEvent(3,$event)" (paste)="digitBoxPaste($event)"
                      oninput="this.value=this.value.replace(/[^0-9]/g,'');" />
                    <input formControlName="digit4" [ngClass]="{'digit-box-error': varificationCodeValidation == 1}"
                      class="digit-box" id="codeBox4" type="text" maxlength="1" placeholder="#"
                      (keydown)="onKeyUpEvent(4,$event)" (paste)="digitBoxPaste($event)"
                      oninput="this.value=this.value.replace(/[^0-9]/g,'');" />





                    <div class="error-container">
                      <div *ngIf="varificationCodeValidation == 1">
                        <span>
                          <img src="../../../../../assets/images/shared/error-info.svg">
                        </span>
                        <span>Please enter the code you have received.</span>
                      </div>

                      <div *ngIf="varificationCodeValidation == 2">
                        <span>
                          <img src="../../../../../assets/images/shared/error-info.svg">
                        </span>
                        <span>The code you have entered is invalid.</span>
                      </div>

                      <div *ngIf="varificationCodeValidation == 3">
                        <span>
                          <img src="../../../../../assets/images/shared/error-info.svg">
                        </span>
                        <span>Code has expired. Please try resending a different code.</span>
                      </div>

                      <div *ngIf="varificationCodeValidation == 4">
                        <span>
                          <img src="../../../../../assets/images/shared/error-info.svg">
                        </span>
                        <span>Given email already verified</span>
                      </div>

                    </div>



                  </div>
                </div>
                <div class="signup-form-bottom-container">
                  <div class="input-container-bottom">
                    <div class="register-button">
                      <!-- <input type="submit" class="secondary_button1 fullWidth" value="Verify & Sign Up" data-toggle="modal"
                      data-target="#exampleModalCenter"> -->

                      <button type="submit" class="secondary_button1 fullWidth">
                        <span *ngIf="!loading">Verify & Sign Up</span>
                        <span class="loading" *ngIf="loading"><img
                            src="../../../../../assets/images/shared/zepto-loader-button.gif"></span>
                      </button>

                    </div>
                    <div class="bottom-text">
                      <div>
                        <span class="section-one">
                          The code you have received will expire within 2 days.
                        </span>
                      </div>

                      <div>
                        <span class="section-one">
                          I didn’t receive the code!
                        </span>
                        <span class="section-two" (click)="resendVerificationCode()">
                          Resend a different code
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
            <!-- end of verify -->

          </div>
        </div>
      </div>
    </div>
  </div>
</div>



<!-- Modal -->
<div #successModal class="modal fade" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle"
  aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <div class="title">
          Successfully Sent!
        </div>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="content">
          We sent a new code to <span>{{userEmail}}</span> Please check your email.
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="ok-btn" data-dismiss="modal" aria-label="Close"
          (click)="clearVerificationForm()">OK</button>
      </div>
    </div>
  </div>
</div>




<success *ngIf="verifySuccess"></success>