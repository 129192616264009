import { Component, OnInit } from '@angular/core';
import { CookieService } from 'ngx-cookie';
import { WebService } from '../services/web-service/web.service';
import { ApiEndpont } from '../api-endpoints/api-endpoint';
import { Router, RouteConfigLoadStart, RouteConfigLoadEnd, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  providers: [WebService, CookieService]
})
export class HeaderComponent implements OnInit {

  public signInStatus: boolean;
  private endPoint = ApiEndpont;
  public showHideNav: boolean = true;

  constructor(
    private cookieService: CookieService,
    private webservice: WebService,
    private router: Router,
    private activeRoute: ActivatedRoute) {
  }

  ngOnInit() {
    this.verifyToken();
    this.router.events.subscribe(event => {
      this.showHideNavBar(this.router.url);
    });

  }

  showHideNavBar(route) {

    var token = undefined;

    this.activeRoute.queryParams.subscribe(params => {
      token = params.token
    });

    if (route === "/signup" || route === "/login" || route === "/reset-password") {
      this.showHideNav = false;
    } else if (token != undefined) {
      this.showHideNav = false;
    } else {
      this.showHideNav = true;
    }
  }


  logOut() {

    this.cookieService.remove('AUTH_TOKEN');
    this.cookieService.remove('code');
    this.cookieService.remove('signUpStatus');

    this.webservice.processPost(this.endPoint.signOut.logOut, {})

      .subscribe(
        successData => {

          setTimeout(() => {
            window.location.href = "/login";
          }, 500);
        },
        error => {
          console.log("ERRORRRR, error");
          setTimeout(() => {
            window.location.href = "/login";
          }, 500);
        },
        () => {
          setTimeout(() => {
            window.location.href = "/login";
          }, 500);
        }
      );
  }

  gotoApp() {
    window.location.href = "/app/datalocker"
  }

  resetSignUp() {
    localStorage.setItem("currentSignUpActiveStep", "1");
    this.cookieService.remove("AUTH_TOKEN");
  }



  verifyToken() {
    if (this.cookieService.get("AUTH_TOKEN") != undefined) {
      const tokenPayload = {
        token: this.cookieService.get("AUTH_TOKEN")
      }
      this.webservice.processPost(this.endPoint.signIn.validateToken, tokenPayload).subscribe(response => {
        if (response['status'] === "valid") {
          this.signInStatus = true;
        } else {
          this.signInStatus = false;
        }
      }, error => {
        console.log("ERRORRRR, error");
        this.signInStatus = false;
      });
    }
  }
}