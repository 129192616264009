import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './modules/home/pages/home/home.component';
import { AboutUsComponent } from './modules/about-us/pages/about-us/about-us.component';
import { BlogComponent } from './modules/blog/pages/blog/blog.component';
import { PricingComponent } from './modules/pricing/pages/pricing/pricing.component';
import { PrivacyPolicyComponent } from './modules/privacy-policy/pages/privacy-policy/privacy-policy.component';
import { TermsComponent } from './modules/terms/terms/terms.component';
import { TeamComponent } from './modules/team/pages/team/team.component';


// new signup
import { SignUpComponent } from './modules/signup/pages/sign-up/sign-up.component';
import { SuccessComponent } from './modules/signup/components/success/success.component';
import { SignInComponent } from './modules/signin/pages/sign-in/sign-in.component';
import { ResetPasswordComponent } from './modules/signin/components/reset-password/reset-password.component';
import { NotFoundComponent } from './core/not-found/not-found.component';
import { AdvertisementComponent } from './modules/landing-pages/advertisement/advertisement.component';
import { PreReleaseComponent } from './modules/landing-pages/pre-release/pre-release.component';
import { HealthcareComponent } from './modules/landing-pages/healthcare/healthcare.component';


const routes: Routes = [
  {
    path: '',
    component: HomeComponent
  },
  {
    path: 'aboutus',
    component: AboutUsComponent
  },
  /*{
    path: 'blog',
    component: BlogComponent
  },
  {
    path: 'blog/:slug',
    component: BlogComponent
  },*/
  {
    path: 'pricing',
    component: PricingComponent
  },
  {
    path: 'login',
    component: SignInComponent
  },
  {
    path: 'signup',
    component: SignUpComponent
  },
  {
    path: 'reset-password',
    component: ResetPasswordComponent
  },
  {
    path: 'signup/billing/success',
    component: SuccessComponent
  },
  {
    path: 'privacy-policy',
    component: PrivacyPolicyComponent
  },
  {
    path: 'terms',
    component: TermsComponent
  },
  {
    path: 'team',
    component: TeamComponent
  },
  {
    path: 'ai-analytics-for-financial-services/asset-backed-securities',
    component: AdvertisementComponent
  },
  {
    path: 'early-bird-registration/insurance-claim-assistant-bot',
    component: PreReleaseComponent
  },
  {
    path: 'zepto-for-healthcare',
    component: HealthcareComponent
  },
  {
    path: '404',
    component: NotFoundComponent
  },
  {
    path: '**',
    component: NotFoundComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
