import { Component, OnInit } from '@angular/core';
import * as AOS from 'aos';

@Component({
  selector: 'app-three-simple-steps',
  templateUrl: './three-simple-steps.component.html',
  styleUrls: ['./three-simple-steps.component.scss']
})
export class ThreeSimpleStepsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    AOS.init();
  }

}
