import { Injectable } from '@angular/core';
import { Meta } from '@angular/platform-browser';

@Injectable()
export class MetaTagService {

  constructor(private meta: Meta) { }


  addTags(config) {

    this.meta.addTag({ name: 'twitter:card', content: 'summary' });
    this.meta.addTag({ name: 'twitter:site', content: '@Zeptolytics' });
    this.meta.addTag({ name: 'twitter:title', content: config.title });
    this.meta.addTag({ name: 'twitter:description', content: config.description });
    this.meta.addTag({ name: 'twitter:image', content: config.image });

    this.meta.addTag({ name: 'og:type', content: 'article' });
    this.meta.addTag({ name: 'og:site_name', content: 'Zepto' });
    this.meta.addTag({ name: 'og:title', content: config.title });
    this.meta.addTag({ name: 'og:description', content: config.description });
    this.meta.addTag({ name: 'og:image', content: config.image });
    this.meta.addTag({ name: 'og:url', content: 'https://www.zepto.io' });
  }


  generateTags(config) {

    this.meta.updateTag({ name: 'twitter:card', content: 'summary' });
    this.meta.updateTag({ name: 'twitter:site', content: '@Zeptolytics' });
    this.meta.updateTag({ name: 'twitter:title', content: config.title });
    this.meta.updateTag({ name: 'twitter:description', content: config.description });
    this.meta.updateTag({ name: 'twitter:image', content: config.image });

    this.meta.updateTag({ property: 'og:type', content: 'article' });
    this.meta.updateTag({ property: 'og:site_name', content: 'Zepto' });
    this.meta.updateTag({ property: 'og:title', content: config.title });
    this.meta.updateTag({ property: 'og:description', content: config.description });
    this.meta.updateTag({ property: 'og:image', content: config.image });
    this.meta.updateTag({ property: 'og:url', content: 'https://www.zepto.io' });
  }

}