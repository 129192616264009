<div class="app_container">
  <div *ngIf="showResetPwdForm == 1">
    <div class="reset-password-container" *ngIf="!showPasswordResetSuccess">
      <form (ngSubmit)="resetPassword(resetForm)" [formGroup]="resetForm">

        <div class="main-title">
          Reset Your Password
        </div>
        <div class="sub-title">
          Enter a new password.
        </div>
        <div class="content">

          <input type="password" formControlName="resetInput" minlength="6" maxlength="50" required [ngClass]="{'input-error': resetPwdStatus == 1}"
            class="input_rounded center_text_input" id="password-field" placeholder="Password" (ngModelChange)="checkPasswordLenght()">

          <span toggle="#password-field" class="field-icon" *ngIf="showPasswordLengthCorrectTick">
            <img class="correct-sign" src="../../../../../assets/images/sign_in/correct.svg">
          </span>

          <span toggle="#password-field" class="field-icon" (click)="clearPasswordField()" *ngIf="resetPwdStatus === 2">
            <img class="clear-error" src="../../../../../assets/images/shared/remove-input-error.svg">
          </span>

          <span toggle="#password-field" class="field-icon" (click)="passwordMask()">
            <img class="eye-open" *ngIf="!showPassword" src="../../../../../assets/images/sign_up/icons/eye-open.svg">
            <img class="eye-close" *ngIf="showPassword" src="../../../../../assets/images/sign_up/icons/eya-close.svg">
          </span>

          <div class="error-container">
            <div *ngIf="resetPwdStatus == 1">
              <span>
                <img src="../../../../../assets/images/shared/error-info.svg">
              </span>
              <span>Please enter a password to proceed.</span>
            </div>

            <div *ngIf="resetPwdStatus == 2">
              <span>
                <img src="../../../../../assets/images/shared/error-info.svg">
              </span>
              <span>Password should be minimmum 6 characters.</span>
            </div>
          </div>

        </div>

        <button type="submit" class="ok-btn">
          <span *ngIf="!loading">Reset Password</span>
          <span class="loading" *ngIf="loading"><img src="../../../../../assets/images/shared/zepto-loader-button.gif"></span>
        </button>
      </form>
    </div>

    <!-- back -->
    <div class="reset-password-container" *ngIf="showPasswordResetSuccess">

      <div class="main-title">
        Successfully Reset
      </div>
      <div class="sub-title">
        &nbsp;
      </div>

      <div class="content">
        <div class="success-text">
          The password for the account {{resetEmail}} has been successfully changed.
        </div>
      </div>
      <button type="submit" class="ok-btn" (click)="goToApp()">Go to app</button>

    </div>
  </div>

  <div class="pwd-expired-container" *ngIf="showResetPwdForm == 3">
    <div class="expired-image">
      <img src="../../../../../assets/images/sign_in/reset-link-expired.svg">
    </div>

    <div class="description-container">

      <div class="sorry">
        Sorry!
      </div>
      <div class="description">
        The password reset link has expired!
      </div>
      <button class="resend-btn" (click)="openResetLinkSendModal()">Resend a link</button>
    </div>
  </div>

  <not-found *ngIf="showResetPwdForm == 2"></not-found>

</div>

<!-- Modal -->
<div #resendLinkModal class="modal fade" id="resend-link-modal" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle"
  aria-hidden="true">

  <div class="modal-dialog modal-dialog-centered" role="document">

    <div class="modal-content" [ngClass]="{'do-flip': successfullySentLink}">
      <div class="flip-box">
        <div class="flip-box-inner">
          <div class="flip-box-front">

            <div class="modal-header">
              <div class="title" [innerHTML]="resedLinkModalText.title">
              </div>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <div class="content" [innerHTML]="resedLinkModalText.description">
              </div>
            </div>
            <div class="modal-footer">
              <button *ngIf="resedLinkModalText.status == 1" type="button" class="ok-btn" (click)="sendResetLink()">
                <span *ngIf="!modalBtnLoading" [innerHTML]="resedLinkModalText.buttonTitle"></span>
                <span class="loading" *ngIf="modalBtnLoading"><img src="../../../../../assets/images/shared/zepto-loader-button.gif"></span>
              </button>
              <button *ngIf="resedLinkModalText.status == 2" type="button" class="ok-btn" [innerHTML]="resedLinkModalText.buttonTitle"
                data-dismiss="modal" aria-label="Close">
              </button>
            </div>

          </div>

          <div class="flip-box-back">

            <div class="modal-header">
              <div class="title" [innerHTML]="resedLinkModalText.title">
              </div>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <div class="content" [innerHTML]="resedLinkModalText.description">
              </div>
            </div>
            <div class="modal-footer">
              <button *ngIf="resedLinkModalText.status == 1" type="button" class="ok-btn" (click)="sendResetLink()">
                <span *ngIf="!loading" [innerHTML]="resedLinkModalText.buttonTitle"></span>
                <span class="loading" *ngIf="loading"><img src="../../../../../assets/images/shared/zepto-loader-button.gif"></span>
              </button>
              <button *ngIf="resedLinkModalText.status == 2" type="button" class="ok-btn" data-dismiss="modal"
                aria-label="Close" (click)="goToHome()">
                <span *ngIf="!loading" [innerHTML]="resedLinkModalText.buttonTitle"></span>
                <span class="loading" *ngIf="loading"><img src="../../../../../assets/images/shared/zepto-loader-button.gif"></span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>