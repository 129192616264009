<div class="laptop-container">
  <div class="laptop-wrapper">
    <img src="../../../../../assets/images/home/banner_animation.gif">

    <!-- <p class="header_desc_mob">
      You can analyse your business data and it will predict whatever is that going to be
    </p>
    <a onclick="location.href='/signup';" class="header_try_btn_mob yellow_btn" href="#">Try for free</a> -->

  </div>

</div>


<div class="cloud_container">
  <img class="cloud_anim_1" src="../../../../../assets/images/home/header_clouds/ani_vector_07.svg">
  <img class="cloud_anim_2" src="../../../../../assets/images/home/header_clouds//ani_vector_06.svg">
  <img class="cloud_anim_3" src="../../../../../assets/images/home/header_clouds/ani_vector_01.svg">
  <img class="cloud_anim_4" src="../../../../../assets/images/home/header_clouds/ani_vector_03.svg">
  <img class="cloud_anim_5" src="../../../../../assets/images/home/header_clouds/ani_vector_05.svg">
  <img class="cloud_anim_6" src="../../../../../assets/images/home/header_clouds/ani_vector_04.svg">
</div>