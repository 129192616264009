<div class="comin-soon-container">


  <div class="row">

    <div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-3">
      <div class="card">
        <div class="card-body">
          <div class="row">
            <div class="col-12">
              <img class="coming_soon_img" src="../../../../../assets/images/home/feature_1.svg">
              <h2 class="coming_soon_feature_card-title">Consulting Bot</h2>
              <p class="coming_soon_p">
                Your personal advisor - one that makes recommendations, suggests action points and gives you reasons to why insights were
                generated for your data
              </p>
            </div>

          </div>
        </div>
      </div>
    </div>

    <div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-3">
      <div class="card">
        <div class="card-body">
          <div class="row">
            <div class="col-12">
              <img class="coming_soon_img" src="../../../../../assets/images/home/feature_2.svg">
              <h2 class="coming_soon_feature_card-title">Proactive Alerts</h2>
              <p class="coming_soon_p">
                Create customized parameters on your key charts and get instant email alerts when the chart values hit the set parameters
              </p>
            </div>

          </div>
        </div>
      </div>
    </div>

    <div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-3">
      <div class="card">
        <div class="card-body">
          <div class="row">
            <div class="col-12">
              <img class="coming_soon_img" src="../../../../../assets/images/home/feature_3.svg">
              <h2 class="coming_soon_feature_card-title">Story Book</h2>
              <p class="coming_soon_p">
                An immersive reporting platform where users can include their charts, insights and narrations to easily share with their
                colleagues
              </p>
            </div>

          </div>
        </div>
      </div>
    </div>

    <div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-3">
      <div class="card">
        <div class="card-body">
          <div class="row">
            <div class="col-12">
              <img class="coming_soon_img" src="../../../../../assets/images/home/feature_4.svg">
              <h2 class="coming_soon_feature_card-title">Data Connectors</h2>
              <p class="coming_soon_p">
                Connect to 3rd party apps used daily such as Google Analytics, Facebook, Amazon etc directly to analyse your data faster
              </p>
            </div>

          </div>
        </div>
      </div>
    </div>

  </div>

</div>