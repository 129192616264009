<!-- ==================== Start: Footer ==================== -->
<footer id="footer" class="page-footer font-small unique-color-dark mt-4" *ngIf="showHideFooterSection">

    <div class="container text-center text-md-left mt-5">

        <div class="row mt-3">

            <div class="col-md-4 col-lg-4 col-xl-4 mx-auto mb-4 footer_links">

                <h6 class="footer_title">Product</h6>
                <hr class="footer_title_hr accent-2 mb-4 mt-0 d-inline-block mx-auto">
                <p>
                    <a [routerLink]="['/']">Home</a>
                </p>
                <p>
                    <a [routerLink]="['/pricing']">Pricing</a>
                </p>
                <!-- <p>
                    <a onclick="location.href='/signup';" class="footer_sign_up">Sign Up</a>
                </p> -->

            </div>

            <div class="col-md-4 col-lg-4 col-xl-4 mx-auto mb-4 footer_links">

                <h6 class="footer_title">Company</h6>
                <hr class="footer_title_hr accent-2 mb-4 mt-0 d-inline-block mx-auto">
                <p>
                    <a [routerLink]="['/aboutus']">Our Story</a>
                </p>
                <p>
                    <a [routerLink]="['/team']">Team</a>
                </p>
                <!--<p>
                    <a [routerLink]="['/blog']">Blog</a>
                </p>-->
                <p>
                    <a [routerLink]="['/terms']">Terms of Service</a>
                </p>
                <p>
                    <a [routerLink]="['/privacy-policy']">Privacy Policy</a>
                </p>

            </div>

            <div class="col-md-4 col-lg-4 col-xl-4 mx-auto mb-md-0 mb-4 footer_links">

                <h6 class="footer_title">Contact Us</h6>
                <hr class="footer_title_hr accent-2 mb-4 mt-0 d-inline-block mx-auto">

                <div class="address-secrion">
                    <p class="title">
                        - USA
                    </p>
                    <p class="address">
                        305 E 21st St,<br>
                        New York, NY 10010
                    </p>
                </div>


                <div class="address-secrion">
                    <p class="title">
                        - Sri Lanka
                    </p>
                    <p class="address">
                        Level 4, Access Tower 1, <br>
                        278, Union Pl, Colombo 01000
                    </p>
                </div>
                <br>
                <p>
                    <a href="mailto:support@zepto.io">support@zepto.io</a>
                </p>

                <div class="row social_medias">
                    <div class="col-2">
                        <a href="https://www.linkedin.com/company/zepto-io/" target="_blank">
                            <img src="../../../assets/images/footer/icon_linkedin.svg" alt="Linkedin" title="Linkedin">
                        </a>
                    </div>
                    <div class="col-2">
                        <a href="mailto:support@zepto.io">
                            <img src="../../../assets/images/footer/icon_mail.svg" alt="Email" title="support@zepto.io">
                        </a>
                    </div>
                    <div class="col-2">
                        <a href="https://www.facebook.com/zeptobi/" target="_blank">
                            <img src="../../../assets/images/footer/icon_fb.svg" alt="Facebook" title="Facebook">
                        </a>
                    </div>
                    <div class="col-2">
                        <a href="https://www.youtube.com/channel/UCvR4THJHvnury-lIoJ044nw" target="_blank">
                            <img src="../../../assets/images/footer/icon_youtube.svg" alt="Youtube" title="Youtube">
                        </a>
                    </div>
                    <div class="col-2">
                        <a href="https://twitter.com/Zeptolytics" target="_blank">
                            <img src="../../../assets/images/footer/icon-twitter.svg" alt="Twitter" title="Twitter">
                        </a>
                    </div>
                    <div class="col-2">
                        <a href="https://medium.com/zepto-io" target="_blank">
                            <img src="../../../assets/images/footer/icon_medium.svg" alt="Medium" title="Medium">
                        </a>
                    </div>

                </div>

            </div>

        </div>

    </div>

    <hr class="footer_divider_hr">


    <div class="footer_copyright text-center py-3">Copyright © {{ today | date: 'yyyy' }}&nbsp;&nbsp;&nbsp;&nbsp;
        |&nbsp;&nbsp;&nbsp;&nbsp;
        Zeptolytics
        (Pvt) Ltd&nbsp;&nbsp;&nbsp;&nbsp; |&nbsp;&nbsp;&nbsp;&nbsp; All rights reserved
    </div>

</footer>
<!-- ==================== End: Footer ==================== -->