<div class="contact-us-form" *ngIf="showContactUsForm">


  <div class="loader" *ngIf="loading">
    <app-loader></app-loader>
  </div>

  <form [formGroup]="contactUsForm" (ngSubmit)="contactUS(contactUsForm.value)">
    <div class="row">
      <div class="col-sm-6">
        <input name="contact-name" class="contact-name input_rounded" placeholder="Name*" name="name" formControlName="name" required>
      </div>

      <div class="col-sm-6">
        <input name="contact-company" class="contact-company input_rounded" placeholder="Company*" name="company" formControlName="company"
          required>
      </div>

      <div class="col-sm-6">
        <select required="true" class="contact-employee_size input_rounded" name="contact-employee_size" id="employee_size_select"
          placeholder="Employee Size*" name="companySize" formControlName="companySize">
          <option value="" disabled selected>Employee Size*</option>
          <option id="employee_size1" value="5-10">5-10</option>
          <option id="employee_size2" value="10-50">10-50</option>
          <option id="employee_size3" value="50-100">50-100</option>
          <option id="employee_size4" value="more100">More than 100</option>
        </select>
      </div>

      <div class="col-sm-6">
        <input name="contact-subject" class="contact-subject input_rounded" placeholder="Subject*" name="subject" formControlName="subject"
          required>
      </div>

      <div class="col-sm-6">
        <input name="contact-email" type="email" class="contact-email input_rounded" placeholder="Email*" name="email" formControlName="email"
          required>
      </div>

      <div class="col-sm-12">
        <textarea name="contact-message" class="contact-message input_rounded" rows="6" cols="50" placeholder="Message*" name="message"
          formControlName="message" required></textarea>
      </div>

      <div class="col-sm-12">
        <br>
        <input type="submit" class="secondary_button2" value="Submit" [disabled]="!contactUsForm.valid">

      </div>

    </div>
  </form>

</div>






<div class="contact-us-form-succsess" *ngIf="!showContactUsForm">
  <div class="contact_form contct_success_wrapper">

    <div class="contact_us_success_message">
      <h2>
        Thank You for Getting in Touch!
      </h2>
      <br>

      <div>
        <img src="../../../../../assets/images/shared/contact_us_success_hr.svg">
      </div>
      <br>
      <h3>
        In the mean time, see what kinds of cool stuff we are up to...
      </h3>
      <br>
      <br>
      <a href="https://twitter.com/Zeptolytics" target="_blank">
        <button class="white_btn white_btn_padding contct_us_twitter_btn">Follow Us on Twitter</button>
      </a>
      <br>
      <a href="https://www.facebook.com/zeptobi" target="_blank">
        <button class="white_btn white_btn_padding contct_us_facebook_btn">Follow Us on Facebook</button>
      </a>
      <br>
      <a href="https://medium.com/zepto-io" target="_blank">
        <button class="white_btn white_btn_padding contct_us_medium_btn">Follow Us on Medium</button>
      </a>

    </div>

  </div>
</div>