import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-story-video',
  templateUrl: './story-video.component.html',
  styleUrls: ['./story-video.component.scss']
})
export class StoryVideoComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
