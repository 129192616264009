<div class="three-simple-step-section">

  <app-section-title [sectionTitle]="'Three Simple Steps'"></app-section-title>


  <div class="d-none d-md-block">
    <div class="wrapper" data-aos="zoom-in" data-aos-delay="1100">
      <div class="item d-flex justify-content-end">
        <img src="../../../../../assets/images/home/ani_popup_01.svg">
      </div>
      <div class="item d-flex justify-content-start">
        <img src="../../../../../assets/images/home/ani_popup_02.svg">
      </div>
    </div>
  </div>







  <div class="container three-step-animation-contaner">
    <div class="row center-block text-center three_steps_img">
      <div class="col-md-4" data-aos="zoom-in">
        <img class="step1" src="../../../../../assets/images/home/upload_data.svg">
        <div class="row step-title">
          <div class="col-3">
            <span class="step-no">01</span>
          </div>

          <div class="col-9 text-left">
            <h2 class="title">Upload data</h2>
          </div>

          <div class="col-3"></div>

          <div class="col-9 text-left">
            <h2 class="step_desc">Upload structured tabular data in the form of an Excel or CSV</h2>
          </div>
        </div>
      </div>

      <div class="col-md-4" data-aos="zoom-in" data-aos-delay="300">
        <img class="step2" src="../../../../../assets/images/home/search.svg">
        <div class="row step-title">
          <div class="col-3">
            <span class="step-no">02</span>
          </div>

          <div class="col-9 text-left">
            <h2 class="title">Search</h2>
          </div>

          <div class="col-3"></div>

          <div class="col-9 text-left">
            <h2 class="step_desc">Ask Zepto for any questions you have on your data in Plain English!</h2>
          </div>
        </div>
      </div>

      <div class="col-md-4" data-aos="zoom-in" data-aos-delay="600">
        <img class="step3" src="../../../../../assets/images/home/moniter.svg">

        <div class="row step-title">
          <div class="col-3">
            <span class="step-no">03</span>
          </div>

          <div class="col-9 text-left">
            <h2 class="title">Monitor</h2>
          </div>

          <div class="col-3"></div>

          <div class="col-9 text-left">
            <h2 class="step_desc">Create your own dashboard with charts in just 3 clicks</h2>
          </div>
        </div>
      </div>
    </div>
  </div>


</div>