import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-cover-animation',
  templateUrl: './cover-animation.component.html',
  styleUrls: ['./cover-animation.component.scss']
})
export class CoverAnimationComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
