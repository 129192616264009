import { Component, OnInit, HostListener, ElementRef } from '@angular/core';
import {
  trigger,
  state,
  style,
  animate,
  transition
} from '@angular/animations';


@Component({
  selector: 'app-counts',
  templateUrl: './counts.component.html',
  styleUrls: ['./counts.component.scss'],
  animations: [
    trigger('scrollAnimation', [
      state('show', style({
        display: "block"
      })),
      state('hide', style({
        display: "none"
      })),
    ])
  ]
})

export class CountsComponent implements OnInit {

  state = 'hide'



  constructor(public el: ElementRef) { }

  ngOnInit() {
  }


  @HostListener('window:scroll', ['$event'])
  checkScroll() {
    const componentPosition = this.el.nativeElement.offsetTop;

    let percentage = (window.pageYOffset * 60) / 100;

    const scrollPosition = window.pageYOffset + percentage;


    if (scrollPosition >= componentPosition) {
      this.state = 'show'
    } else {
      this.state = 'hide'
    }

  }

}
