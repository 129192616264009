<div class="video-section">

  <app-section-title [sectionTitle]="'Paul&#8217;s Story'"></app-section-title>


  <div class="story_lap_with_cloud">
    <div class="row">
      <div class="col-md-12">

        <div class="story_laptop_container">
          <div class="story_laptop-wrapper">
            <video id="main_video" preload controls>
              <source src="../../../../../assets/videos/pauls_video.mp4" type='video/mp4'>
            </video>
          </div>
        </div>

      </div>
    </div>
  </div>

</div>