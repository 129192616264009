import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import { SafeResourceUrl, DomSanitizer } from '@angular/platform-browser';
import * as jQuery from 'jquery';

import { WebService } from '../../../../core/services/web-service/web.service';
import { MetaTagService } from '../../../../core/services/meta-tag-service/meta-tag.service';
import { Title } from '@angular/platform-browser';
import { ApiEndpont } from '../../../../core/api-endpoints/api-endpoint';
declare var jQuery: any;
declare var $: any;

@Component({
  selector: 'app-blog',
  templateUrl: './blog.component.html',
  styleUrls: ['./blog.component.scss'],
  providers: [WebService, MetaTagService, Title]
})
export class BlogComponent implements OnInit {

  public blogList: any;
  public singlePost: any;
  public showBlogList: boolean = true;
  public slug: string;
  public selectedPostURL: string;
  url: SafeResourceUrl;
  private endPoint = ApiEndpont;
  public loadingBlogList: boolean;
  public loadingSingleBlogPost: boolean;

  public iheight: string = "7500px";

  public postSize: number;
  public myPost: any;

  constructor(private webservice: WebService,
    private activeRoute: ActivatedRoute,
    private router: Router,
    private sanitizer: DomSanitizer,
    private titleService: Title,
    private meta: MetaTagService) { }


  ngOnInit() {


    this.slug = this.activeRoute.snapshot.params['slug'];
    this.selectedPostURL = this.endPoint.blog.url + this.slug;
    this.url = this.sanitizer.bypassSecurityTrustResourceUrl(this.selectedPostURL);



    if (this.slug != undefined) {
      this.showBlogList = false;
      this.getSingleBlogPost(this.slug);
    } else {



      this.titleService.setTitle('Zepto Blog- Everything about Data');

      // ============== add meta tags ================
      this.meta.addTags(
        {
          title: "Zepto Blog- Everything about Data",
          description: "Zepto’s blog covers helpful tips and education in data in general and data analytics, visualization, AI, predictive analytics and more.",
          image: 'https://www.zepto.io/assets/images/shared/og-v3.png',
        }
      );

      this.showBlogList = true;
      this.getBlogPostList();
    }

  }


  getBlogPostList() {
    this.loadingBlogList = true;
    let url: string = this.endPoint.blog.url + "wp-json/wp/v2/posts?_embed&per_page=100";

    this.webservice.processGetBlog(url).subscribe(response => {

      this.blogList = response;
      this.showBlogList = true;

      this.loadingBlogList = false;

    }, error => {
      console.log("ERRORRRR, error");
      this.loadingBlogList = false;
    })
  }

  getSingleBlogPost(slug) {
    this.loadingSingleBlogPost = true;
    let url: string = this.endPoint.blog.url + "wp-json/wp/v2/posts?slug=" + slug + "&_embed";

    this.webservice.processGetBlog(url).subscribe(response => {

      this.singlePost = response;
      this.postSize = this.singlePost.length;

      this.myPost = response;

      this.titleService.setTitle(this.singlePost[0]['title']['rendered']);


      // // ============== add meta tags ================
      this.meta.addTags(
        {
          title: this.singlePost[0]['title']['rendered'],
          description: this.singlePost[0]['title']['rendered'],
          image: this.singlePost[0]['_embedded']['wp:featuredmedia'][0]['source_url'],
        }
      );
      this.loadingSingleBlogPost = false;

    }, error => {
      console.log("ERRORRRR, error");
      this.loadingSingleBlogPost = false;
    })
    this.selectedPostURL = this.endPoint.blog.url + this.slug;
    this.url = this.sanitizer.bypassSecurityTrustResourceUrl(this.selectedPostURL);


  }

  getBlogPostDetails(slug) {

    this.showBlogList = false;

    this.router.navigate(['/blog', slug]);

    this.getSingleBlogPost(slug);

  }

}