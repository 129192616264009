import { Component, OnInit } from '@angular/core';
import { WebService } from '../../../../core/services/web-service/web.service';

@Component({
  selector: 'app-key-features',
  templateUrl: './key-features.component.html',
  styleUrls: ['./key-features.component.scss'],
  // providers: [WebService]

})
export class KeyFeaturesComponent implements OnInit {

  constructor() { }

  ngOnInit() {


  }


}
