import { Component, OnInit } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { CookieService } from 'ngx-cookie';
import { WebService } from '../../../../core/services/web-service/web.service';
import { ApiEndpont } from '../../../../core/api-endpoints/api-endpoint';

const helper = new JwtHelperService();
declare let ga:Function;

@Component({
  selector: 'success',
  templateUrl: './success.component.html',
  styleUrls: ['./success.component.scss'],
  providers: [CookieService]
})
export class SuccessComponent implements OnInit {
  public showDetailsToggle: boolean;
  public userEmail: string;
  private endPoint = ApiEndpont;
  constructor(
    private cookieService: CookieService,  
    private webservice: WebService,) {

    const gtag = window['gtag'];
    gtag('event', 'conversion', {
      'send_to': 'AW-857353796/OlezCLuovJcBEMTc6JgD'
    });

    ga('send', 'event', 'NewUser_Add_Evt', 'New User', 'New User Added');

    var me = this;
    var box = document.querySelector(".icon");
    // Detect all clicks on the document
    document.addEventListener("click", function (event) {
      // If user clicks inside the element, do nothing
      if (event.target['closest'](".icon")) return;
      // If user clicks outside the element, hide it!
      me.showDetailsToggle = false;
    });
  }

  ngOnInit() {
    this.userEmail = helper.decodeToken(this.cookieService.get("AUTH_TOKEN")).email;

    let userData = this.webservice.processGet(this.endPoint.userInfo.getUserInfo).toPromise();
    this.cookieService.put("refNo", userData['userRefNo']);
  }

  showDetails() {
    this.showDetailsToggle = !this.showDetailsToggle;
  }
  goToTrialApp() {
    window.location.href = "/app/datalocker";
  }
  goToAccount() {
    window.location.href = "/app/account/profile";
  }

}
