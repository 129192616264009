import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'team',
  templateUrl: './team.component.html',
  styleUrls: ['./team.component.scss']
})
export class TeamComponent implements OnInit {

  public showMoreId: number;
  public selectedMember: number = 0;
  public isSelectMember: boolean = false;

  constructor() { }

  ngOnInit() {
  }


  showMore(id) {
    if (id == this.showMoreId) {
      this.showMoreId = -1;
    } else {
      this.showMoreId = id;
    }
  }

  selectMemberSm(id) {
    this.selectedMember = id;
    this.isSelectMember = true;

    let elmnt = document.getElementById("team-title");
    elmnt.scrollIntoView();
  }



  previous() {
    switch (this.selectedMember) {
      case 1:
        this.selectedMember = 3;
        break;

      case 2:
        this.selectedMember = 1;
        break;

      case 3:
        this.selectedMember = 2;
        break;

      default:
        break;
    }
  }

  next() {

    switch (this.selectedMember) {
      case 1:
        this.selectedMember = 2;
        break;

      case 2:
        this.selectedMember = 3;
        break;

      case 3:
        this.selectedMember = 1
        break;

      default:
        break;
    }

  }

}
