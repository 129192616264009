import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { CookieService } from 'ngx-cookie';
//import { Headers, RequestOptions } from '@angular/http';
import { ApiEndpont } from '../../api-endpoints/api-endpoint';

@Injectable()
export class WebService {

  private endPoint = ApiEndpont;

  constructor(
    private http: HttpClient,
    private cookieService: CookieService
  ) { }

  getToken() {
    if (this.cookieService.get('AUTH_TOKEN') != undefined) {
      let token: string;
      token = this.cookieService.get('AUTH_TOKEN');
      return token;
    } else {
      return "";
    }
  }


  /**
 * 
 * @param url - Destination URL
 */
  public processGet(url: string) {
    const headers = new HttpHeaders().set("x-access-token", this.getToken());
    return this.http.get(this.endPoint.apiEndpoint + url, { headers });
  }


  /**
* 
* @param url - Destination URL
*/
  public processGetBlog(url: string) {
    return this.http.get(url);
  }


  /**
 * 
 * @param url - Destination URL
 * @param query - JSON query of request payload
 */
  public processPost(url: string, query: any) {
    const headers = new HttpHeaders().set("x-access-token", this.getToken());
    return this.http.post(this.endPoint.apiEndpoint + url, query, { headers });
  }

}