import { Component, OnInit } from '@angular/core';
import { MetaTagService } from '../../../../core/services/meta-tag-service/meta-tag.service';
import { Title, Meta } from '@angular/platform-browser';
import { WebService } from '../../../../core/services/web-service/web.service';
import { CookieService } from 'ngx-cookie';
import { ApiEndpont } from '../../../../core/api-endpoints/api-endpoint';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-pricing',
  templateUrl: './pricing.component.html',
  styleUrls: ['./pricing.component.scss'],
  providers: [Title, MetaTagService, WebService, CookieService]
})
export class PricingComponent implements OnInit {

  public selectedPackage: number = 1;
  public resetContactUs: string;
  private endPoint = ApiEndpont;

  // 0 for no option, 1 gor goto app, 2 for get started
  public goToApp: number = 0;

  constructor(
    private titleService: Title,
    private meta: MetaTagService,
    private webservice: WebService,
    private cookieService: CookieService
  ) { }

  ngOnInit() {
    this.validateToken();

    this.titleService.setTitle('Zepto Pricing for Individuals Teams and Corporate - Zepto Data Analytics Tool');


    // ============== add meta tags ================
    this.meta.addTags(
      {
        title: "Zepto Pricing for Individuals Teams and Corporate - Zepto Data Analytics Tool",
        description: "Zepto plans and pricing scales with your business. Get deeper insights from your business data and make better decisions while sharing it with your team. Try Zepto for free.",
        image: 'https://www.zepto.io/assets/images/shared/og-v3.png',
      }
    );
  }

  changeActivePlanColor(plan) {
    this.selectedPackage = plan;
    switch (plan) {
      case 1:
        document.getElementById("plan_1_pill").style.color = "#000000";
        document.getElementById("plan_2_pill").style.color = "#777777";
        document.getElementById("plan_3_pill").style.color = "#777777";
        break;

      case 2:
        document.getElementById("plan_1_pill").style.color = "#777777";
        document.getElementById("plan_2_pill").style.color = "#000000";
        document.getElementById("plan_3_pill").style.color = "#777777";
        break;

      case 3:
        document.getElementById("plan_1_pill").style.color = "#777777";
        document.getElementById("plan_2_pill").style.color = "#777777";
        document.getElementById("plan_3_pill").style.color = "#000000";
        break;

      default:
        break;
    }
  }

  openContactUs() {
    this.resetContactUs = new Date().getTime().toString();
  }

  validateToken() {
    const tokenPayload = {
      token: this.cookieService.get("AUTH_TOKEN")
    }
    return this.webservice.processPost(this.endPoint.auth.validateToken, tokenPayload)
      .pipe(map(
        (response) => {
          if (response['status'] == "valid") {
            // enable go to app
            this.goToApp = 1;
          } else {
            // enable get started
            this.goToApp = 2;
          }
        }
      ))
      .subscribe(
        (data) => { },
        (error) => {
          // enable get started
          this.goToApp = 2;
        }
      );
  }

}
