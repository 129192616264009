<div class="app_container">

  <div class="blog_container">

    <div class="blog-list-loading" *ngIf="loadingBlogList">
      <img src="../../../../../assets/images/shared/zepto_loder.gif">
    </div>
    <div *ngIf="!loadingBlogList">
      <div *ngIf="showBlogList">
        <div class="blog_list" *ngFor="let blogs of blogList" (click)="getBlogPostDetails(blogs.slug)">
          <div class="row">
            <div class="col-12 col-sm-4">
              <img class="featured_img" [src]="blogs._embedded['wp:featuredmedia']['0'].source_url">
            </div>
            <div class="col-12 col-sm-8">
              <h3 class="blog_list_title" [innerHTML]="blogs.title.rendered"></h3>
              <div class="blog_list_date" [innerHTML]="blogs.date | date : 'longDate'"></div>
              <br>
              <div class="blog_list_content" [innerHTML]="blogs.excerpt.rendered"></div>
            </div>
          </div>
        </div>
      </div>
    </div>


    <div class="blog-list-loading" *ngIf="loadingSingleBlogPost">
      <img src="../../../../../assets/images/shared/zepto_loder.gif">
    </div>

    <div *ngIf="!loadingSingleBlogPost">
      <div class="detail_content" *ngIf="!showBlogList">
        <div *ngIf="postSize != 0">
          <!-- <iframe id="blogFrame" [src]="url" width="100%" [height]="iheight" frameBorder="0"></iframe> -->
          <h3 class="blog_title" [innerHTML]="myPost['0']['title']['rendered']"></h3>

          <div class="blog_date_author">
            <span class="author" [innerHTML]="myPost['0']['_embedded']['author']['0']['name']"></span>
            <span class="date">{{myPost['0']['date'] | date}}</span>
          </div>

          <div class="blog_list_content" [innerHTML]="myPost['0']['content']['rendered']"></div>
        </div>
        <div *ngIf="postSize == 0">
          <div class="no_blog_found">
            No blog post found!
          </div>
        </div>
      </div>
    </div>

  </div>
</div>