<div class="landing-page-wrapper">

  <div class="row">
    <div class="col-5">
      <div class="header-logo">
        <img src="../../../../assets/images/header/logo.svg">
      </div>
      <div class="hero-text">
        <div class="regular-title">
          Your Personal Data Scientist with
        </div>
        <div class="bold-title">
          Artificial Intelligence
        </div>
        <div class="hero-description">
          Zepto is the world’s simplest AI-powered tool for data analytics, trusted by many players in the Financial
          Services Sector. The main reason for this is due to the fact that the tool is simple enough to be used by
          professionals in Financial Services who are typically non-technical by nature.

          <div class="hero-btns">
            <button class="try-for-free-hero" [routerLink]="['/signup']">Try for Free</button>
            <button class="learn-more" [routerLink]="['/']">Learn more</button>
          </div>
        </div>

      </div>
    </div>
    <div class="col-7">
      <div class="hero-img">
        <img src="../../../../assets/images/landing-pages/hero-image.svg">
      </div>
    </div>
  </div>

  <div class="left-leaf1">
    <img src="../../../../assets/images/landing-pages/leaf-left.svg">
  </div>
  <div class="left-leaf2">
    <img src="../../../../assets/images/landing-pages/leaf-left.svg">
  </div>
  <div class="right-leaf1">
    <img src="../../../../assets/images/landing-pages/right-leaf.svg">
  </div>
  <div class="right-leaf2">
    <img src="../../../../assets/images/landing-pages/right-leaf2.svg">
  </div>
  <div class="bubble-vector1">
    <img src="../../../../assets/images/landing-pages/bubble1.svg">
  </div>
  <div class="bubble-vector2">
    <img src="../../../../assets/images/landing-pages/bubble2.svg">
  </div>
  <div class="bubble-vector3">
    <img src="../../../../assets/images/landing-pages/bubble3.svg">
  </div>
  <div class="bubble-vector4">
    <img src="../../../../assets/images/landing-pages/bubble4.svg">
  </div>
  <div class="bubble-vector5">
    <img src="../../../../assets/images/landing-pages/bubble5.svg">
  </div>

  <div class="row">
    <div class="col-2">

    </div>
    <div class="col-8">
      <hr class="hr-dark">
    </div>
    <div class="col-2">

    </div>
  </div>

  <div class="section-wrapper">
    <div class="section-container" id="section-container-1">

      <div class="section-paragraph">


        <div class="row">
          <div class="col-6">

            <div class="section-title-small">Use case</div>

            <div class="section-main-title">How it works in Asset-Backed Securities industry</div>

            <div class="pargraph1-text">
              <p>
                The asset-backed securities industry contributes a decent chunk for the financial services industry as
                a
                whole. Just to put into perspective, the size of the industry is close to the entire GDP of Australia
                which
                is 14th largest in the world.
              </p>
              <p>
                The industry is filled with data and experts who are trying to extract meaning out of it to get a
                competitive
                stronghold in the market. The key to proactive decision making is the timely identification of patterns
                from data and its constant monitoring with flexibility at will. However, the inherent need for
                technical
                skill
                sets to manipulate the data has been hindering the opportunities of non-technical managers who are in
                the
                front field and know how to solve problems better. The biggest reason is that traditional tools like
                Excel
                are limited in automated capabilities and heavily rely on input from human experts.
              </p>

              <div class="shade1" id="shade1">
                <br><br> <br><br>
                <button (click)="expandSection(1)">Learn more</button>
              </div>
            </div>

          </div>

          <div class="col-6">
            <div class="paragraph1-img">
              <img src="../../../../assets/images/landing-pages/first-artical.svg">
            </div>
          </div>

          <div class="col-12">
            <div class="pargraph-text-large">

              <p>
                Manually Identifying trends and patterns of KPIs such as Default Rates, Severity, Pre-payment, etc.
                across
                different dimensions such as product lines, geographic segments, etc. will be like finding a needle in
                a
                haystack and is, of course, a painstaking task. This is where ZEPTO comes in handy where the product is
                capable of crunching all the possible combinations using its proprietary AI engine and come up with
                patterns
                in minutes for managers to take action upon.
              </p>
              <p>
                It also provides an intuitive analytics area to analyze data flexibly without having to get stuck with
                static
                reports. The unique beauty is that even a non-technical manager can analyze the data on their own and
                create
                reports at his/her will. This will help to save time on repetitive tasks like creating the same chart
                again
                and again by binding the data in excel.
              </p>
              <p>
                Monitoring important KPIs over time is critical in this business. Typically, the dashboards are
                prepared
                by
                technical staff as per given requirements and any modification will take weeks if not months. For a
                dynamic
                business environment, this will cause a huge drawback on the performance. By keeping this in mind we
                have
                built our dashboards in such a way that a non-technical manager no longer have to wait for IT staff.
                The
                dashboard is truly self-serviceable.
              </p>
              <p>
                If you feel like this is the product for you, why don’t you give it a try? Feel free to signup for our
                free
                trial and enjoy the benefits now!
              </p>
            </div>
          </div>
        </div>

        <div class="try-now-btn">
          <button [routerLink]="['/signup']">Try Now</button>
        </div>
      </div>
    </div>
  </div>


  <div class="italic-paragraph">
    <div class="italic-container">
      <div class="quote-1">❝</div>
      <div class="quote-2">❞</div>
      <div class="text">The key to proactive decision making is the timely identification of patterns from data and its
        constant monitoring with flexibility at will</div>
    </div>
  </div>


  <div class="section-wrapper">
    <div class="section-container" id="section-container-2">

      <div class="section-paragraph">


        <div class="row">
          <div class="col-6">

            <div class="paragraph2-img">
              <img src="../../../../assets/images/landing-pages/second-artical.svg">
            </div>

          </div>

          <div class="col-6">

            <div class="section-title-small">Who we are?</div>

            <div class="section-main-title">Background to the Company</div>

            <div class="pargraph2-text">
              <p>
                ZEPTO is a SaaS based Data Analytics Product with an automated AI Driven Insight generator from tabular
                formatted data and an intuitive visualisation area with analytics capabilities using intelligent
                algorithms.This is mainly to make analytics a truly self serviceable thing for small businesses in the
                financial services sector.
              </p>
              <p>
                Currently, we serve customers like KPMG, Capital Alliance, Alliance Finance, First Capital, etc. by
                providing Artificial Intelligence powered Data Analytics Software Product (ZEPTO) for their executives
                to analyze data to make proactive decisions. This has reduced their time to reporting from days to
                hours mainly due to its capability to crunch data and help interpret it in a story-like manner.
              </p>

              <div class="shade2" id="shade2">
                <br><br> <br><br>
                <button (click)="expandSection(2)">Learn more</button>
              </div>
            </div>
          </div>

        </div>

      </div>
    </div>
  </div>


  <br>
  <br>
  <br>
  <br>
  <div class="section-wrapper">
    <div class="section-container">
      <div class="who-with-us-title">
          Our Customers
      </div>
      <div class="who-with-img">

        <img src="../../../../assets/images/landing-pages/kpmg-brands.svg">
        <img src="../../../../assets/images/landing-pages/eternis-brands.svg">
        <img src="../../../../assets/images/landing-pages/alliance-finance.svg">
        <img src="../../../../assets/images/landing-pages/dynawash.svg">
        <img src="../../../../assets/images/landing-pages/kotak-brands.svg">

      </div>
    </div>
  </div>

  <br>
  <br>
  <br>
  <br>
  <br>
  <br>


  <div class="section-title-bottom">
    <div class="secton-title-container">
      Companies have been pooling their data for years, but many still don't know how to harness its power. We have
      built the world’s simplest BI & Analytics tool to help you out with that.
    </div>
  </div>

  <div class="try-for-free">
    <button class="try-free-btn" [routerLink]="['/signup']">Try for free</button>
    <div class="explore-trial">Explore the 3-day free trial</div>
  </div>






  <div class="footer">
    <div class="row">
      <div class="col-5">
        <br><br><br>
        <div class="logo-white">
          <img src="../../../../assets/images/landing-pages/logo-white.svg">
        </div>
        <br><br>
        <div class="title-small">
          Your Personal Data Scientist with
        </div>
        <div class="title-large">
          Artificial Intelligence
        </div>
        <div class="description">
          Zepto is the world’s simplest AI-powered tool for data analytics, trusted by many players in the Financial
          Services Sector. The main reason for this is due to the fact that the tool is simple enough to be used by
          professionals in Financial Services who are typically non-technical by nature.
        </div>
      </div>
      <div class="col-1"></div>
      <div class="col-6">
        <div class="row side-links-container">
          <div class="col-4 side-links">
            Product
            <hr>
            <div class="sub-links">
              <div [routerLink]="['/']">Home</div>
              <div [routerLink]="['/pricing']">Pricing</div>
            </div>
          </div>
          <div class="col-4 side-links">
            Company
            <hr>
            <div class="sub-links">
              <div [routerLink]="['/aboutus']">Our Story</div>
              <div [routerLink]="['/team']">Team</div>
              <div [routerLink]="['/terms']">Terms of Services</div>
              <div [routerLink]="['/privacy-policy']">Privacy Policy</div>
            </div>
          </div>
          <div class="col-4 side-links">
            Contact Us
            <hr>
            <div class="sub-links">
              <div><a href="mailto:support@zepto.io">support@zepto.io</a></div>
            </div>
          </div>
        </div>
        <div class="social-links">
          <a href="https://www.linkedin.com/company/zepto-io/" target="_blank">
            <img src="../../../../assets/images/landing-pages/social-media/linkedin.svg">
          </a>
          <a href="mailto:support@zepto.io">
            <img src="../../../../assets/images/landing-pages/social-media/email.svg">
          </a>
          <a href="https://www.facebook.com/zeptobi/" target="_blank">
            <img src="../../../../assets/images/landing-pages/social-media/fb.svg">
          </a>
          <a href="https://www.youtube.com/channel/UCvR4THJHvnury-lIoJ044nw" target="_blank">
            <img src="../../../../assets/images/landing-pages/social-media/youtube.svg">
          </a>
          <a href="https://twitter.com/Zeptolytics" target="_blank">
            <img src="../../../../assets/images/landing-pages/social-media/twitter.svg">
          </a>
          <a href="https://medium.com/zepto-io" target="_blank">
            <img src="../../../../assets/images/landing-pages/social-media/medium.svg">
          </a>
        </div>
      </div>
    </div>

    <hr class="footer-hr">

    <br>

    <div class="row bottom">
      <div class="col-2"></div>
      <div class="col-8">
        <div class="row">
          <div class="col-3">Copyright @ 2019</div>
          <div class="col-1">|</div>
          <div class="col-4">Zeptolytics (Pvt) Ltd</div>
          <div class="col-1">|</div>
          <div class="col-3">All rights reserved</div>
        </div>
      </div>
      <div class="col-2"></div>
    </div>
    <br>
  </div>


  <div class="bubble bubble1"></div>
  <div class="bubble bubble2"></div>
  <div class="bubble bubble3"></div>
  <div class="bubble bubble4"></div>
  <div class="bubble bubble5"></div>
  <div class="bubble bubble6"></div>
  <div class="bubble bubble6"></div>
  <div class="bubble bubble7"></div>
  <div class="bubble bubble8"></div>
  <div class="bubble bubble9"></div>
  <div class="bubble bubble10"></div>
  <div class="bubble bubble11"></div>
  <div class="bubble bubble12"></div>
  <div class="bubble bubble13"></div>
  <div class="bubble bubble14"></div>
  <div class="bubble bubble15"></div>
  <div class="bubble bubble16"></div>
  <div class="bubble bubble16"></div>
  <div class="bubble bubble17"></div>
  <div class="bubble bubble18"></div>
  <div class="bubble bubble19"></div>
  <div class="bubble bubble20"></div>



</div>