import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { MetaTagService } from '../../../../core/services/meta-tag-service/meta-tag.service';

@Component({
  selector: 'app-about-us',
  templateUrl: './about-us.component.html',
  styleUrls: ['./about-us.component.scss'],
  providers:[Title,MetaTagService]
})
export class AboutUsComponent implements OnInit {

  constructor(private titleService: Title,
    private meta: MetaTagService) { }

  ngOnInit() {

    // window.addEventListener('scroll', this.scroll, true); //third parameter


    this.titleService.setTitle('About Zepto: Simplest Data Analytics Tool');


    // ============== add meta tags ================
    this.meta.addTags(
      {
        title: "About Zepto: Simplest Data Analytics Tool",
        description: "At Zepto our primary vision is to help users understand their data and its potential better, simpler and more effectively. Learn about the people and products we have devoted to that goal.",
        image: 'https://www.zepto.io/assets/images/shared/og-v3.png',
      }
    );


    // ================== change meta tags =================
    // this.meta.generateTags({
    //   title: "About Zepto: Simplest Data Analytics Tool",
    //   description: "At Zepto our primary vision is to help users understand their data and its potential better, simpler and more effectively. Learn about the people and products we have devoted to that goal.",
    //   image: 'https://www.zepto.io/assets/images/shared/og-v3.png',
    // });

  }


  ngOnDestroy() {

    // window.removeEventListener('scroll', this.scroll, true);

  }


  scroll = (): void => {
    //handle your scroll here
    //notice the 'odd' function assignment to a class field
    //this is used to be able to remove the event listener
  };

}
