<div class="app_container">

  <div class="card privacy_terms_card">
    <div class="card-body">
      <h1 class="privacy_terms_title">
        ZEPTO Privacy Policy
      </h1>
      <br>

      <div class="privacy_image">
        <img src="../../../../../assets/images/privacy-policy/privacy_policy.svg">
      </div>







      <div style="padding: 0 5%; color: #777777;">
        <div class="row">

          <div class="each-features col-xs-12">
            <div class="inner">

              <div>
                <p>Privacy Policy.</p>
                <br>
                <ul>
                  <li>We intend to provide you with a high degree of comfort and confidence in terms of privacy for your
                    content when using the ZEPTO service.</li>
                  <li>We will endeavor to ensure that you have control over the content you upload to the ZEPTO service.
                    Your content will not be shared with any third party without your permission (unless required by law).
                    You also can delete your content from our systems.
                  </li>
                  <li>ZEPTO will not claim any ownership rights to your content. If you provide ZEPTO with credentials for
                    a third-party service, we will only use those credentials to list your content. We will also not upload
                    anything into ZEPTO without your permission.</li>
                </ul>
              </div>

              <p>By using this service, you agree to allow us to collect and process information as described below. If you
                have any questions or believe that content has been wrongfully uploaded into ZEPTO, please contact us at
                contact@zepto.io</p>

              <div>
                <p>COLLECTION: We collect three types of Personally Identifiable Information (PII) which is used purely to
                  enable ZEPTO service functionality and to better allow us to understand how visitors navigate our website.</p>
                <br>
                <ul>
                  <li>Purchase Information: When you purchase paid features of the ZEPTO service, we will collect your name,
                    billing and shipping address and any other information necessary to complete the transaction.
                  </li>
                  <li>Log-in Information: If you create a ZEPTO account, you provide your email address, name and create
                    a password that we store so you can access and use the ZEPTO service.
                  </li>
                  <li>Website Use Information: We use tools (including Google Analytics) to collect information from visitors
                    in order to better understand how they use our website (e.g., browser type, domains, pageviews). We use
                    session cookies to keep you logged in while you use features of our web application. These however disappear
                    after you close your browser. We also use persistent cookies which stay in your browser and allow us
                    to recognize you when you return to the site. Most Internet browsers automatically accept cookies, but
                    you can change the settings to stop accepting cookies or to prompt you before accepting a cookie from
                    the websites you visit. However, if you turn off cookies, parts of the ZEPTO service may not work for
                    you.</li>
                </ul>
              </div>

              <p>TRANSFER: We do not sell our customer lists. We may share your personally identifiable information with
                third parties purely for purposes of providing you with the ZEPTO services. These third parties may use your
                information to perform services on our behalf, and to offer you other products or services that may be of
                interest to you. Except as described, ZEPTO will only provide personally identifiable information collected
                on the website to a third party if required to do so by law, or in the good-faith belief that such action
                is necessary to comply with laws or respond to a court order, subpoena, or search warrant. We may share aggregated,
                non-personally identifiable information (such as usage statistics or other information) with third parties
                at any time and for any reason.</p>

              <p>SECURITY: ZEPTO takes serious note of security. We use commercially standard methods to transmit your content
                securely including HTTPS and SSL. The content is stored using secure AWS cloud services.</p>

              <p>International Visitors: ZEPTO is hosted in United States. If you are accessing the site from any third party
                jurisdiction with laws governing content collection and use that may differ from United States Law, then
                please note that you are transferring your personal content to the United States and by providing your personal
                content you consent to that transfer.
              </p>

              <p>Business Transitions: Upon the sale or transfer of the company and its assets, we will require a purchaser
                to treat our data under the privacy statement in place at the time of its collection.
              </p>

              <p>Changes to Privacy Policy: Please note that our Privacy Policy may change from time to time. However, we
                will not reduce your rights under this Privacy Policy without your explicit consent. When we decide to do
                so we will post those changes to this Privacy Notice, and other places we deem appropriate so that you are
                aware of what information we collect, how we use it, and under what circumstances, if any, we disclose it.
                We will also keep prior versions of this Privacy Policy in an archive for your review.
              </p>

              <p>Questions, Concerns and Comments: If you have any questions regarding our privacy policy, please contact
                us:
                <br> Zeptolytics (Pvt) Ltd
                <br> Level 04, Access Tower 1,
                <br> No.278, Union Place,
                <br> Colombo 02.
                <br> Email: support@zepto.io
                <br>
              </p>

            </div>

          </div>


        </div>
      </div>


    </div>
  </div>
</div>