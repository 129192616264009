<div class="app_container">

  <div class="success-container">
    <div class="container">
      <div class="success-logo">
        <img src="../../../../../assets/images/sign_up/signup-success-logo.svg">
      </div>

      <div class="success-title">
        Registration Successful!
      </div>

      <div class="success-description">
        <div>Hello <span>{{userEmail}}</span></div>
        <div>
          You have successfully registered with <span>ZEPTO.</span>
        </div>
      </div>

      <div class="continue-btn-container">

        <div class="row">
          <div class="col-12 col-md-5">
            <div class="arrow-box-container" *ngIf="showDetailsToggle">
              <div class="arrow-box">
                <ul>
                  <li>Valid for 72 hours</li>
                  <li>Up to 2 datasets can be uploaded</li>
                  <li>Collaboration is not allowed</li>
                  <li>Limited dashboard</li>
                </ul>
              </div>
            </div>
            <button class="filled-btn">
              <span class="text" (click)="goToTrialApp()">
                Continue with the Limited Free Trial
              </span>
              <span class="icon" (click)="showDetails()">
                <img src="../../../../../assets/images/sign_up/icons/icon1.svg">
              </span>
            </button>
          </div>
          <div class="col-12 col-md-2">
            <div class="or">
              Or
            </div>
          </div>
          <div class="col-12 col-md-5">
            <div class="outline-btn" (click)="goToAccount()">
              <span class="icon">
                <img src="../../../../../assets/images/sign_up/icons/icon2.svg">
              </span>
              <span class="text">
                Buy Now
              </span>
            </div>
          </div>
        </div>

      </div>

    </div>
  </div>
</div>