<div class="app_container">

  <div class="card about_us_card">
    <div class="card-body">

      <h1 class="about_us_title">
        How it all began...
      </h1>
      <br>
      <h3 class="about_us_desc">
        Helping users understand their data and its potential better, simpler and more effectively
      </h3>
      <br>
      <br>

      <p>Data is the new oil and AI is already the next big thing, and there's no doubt that there's an eruption of
        data globally
        at present. Companies have been pooling their data for years, but many still don't know how to harness its
        power.
      </p>

      <p>We've always been obsessed about how we could make people understand data better. Since its launch in 2017,
        Zepto has
        helped its users understand their business data better and its full potential.
      </p>

      <p>Most BI and Analytical tools take the user on quite a complicated path. Too many features that are not
        relevant, settings,
        controls and distracts. None of them end up connecting well with the user nor helps them get the full potential
        of
        their data.
      </p>

      <p>We wanted to change this, we wanted to focus on what matters.</p>

      <div class="about_us_card_img">
        <img src="../../../../../assets/images/about_us/about_us.svg">
      </div>

    </div>
  </div>

  <br>
  <div class="card about_us_card">
    <div class="card-body">

      <h1 class="about_us_title">
        <br>Beautiful Simple and Smart
      </h1>
      <br>

      <p>At Zepto, we believe in keeping things simple and clean. It's quite a challenge to build a simple tool
        compared to
        a complicated one.
      </p>

      <p>We understood how an ordinary user could use and benefit from an analytical tool and merged it with design
        elements
        and thought process of a user. Oh, this is the fun part, knowing the power of data, we integrated Zepto with AI
        so
        that our users could harness better out of their data.
      </p>

      <p>As a result, we ended up building a simple yet powerful tool that could help our users get more out of their
        data with
        less complexity. Still not getting the complete picture? Check out the below stats, facts could be debatable
        but
        stats cannot;
      </p>

      <div class="about_us_card_img">
        <img src="../../../../../assets/images/about_us/about_us2.svg">
      </div>



    </div>
  </div>



  <br>
  <app-counts></app-counts>

</div>