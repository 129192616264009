import { Component, OnInit } from '@angular/core';
import { NgxGalleryOptions, NgxGalleryImage, NgxGalleryAnimation, NgxGalleryLayout } from '@kolkov/ngx-gallery';

@Component({
  selector: 'app-trusted-section',
  templateUrl: './trusted-section.component.html',
  styleUrls: ['./trusted-section.component.scss']
})
export class TrustedSectionComponent implements OnInit {

  galleryOptions: NgxGalleryOptions[];
  galleryImages: NgxGalleryImage[];



  slides = [
    { img: "../../../../../assets/images/home/partners/brand_alliance-finance.svg" },
    { img: "../../../../../assets/images/home/partners/brand_analytical-instruments.svg" },
    { img: "../../../../../assets/images/home/partners/brand_cal.svg" },
    { img: "../../../../../assets/images/home/partners/brand_dynawash.svg" },
    { img: "../../../../../assets/images/home/partners/brand_eternis.svg" },
    { img: "../../../../../assets/images/home/partners/brand_firstcapital.svg" },
    { img: "../../../../../assets/images/home/partners/brand_kotat.svg" },
    { img: "../../../../../assets/images/home/partners/brand_kpmg.svg" }
  ];

  slideConfig = {
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    arrows : false,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          arrows: false,
          centerMode: true,
          centerPadding: '40px',
          slidesToShow: 3,
          autoplay: true,
          autoplaySpeed: 2000,
        }
      },
      {
        breakpoint: 480,
        settings: {
          arrows: false,
          centerMode: true,
          centerPadding: '40px',
          slidesToShow: 1,
          autoplay: true,
          autoplaySpeed: 2000,
        }
      }
    ]
  };



  removeSlide() {
    this.slides.length = this.slides.length - 1;
  }

  slickInit(e) {
  }

  breakpoint(e) {
  }

  afterChange(e) {
  }

  beforeChange(e) {
  }

  // infMe(){
  //   this.slickModal
  // }

  constructor() { }

  ngOnInit() {


    this.galleryOptions = [
      {
        height: "150px",
        width: "100%",
        thumbnailsColumns: 4,
        imageAutoPlay: true,
        imageAutoPlayInterval: 1000,
        imageInfinityMove: true,
        previewInfinityMove: true,
        imageAutoPlayPauseOnHover: true,
        imageAnimation: NgxGalleryAnimation.Slide,
        thumbnails: true,
        image: false,
        previewAutoPlay: true,
        previewAutoPlayInterval: 1000,
      },
      {
        breakpoint: 920,
        thumbnailsColumns: 3,

      },
      {
        breakpoint: 720,
        thumbnailsColumns: 2,
      },
      {
        breakpoint: 480,
        thumbnailsColumns: 1,

      }

    ];

    this.galleryImages = [
      {
        small: '../../../../../assets/images/home/partners/brand_alliance-finance.svg',
        medium: '../../../../../assets/images/home/partners/brand_alliance-finance.svg',
        // big: '../../../../../assets/images/home/partners/brand_alliance-finance.svg'
      },
      {
        small: '../../../../../assets/images/home/partners/brand_analytical-instruments.svg',
        medium: '../../../../../assets/images/home/partners/brand_analytical-instruments.svg',
        // big: '../../../../../assets/images/home/partners/bbrand_analytical-instruments.svg'
      },
      {
        small: '../../../../../assets/images/home/partners/brand_cal.svg',
        medium: '../../../../../assets/images/home/partners/brand_cal.svg',
        // big: '../../../../../assets/images/home/partners/brand_cal.svg'
      },
      {
        small: '../../../../../assets/images/home/partners/brand_dynawash.svg',
        medium: '../../../../../assets/images/home/partners/brand_dynawash.svg',
        // big: '../../../../../assets/images/home/partners/brand_dynawash.svg'
      },
      {
        small: '../../../../../assets/images/home/partners/brand_eternis.svg',
        medium: '../../../../../assets/images/home/partners/brand_eternis.svg',
        // big: '../../../../../assets/images/home/partners/brand_eternis.svg'
      },
      {
        small: '../../../../../assets/images/home/partners/brand_firstcapital.svg',
        medium: '../../../../../assets/images/home/partners/brand_firstcapital.svg',
        // big: '../../../../../assets/images/home/partners/brand_firstcapital.svg'
      },
      {
        small: '../../../../../assets/images/home/partners/brand_kotat.svg',
        medium: '../../../../../assets/images/home/partners/brand_kotat.svg',
        // big: '../../../../../assets/images/home/partners/brand_kotat.svg'
      },
      {
        small: '../../../../../assets/images/home/partners/brand_kpmg.svg',
        medium: '../../../../../assets/images/home/partners/brand_kpmg.svg',
        // big: '../../../../../assets/images/home/partners/brand_kpmg.svg'
      }
    ];


  }

}
