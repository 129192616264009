import { Component, Inject, PLATFORM_ID } from '@angular/core';
import { Router, RouteConfigLoadStart, RouteConfigLoadEnd, NavigationEnd } from '@angular/router';
import { isPlatformServer } from '@angular/common';
import { TransferState, makeStateKey } from '@angular/platform-browser';
// import { Idle, DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';
// import { Keepalive } from '@ng-idle/keepalive';
import { CookieService } from 'ngx-cookie';
import { ApiEndpont } from './core/api-endpoints/api-endpoint';
import { WebService } from './core/services/web-service/web.service';
import { Observable, fromEvent, merge, of } from 'rxjs';
import { mapTo } from 'rxjs/operators';

const RESULT_KEY = makeStateKey<string>('result');

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    providers: [WebService, CookieService]
})


export class AppComponent {
    title = 'ZEPTO-WEB';
    loadingRouteConfig: boolean;
    private isServer: boolean;
    public result;
    public contentLoadedFromServer: boolean = false;
    additionPromiseResult: number;
    additionAsyncResult: number;
    deviceInfo = null;
    private endPoint = ApiEndpont;
    public showNetworkStatus: boolean;
    online$: Observable<boolean>;
    public currentRoute: string;

    idleState = 'Not started.';
    timedOut = false;
    lastPing?: Date = null;


    constructor(
        private router: Router,
        private tstate: TransferState,
        @Inject(PLATFORM_ID) platformId,
        // private idle: Idle,
        // private keepalive: Keepalive,
        private cookieService: CookieService,
        private webservice: WebService
    ) {

        router.events.subscribe((currentRoute) => {
            if (currentRoute instanceof NavigationEnd) {
                this.currentRoute = currentRoute.url;
                window.scrollTo(0, 0)
            }
        });


        this.isServer = isPlatformServer(platformId);

        // // idle settings
        // // sets an idle timeout of 5 seconds, for testing purposes.
        // idle.setIdle(1800);
        // // sets a timeout period of 5 seconds. after 10 seconds of inactivity, the user will be considered timed out.
        // idle.setTimeout(1800);
        // // sets the default interrupts, in this case, things like clicks, scrolls, touches to the document
        // idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

        // idle.onIdleEnd.subscribe(() => this.idleState = 'No longer idle.');
        // idle.onTimeout.subscribe(() => {
        //     this.idleState = 'Timed out!';
        //     this.timedOut = true;

        //     if (this.cookieService.get('AUTH_TOKEN') != undefined) {
        //         this.logOut();
        //     }

        // });
        // idle.onIdleStart.subscribe(() => this.idleState = 'You\'ve gone idle!');
        // idle.onTimeoutWarning.subscribe((countdown) => this.idleState = 'You will time out in ' + countdown + ' seconds!');

        // // sets the ping interval to 15 seconds
        // keepalive.interval(15);

        // keepalive.onPing.subscribe(() => this.lastPing = new Date());

        // this.reset();

    }

    ngOnInit() {
        if (this.cookieService.get('AUTH_TOKEN') != undefined) {
            if (window.location.pathname === "/login" || window.location.pathname === "/signup") {
                window.location.href = "/app/datalocker"
            }
        }

        this.contentLoadedFromServer = false;

        if (this.tstate.hasKey(RESULT_KEY)) {
            // We are in the browser
            this.result = this.tstate.get(RESULT_KEY, '');
            this.contentLoadedFromServer = true;

        } else if (this.isServer) {
            // We are on the server
            this.tstate.set(RESULT_KEY, 'Im created on the server!');

        } else {
            // No result received 
            this.result = 'Im created in the browser!';
            this.contentLoadedFromServer = true;

        }

        this.router.events.subscribe(event => {
            if (event instanceof RouteConfigLoadStart) {
                this.loadingRouteConfig = true;
            } else if (event instanceof RouteConfigLoadEnd) {
                this.loadingRouteConfig = false;
            }
        });

    }


    logOut() {
        this.cookieService.remove('AUTH_TOKEN');
        this.cookieService.remove('code');
        this.cookieService.remove('signUpStatus');
        this.webservice.processPost(this.endPoint.signOut.logOut, {})
            .subscribe(
                successData => {
                    setTimeout(() => {
                        window.location.href = "/login";
                    }, 500);
                },
                error => {
                    console.log("ERRORRRR, error");
                    setTimeout(() => {
                        window.location.href = "/login";
                    }, 500);
                },
                () => {
                    setTimeout(() => {
                        window.location.href = "/login";
                    }, 500);
                }
            );
    }

    // reset() {
    //     this.idle.watch();
    //     this.idleState = 'Started.';
    //     this.timedOut = false;
    // }

    // networkStatus() {
    //     this.online$.subscribe(value => {
    //         this.showNetworkStatus = value;
    //     })
    // }
}