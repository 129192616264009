<div class="app_container">

  <div class="card privacy_terms_card">
    <div class="card-body">
      <h1 class="privacy_terms_title">
        ZEPTO Terms of Service
      </h1>
      <br>

      <div class="privacy_image">
        <img src="../../../../assets/images/terms/termsservice.svg">
      </div>



      <div style="padding: 0 5%; color: #777777;">

        <div class="row">

          <div class="each-features col-xs-12">
            <div class="inner">
              <h4 class="title" style="margin-top: 70px;">Welcome to ZEPTO!</h4>
              <p>Your use of and access to our ZEPTO website its affiliated websites, products and services (collectively,
                the “ZEPTO Service”) is subject to your compliance with these terms of use (the “Terms”). Zeptolytics (Private)
                Limited (“ZEPTO”, “ZEPTOLYTICS”, “we”, “us” or “our”) reserves the right to limit or terminate your access
                to this service if you fail to comply with these Terms.
              </p>
              <p>By accessing and using this site, you agree to be bound by these terms. IF YOU DO NOT AGREE TO ALL OF THE
                TERMS AND CONDITIONS CONTAINED IN THE TERMS OF USE, DO NOT USE THE ZEPTO SERVICE IN ANY MANNER. If you are
                using the ZEPTO Service on behalf of your employer, you represent that you are authorized to accept these
                Terms on your employer’s behalf.</p>

              <h4 class="title">Changes in Terms of Use</h4>
              <p>We reserve the right to modify these Terms from time to time. When we do so, we will give you notice thereof
                on the ZEPTO Service. Use of the ZEPTO Service constitutes your acceptance of any modified Terms.
              </p>


              <h4 class="title">Ownership and Privacy</h4>
              <p>All User Content you transmit, upload, publish or display (hereinafter “distribute”) on or through the ZEPTO
                Service is, and will always remain, your content. ZEPTO does not take any ownership rights in the User Content.
                All we require from you is a license to allow us (and our third party providers) to host, transmit and display
                your User Content, solely so we can provide the ZEPTO Service to you. We may modify your User Content to
                create previews or thumbnails or to otherwise provide the ZEPTO Service, but we do not claim any ownership
                in your User Content because of such modifications.</p>
              <p>We do not monitor User Content. It is therefore your responsibility to ensure that you have the appropriate
                permissions to upload your User Content to the ZEPTO Service. However, ZEPTO may block or remove any such
                User Content or prohibit any use of the ZEPTO Service that it believes may be (or is alleged to be) in violation
                of these Terms.
              </p>
              <p>We will not share User Content with a third party unless directed by you (for example, if you elect to share
                your User Content with other users). We may share User Content if required to do so by law, or in the good-faith
                belief that such action is necessary to comply with laws or respond to a court order, subpoena, or search
                warrant. Whenever possible, we will notify you before making your User Content available to enable you to
                respond to the request yourself.</p>


              <h4 class="title">User Conduct and Responsibilities</h4>
              <div>
                <p>Your use of the ZEPTO Service is subject to all applicable local, state, national and international laws
                  and regulations. Without limitation, you agree to not use the ZEPTO Service to upload any User Content
                  that:</p>
                <br>
                <ul>
                  <li>is illegal under or otherwise violates any local, state, national or international law or would constitute,
                    encourage or provide instructions for a criminal offense ;</li>
                  <li>violates the rights of any party (including without limitation rights of privacy and publicity);</li>
                  <li>infringes any copyright, patent, trademark, trade secret, or other proprietary rights of any party;</li>
                  <li>introduces viruses or any other computer code, files or programs designed to interrupt, destroy or
                    limit the functionality of any computer software or hardware or telecommunications equipment;
                  </li>
                  <li>you do not have a right to transmit under any law or under contractual or fiduciary relationships (such
                    as inside information, proprietary and confidential information learned or disclosed as part of employment
                    relationships or under nondisclosure agreements); or
                  </li>
                  <li>in our sole judgment, which may expose ZEPTO or its users to any harm or liability of any type.</li>
                </ul>

              </div>


              <h4 class="title">User Information</h4>
              <p>If you wish to use the ZEPTO Service, you must register and create a user account (“User Account”). You
                are solely responsible in all respects for all use of (including any unauthorized use) of your login credentials,
                and for protecting the confidentiality of your password.</p>


              <h4 class="title">User Content</h4>
              <p>You own and are solely responsible for any content or materials (“User Content”) that you transmit, upload,
                publish or display (hereinafter “distribute”) on or through the ZEPTO Service. ZEPTO may block or remove
                any such User Content or prohibit any use of the ZEPTO Service that it believes may be (or is alleged to
                be) in violation of these Terms. You grant ZEPTO the right to use your User Content solely for the provision
                of the ZEPTO Service.</p>


              <h4 class="title">Fees and Payments</h4>
              <p>ZEPTO charges fees for the use of certain features or aspects of the Services, which are described with
                those features and services. These fees are subject to change at any time. You agree that we may charge your
                payment account and that you will pay the applicable fees assessed to your account for the Services you have
                purchased.
              </p>
              <p>Some Services may start with a free trial. If you or someone in your company has used those Services previously,
                you may not be eligible to receive a free trial. We will not charge your account for use during the free
                trial period. If you do not cancel the Services prior to the end of the free trial period we will charge
                your account the subscription fee commencing from the end of your free trial period and on a recurring basis
                thereafter until you cancel. You must cancel your subscription before your billing period renews to avoid
                the billing of the next period’s fees to your account. You will not receive a refund for any partial billing
                period cancellation.</p>


              <h4 class="title">Suspension, Termination</h4>
              <p>ZEPTO reserves the right, in its sole discretion, to remove your profile and/or deny, restrict, suspend,
                or terminate your access to all or any part of the ZEPTO Service at any time, for any or no reason, with
                or without prior notice or explanation, and without liability. If we suspend your usage of any paid service,
                we will stop billing you during such suspension. You will not receive any refunds if your access to the ZEPTO
                Service is suspended or terminated due to your breach of these Terms.</p>


              <h4 class="title">Links to Other Websites and Content</h4>
              <p>The ZEPTO Service contains (or you may access through the ZEPTO Service) links to other web sitesbelonging
                to third parties (“Third Party Services”). Third Party Services and Third Party Content are not investigated,
                monitored or checked for accuracy, appropriateness, or completeness by ZEPTO, and ZEPTO is not responsible
                for any Third Party Services accessed through the ZEPTO Service or any Third Party Content linked or posted
                through the ZEPTO Service.</p>


              <h4 class="title">Submissions</h4>
              <p>You acknowledge and agree that any questions, comments, suggestions, ideas, feedback or other information
                about the ZEPTO Service (“Submissions”), provided by you to ZEPTO are non-confidential and shall become the
                sole property of ZEPTO. ZEPTO shall own all right, title and interest, including all intellectual property
                rights, in Submissions, and shall be entitled to the unrestricted use and dissemination of these Submissions
                for any purpose, commercial or otherwise, without acknowledgment or compensation to you.</p>


              <h4 class="title">Privacy Policy</h4>
              <p>Your privacy is important to us! Use of the ZEPTO Service is governed by our
                <a href="privacy-policy">Privacy Policy.</a>
              </p>


              <h4 class="title">ZEPTO Service Content</h4>
              <p>The ZEPTO Service is the proprietary property of ZEPTO, its users or its licensors with all rights reserved.
                ZEPTO grants you a limited, revocable license to use the ZEPTO Service and all content contained therein
                in accordance with these Terms. Unless explicitly stated herein, nothing in these Terms shall be construed
                as conferring any license to ZEPTO’s intellectual property rights, whether by estoppel, implication or otherwise.</p>
              <p>The ZEPTO Service is protected to the maximum extent permitted by copyright laws and international treaties.
                You may not decompile or disassemble, reverse engineer or otherwise attempt to discover any source code contained
                in the ZEPTO Service. Without limiting the foregoing, you agree not to reproduce, duplicate, copy, sell,
                resell or exploit for any commercial purposes, any aspect of the ZEPTO Service.</p>


              <h4 class="title">Modifications to ZEPTO Service</h4>
              <p>ZEPTO reserves the right to modify or terminate any or all portions of the ZEPTO Service with or without
                cause at any time and effective immediately. ZEPTO shall not be liable to you or any third party for termination.
                Should you object to any modifications to the ZEPTO Service or become dissatisfied with the ZEPTO Service
                in any way, your only recourse is to immediately discontinue use of the ZEPTO Service.
              </p>


              <h4 class="title">Notices</h4>
              <p>All notices to a party shall be in writing and shall be made either via e-mail or conventional mail. ZEPTO
                may broadcast notices or messages through the ZEPTO Service to inform you of changes to these Terms, the
                ZEPTO Service, or other matters of importance. Such broadcasts shall constitute notice to you.
              </p>


              <h4 class="title">Disclaimers</h4>
              <p>The ZEPTO Service may be temporarily unavailable from time to time for maintenance or other reasons. ZEPTO
                assumes no responsibility for any error, omission, interruption, deletion, defect, delay in operation or
                transmission, communications line failure, theft or destruction or unauthorized access to, or alteration
                of, user communications or User Content.
                <br> YOU EXPRESSLY UNDERSTAND AND AGREE THAT:
                <br> THE ZEPTO SERVICE IS PROVIDED “AS IS,” WITH NO WARRANTIES WHATSOEVER. ZEPTO DISCLAIMS ALL WARRANTIES
                OF ANY KIND, EXPRESS OR IMPLIED, INCLUDING WITHOUT LIMITATION ANY WARRANTY OF MERCHANTABILITY OR FITNESS
                FOR A PARTICULAR PURPOSE.
                <br> ZEPTO MAKES NO WARRANTY OR REPRESENTATION REGARDING (i) THE ZEPTO SERVICE OR ANY THIRD PARTY SERVICES,
                (ii) THE RESULTS THAT MAY BE OBTAINED FROM THE USE OF THE ZEPTO SERVICE, OR (ii) THE DELETION, FAILURE TO
                STORE, MISDELIVERY, OR UNTIMELY DELIVERY OF ANY INFORMATION, PRODUCTS OR USER CONTENT. ZEPTO DOES NOT REPRESENT
                OR WARRANT THAT THE SITE WILL MEET ANY OF YOUR REQUIREMENTS OR THAT IT WILL BE UNINTERRUPTED, TIMELY, SECURE
                OR ERROR FREE.
                <br> USE OF THE ZEPTO SERVICE IS AT YOUR SOLE RISK. YOU WILL BE SOLELY RESPONSIBLE FOR ANY DAMAGE THAT RESULTS
                FROM YOUR USE OF THE SITE OR YOUR USER CONTENT.
              </p>


              <h4 class="title">Limitation of Liability</h4>
              <p>UNDER NO CIRCUMSTANCES SHALL ZEPTO BE LIABLE TO YOU ON ACCOUNT OF (i) YOUR USE OR MISUSE OF OR RELIANCE
                ON THE ZEPTO SERVICE, OR (ii) YOUR INABILITY TO USE THE ZEPTO SERVICE, OR THE INTERRUPTION, SUSPENSION, OR
                TERMINATION OF THE SITE (INCLUDING SUCH DAMAGES INCURRED BY THIRD PARTIES). SUCH LIMITATION OF LIABILITY
                SHALL APPLY TO PREVENT RECOVERY OF DIRECT, INDIRECT, INCIDENTAL, CONSEQUENTIAL, SPECIAL, EXEMPLARY, AND PUNITIVE
                DAMAGES, AND THE COSTS OF PROCUREMENT OF SUBSTITUTE GOODS OR ZEPTO SERVICES, LOST PROFITS, OR LOST DATA ARISING
                FROM ANY CLAIM RELATING TO THIS AGREEMENT OR THE SUBJECT MATTER HEREOF WHETHER SUCH CLAIM IS BASED ON WARRANTY,
                CONTRACT, TORT (INCLUDING NEGLIGENCE), OR OTHERWISE, (EVEN IF ZEPTO OR ITS LICENSORS HAVE BEEN ADVISED OF
                THE POSSIBILITY OF SUCH DAMAGES). IN NO EVENT SHALL THE LIABILITY OF ZEPTO OR ITS OFFICERS, DIRECTORS, AND
                EMPLOYEES EXCEED $100. SUCH LIMITATION OF LIABILITY SHALL APPLY NOTWITHSTANDING ANY FAILURE OF ESSENTIAL
                PURPOSE OF ANY LIMITED REMEDY AND TO THE FULLEST EXTENT PERMITTED BY LAW.
              </p>
              <p>Some jurisdictions do not allow the limitation or exclusion of liability for incidental or consequential
                damages so some of the above limitations may not apply to you.</p>


              <h4 class="title">Indemnity</h4>
              <p>You agree to indemnify and hold ZEPTO, its subsidiaries and affiliates, and each of their directors, officers,
                agents, contractors, partners and employees, harmless from and against any loss, liability, claim, demand,
                damages, costs and expenses, including reasonable attorney’s fees, arising out of your use of the ZEPTO Service,
                the User Content you provide, or any violation of these Terms or of any law or the rights of any third party.</p>


              <h4 class="title">Miscellaneous</h4>
              <p>Waiver and Severability of Terms. The failure of ZEPTO to exercise or enforce any right or provision of
                these Terms shall not constitute a waiver of such right or provision. Any waiver of any provision of these
                Terms will be effective only if in writing and signed by ZEPTO. If any provision of these Terms is found
                by a court of competent jurisdiction to be invalid, the parties nevertheless agree that the court should
                endeavor to give effect to the parties’ intentions as reflected in the provision, and the other provisions
                of these Terms remain in full force and effect.</p>


              <h4 class="title">Choice of Law and Forum</h4>
              <p>This Agreement shall be governed by and construed in accordance with the laws of Sri Lanka.
              </p>

            </div>

          </div>


        </div>

      </div>

    </div>
  </div>

</div>