import { Component, OnInit } from '@angular/core';
import * as jQuery from 'jquery';
declare var $: any;

@Component({
  selector: 'healthcare',
  templateUrl: './healthcare.component.html',
  styleUrls: ['./healthcare.component.scss']
})
export class HealthcareComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

  expandSection(sectionId) {
    if (sectionId == 1) {
      document.getElementById("know-more1").style.display = "none";
      var el = $('#section-container-1'),
        curHeight = el.height(),
        autoHeight = el.css('height', 'auto').height();
      el.height(curHeight).animate({ height: autoHeight }, 1500);
    } else if (sectionId == 2) {
      document.getElementById("know-more2").style.display = "none";
      var el = $('#section-container-2'),
        curHeight = el.height(),
        autoHeight = el.css('height', 'auto').height();
      el.height(curHeight).animate({ height: autoHeight }, 1500);
    } else if (sectionId == 3) {
      document.getElementById("know-more3").style.display = "none";
      var el = $('#section-container-3'),
        curHeight = el.height(),
        autoHeight = el.css('height', 'auto').height();
      el.height(curHeight).animate({ height: autoHeight }, 1500);
    }
  }

  scroll() {
    $('html, body').animate({
      scrollTop: $("#scroll-to").offset().top
    }, 1000);
  }

}
