<div class="card" [@scrollAnimation]="state">
  <div class="card-body" id="user_counts">

    <div class="row">

      <div class="col-12 col-md-4">
        <div class="d-flex justify-content-center about_us_counts_title">Number of Users</div>
        <div class="d-flex justify-content-center about_us_counts">
          <span id="odometer2" class="odometer2" [CountTo]=127 [from]=0 [duration]=5></span>
        </div>
      </div>
      <div class="col-12 col-md-4">
        <div class="vertical-line float-right d-none d-md-block"></div>
        <div class="d-flex justify-content-center about_us_counts_title">Number of Datasets</div>
        <div class="d-flex justify-content-center about_us_counts">
          <span id="odometer1" class="odometer1" [CountTo]=1673 [from]=0 [duration]=5></span>
        </div>
      </div>
      <div class="col-12 col-md-4">
        <div class="vertical-line float-left d-none d-md-block" style="margin-left: -10px;"></div>
        <div class="d-flex justify-content-center about_us_counts_title">Number of Insights</div>
        <div class="d-flex justify-content-center about_us_counts">
          <span id="odometer" class="odometer" [CountTo]=1043708 [from]=0 [duration]=5></span>
        </div>
      </div>

    </div>

  </div>
</div>