<div class="title_cloud_container">

  <!-- <div class="row">

    <div class="col-4">
      <img class="title_left_part float-right" src="../../../../assets/images/shared/h2_bg-left_part.svg">
    </div>

    <div class="col-4">
      <h5 class="section_title">{{sectionTitle}}</h5>

    </div>

    <div class="col-4">
      <img class="title_right_part float-left" src="../../../../assets/images/shared/h2_bg-right_part.svg">
    </div>

  </div> -->





  <div class="flex-container">
    <div>
      <img class="title_left_part float-right" src="../../../../assets/images/shared/h2_bg-left_part.svg">
    </div>
    <div>{{sectionTitle}}</div>
    <div>
      <img class="title_right_part float-left" src="../../../../assets/images/shared/h2_bg-right_part.svg">
    </div>
  </div>








</div>