import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  public showHideFooterSection: boolean = true;
  public today: number = Date.now();

  constructor(private router: Router, private activeRoute: ActivatedRoute) { }

  ngOnInit() {
    this.router.events.subscribe(event => {
      this.showHideFooter(this.router.url);
    });
  }

  showHideFooter(route) {
    var token = undefined;
    this.activeRoute.queryParams.subscribe(params => {
      token = params.token
    });

    if (route === "/signup" || route === "/login" || route === "/reset-password") {
      this.showHideFooterSection = false;
    } else if (token != undefined) {
      this.showHideFooterSection = false;
    } else {
      this.showHideFooterSection = true;
    }
  }

}