<div class="app_container">
  <div class="signup-container">
    <div class="row">

      <div class="col-md-6 d-none d-md-block">
        <div>
          <img src="../../../../../assets/images/sign_in/finger-print.svg">
        </div>
      </div>

      <div class="col-md-6  d-md-block sign-in-form" [ngClass]="{'do-flip': emailSuccess, 'do-flip-reverse': reverseFlip}">

        <div class="flip-box">
          <div class="flip-box-inner">
            <div class="flip-box-front">


              <form (ngSubmit)="doEmailVerify(emailForm)" [formGroup]="emailForm">
                <div class="signup-form-top-container">
                  <div class="signup-logo">
                    <img src="../../../../../assets/images/sign_in/zepto-signin-logo.svg">
                  </div>

                  <div class="input-container-top">
                    <input type="email" formControlName="emailInput" class="input_rounded center_text_input" id="email-field"
                      [ngClass]="{'input-error': emailErrorStatus == 1 || emailErrorStatus == 2 || emailErrorStatus == 3, 'input_rounded-focus':emailErrorStatus == 0}"
                      placeholder="Email" pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$" maxlength="50"
                      autofocus>


                    <!-- <input type="password" class="input_rounded center_text_input" id="password-field" placeholder="Password"> -->


                    <span toggle="#email-field" class="field-icon" (click)="clearEmailField()" *ngIf="emailErrorStatus === 2 || emailErrorStatus === 3">
                      <img class="clear-error" src="../../../../../assets/images/shared/remove-input-error.svg">
                    </span>



                    <div class="error-container">
                      <div *ngIf="emailErrorStatus == 1 || emailErrorStatus == 2">
                        <span>
                          <img src="../../../../../assets/images/shared/error-info.svg">
                        </span>
                        <span>Please enter the email you have registered with.</span>
                      </div>

                      <div *ngIf="emailErrorStatus == 3">
                        <span>
                          <img src="../../../../../assets/images/shared/error-info.svg">
                        </span>
                        <span>This Email has not been registered.</span>
                      </div>

                    </div>

                  </div>
                </div>
                <div class="signup-form-bottom-container">
                  <div class="input-container-bottom">
                    <div class="register-button">
                      <button type="submit" class="secondary_button1 fullWidth">
                        <span *ngIf="!loading">Next</span>
                        <span class="loading" *ngIf="loading"><img src="../../../../../assets/images/shared/zepto-loader-button.gif"></span>
                      </button>
                    </div>
                    <div class="bottom-text">
                      <div>
                        <span class="section-one">I don’t have an account.
                        </span>
                        <span class="section-two" [routerLink]="['/signup']">
                          Sign up
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </form>


            </div>
            <div class="flip-box-back">

              <form (ngSubmit)="doPaswordVerify(passwordForm)" [formGroup]="passwordForm">
                <div class="signup-form-top-container">

                  <div class="signup-logo">
                    <img src="../../../../../assets/images/sign_up/zepto-signup-logo.svg">
                  </div>


                  <div class="verification-code-sent-msg">
                    <div>
                      Sign in as
                      <span>{{emailForm.value.emailInput}}</span>
                    </div>
                  </div>

                  <div class="input-container-top input-container-top-password">

                    <input type="password" formControlName="passwordInput" maxlength="50" required [ngClass]="{'input-error': passwordErrorStatus == 1 || passwordErrorStatus == 3, 'input_rounded-focus':passwordErrorStatus == 0}"
                      class="input_rounded center_text_input" id="password-field" placeholder="Password">


                    <span toggle="#password-field" class="field-icon" (click)="passwordMask()">
                      <img class="eye-open" *ngIf="!showPassword" src="../../../../../assets/images/sign_up/icons/eye-open.svg">
                      <img class="eye-close" *ngIf="showPassword" src="../../../../../assets/images/sign_up/icons/eya-close.svg">
                    </span>

                    <div class="forgot-pwd">
                      <span class="remember">
                        <div class="form-check">
                          <input type="checkbox" class="form-check-input" id="input-remember-me">
                          <label class="form-check-label" for="input-remember-me">Stay signed in</label>
                        </div>
                      </span>
                      <span class="forgot" (click)="openForgotPasswordModal()">Forgot
                        Password?</span>
                    </div>
                  </div>

                  <div class="error-container">
                    <div *ngIf="passwordErrorStatus == 1">
                      <span>
                        <img src="../../../../../assets/images/shared/error-info.svg">
                      </span>
                      <span>Please enter the password.</span>
                    </div>

                    <div *ngIf="passwordErrorStatus == 3">
                      <span>
                        <img src="../../../../../assets/images/shared/error-info.svg">
                      </span>
                      <span>Password is incorrect.</span>
                    </div>

                  </div>

                </div>
                <div class="signup-form-bottom-container">
                  <div class="input-container-bottom">
                    <div class="register-button">
                      <button type="submit" class="secondary_button1 fullWidth">
                        <span *ngIf="!loading">Sign in</span>
                        <span class="loading" *ngIf="loading"><img src="../../../../../assets/images/shared/zepto-loader-button.gif"></span>
                      </button>
                    </div>
                    <div class="bottom-text">
                      <div>
                        <span class="section-two" (click)="doReverseFlip()">
                          Sign in with a different account
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </form>

            </div>
          </div>
        </div>

      </div>

    </div>
  </div>
</div>


<!-- Modal -->
<div #fogotPasswordModal class="modal fade" id="forgot-password-modal" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle"
  aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content" [ngClass]="{'do-flip': successfullySentForgotPwdLink}">
      <div class="flip-box">
        <div class="flip-box-inner">

          <div class="flip-box-front">

            <div class="modal-header">
              <div class="title" [innerHTML]="forgotPasswordModalText.title">
              </div>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <div class="content" [innerHTML]="forgotPasswordModalText.description">
              </div>
            </div>
            <div class="modal-footer">
              <button *ngIf="forgotPasswordModalText.status == 1" type="button" class="ok-btn" (click)="sendResetLink()">
                <span *ngIf="!modalBtnLoading" [innerHTML]="forgotPasswordModalText.buttonTitle"></span>
                <span class="loading" *ngIf="modalBtnLoading"><img src="../../../../../assets/images/shared/zepto-loader-button.gif"></span>
              </button>
              <button *ngIf="forgotPasswordModalText.status == 2" type="button" class="ok-btn" [innerHTML]="forgotPasswordModalText.buttonTitle"
                data-dismiss="modal" aria-label="Close">
              </button>
            </div>

          </div>

          <div class="flip-box-back">

            <div class="modal-header">
              <div class="title" [innerHTML]="forgotPasswordModalText.title">
              </div>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <div class="content" [innerHTML]="forgotPasswordModalText.description">
              </div>
            </div>
            <div class="modal-footer">
              <button *ngIf="forgotPasswordModalText.status == 1" type="button" class="ok-btn" (click)="sendResetLink()"
                [innerHTML]="forgotPasswordModalText.buttonTitle"></button>
              <button *ngIf="forgotPasswordModalText.status == 2" type="button" class="ok-btn" [innerHTML]="forgotPasswordModalText.buttonTitle"
                data-dismiss="modal" aria-label="Close">
              </button>
            </div>

          </div>
        </div>
      </div>

    </div>
  </div>
</div>